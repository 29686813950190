import { ProgressBar, Text } from '@sede-x/shell-ds-react-framework';
import React from 'react';

const ScoreIndicator = ({ Score, text, size = 'medium' }) => {
  const ScoreCard = {
    veryGood: 'VERY GOOD',
    Good: 'GOOD',
    BelowMinimum: 'Below minimum',
    StandardResponses: 'Standard Responses',
  };
  const getScoreCard = (score) => {
    if (score < 50) {
      return ScoreCard.BelowMinimum;
    } else if (score >= 50 && score <= 60) {
      return ScoreCard.Good;
    } else if (score >= 60 && score <= 100) {
      return ScoreCard.veryGood;
    } else {
      return ScoreCard.StandardResponses;
    }
  };
  const value = Score !== null || Score !== '' ? Math.round(Number(Score)) : 0;
  return (
    <div className="score-indicator-container">
      <ProgressBar
        type="circle"
        value={value}
        label={true}
        size={size}
        sentiment={
          value < 50
            ? 'negative'
            : value > 50 && value < 60
            ? 'default'
            : 'positive'
        }
      />
      <br />
      <Text variant="subtitle2">{text} </Text>
      <Text variant="subtitle2">
        {text !== 'Required Level Agreement' ? (
          <b>{getScoreCard(value)}</b>
        ) : (
          getScoreCard()
        )}
      </Text>
    </div>
  );
};

export default ScoreIndicator;
