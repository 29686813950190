import base64 from 'base64-js';
import config from '../../../config';

let codeVerifier, pingURL, code_challenge;
const HAS_CRYPTO = typeof window !== 'undefined' && !!window.crypto;

const bufferToString = (buffer) => {
  const CHARSET =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const state = new Array();
  for (let i = 0; i < buffer.byteLength; i += 1) {
    const index = buffer[i] % CHARSET.length;
    state.push(CHARSET[index]);
  }
  return state.join('');
};
const generateRandom = (size) => {
  const buffer = new Uint8Array(size);
  if (HAS_CRYPTO) {
    window.crypto.getRandomValues(buffer);
  } else {
    // fall back to Math.random() if nothing else is available
    for (let i = 0; i < size; i += 1) {
      buffer[i] = Math.random();
    }
  }
  return bufferToString(buffer);
};
const urlSafe = (buffer) => {
  const encoded = base64.fromByteArray(new Uint8Array(buffer));
  return encoded.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
};
const deriveChallenge = (code) => {
  return new Promise((resolve, reject) => {
    crypto.subtle.digest('SHA-256', textEncodeLite(code)).then(
      (buffer) => {
        return resolve(urlSafe(new Uint8Array(buffer)));
      },
      (error) => reject(error)
    );
  });
};
const textEncodeLite = (str) => {
  const buf = new ArrayBuffer(str.length);
  const bufView = new Uint8Array(buf);
  for (let i = 0; i < str.length; i++) {
    bufView[i] = str.charCodeAt(i);
  }
  return bufView;
};
const setupCodeVerifier = (clientId) => {
  codeVerifier = generateRandom(43);
  const challenge = deriveChallenge(codeVerifier).catch((_error) => {
    return undefined;
  });
  return challenge.then((result) => {
    if (result) {
      code_challenge = result;
      navToPing(clientId);
    }
  });
};

const navToPing = (clientId) => {
  const challenge = code_challenge;
  pingURL = `${config.PINGID_AUTH_URL}?redirect_uri=${config.REDIRECT_URL}&client_id=${clientId}&response_type=code&scope=email&audience=API_AUDIENCE&access_type=offline&code_challenge=${challenge}&code_challenge_method=S256`;
  window.localStorage.setItem('verifier', codeVerifier);
  window.location.href = pingURL;
};

const redirectToPingId = async (clientId) => {
  await setupCodeVerifier(clientId);
};

export default redirectToPingId;
