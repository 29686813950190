import {
  Accordion,
  AccordionPanel,
  Button,
  Card,
  Grid,
  Heading,
  ProgressBar,
  Table,
  Text,
} from "@sede-x/shell-ds-react-framework";
import React, { useContext, useEffect, useState } from "react";
import "./EdtResult.css";
import axios from "axios";
import { Context, actions } from "../../context";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import {
  BASE_URL,
  GET_EDT_QUESTIONS,
  GET_EDT_SURVEY_DETAILS,
  SAVE_EDT_SURVEY,
  POST_PROJECTDETAILS_URL,
} from "../../api/APIdetails";
import { api } from "../../api";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import {
  TBody,
  TD,
  TH,
  THead,
  TR,
} from "@sede-x/shell-ds-react-framework/build/esm/components/Table/Table.styles";
import { Download } from "@sede-x/shell-ds-react-framework/build/esm/components/Icon/components";

const EdtSurveyResult = () => {
  const [state, dispatch] = useContext(Context);
  const [isRamDone, setRamDone] = useState(false);
  const history = useHistory();
  const [formSubmit, setFormSubmit] = useState(false);
  const {
    banner,
    selectedProject,
    EDT,
    EDTAnswers,
    formData,
    EDTScore,
    selectedFlag,
    RAMNeeded,
    EDTSurvey,
  } = state;
  const [edtSurveyAnswers, setEdtSurveyAnswers] = useState([]);
  // console.log(selectedProject);
  // console.log(EDT);
  // console.log(EDTAnswers);

  useEffect(() => {
    if (!formSubmit) {
      window.addEventListener("beforeunload", alertUser);
      return () => {
        window.removeEventListener("beforeunload", alertUser);
      };
    }
  }, [formSubmit]);

  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "You have unsaved changes, are you sure you want to leave?";
  };

  useEffect(() => {
    console.log(formSubmit);

    if (!formSubmit) {
      const unblock = history.block((location, action) => {
        if (
          action === "PUSH" &&
          location.pathname !== "/review" &&
          location.pathname !== "/riskAssessment"
        ) {
          return window.confirm(
            "You have unsaved changes, are you sure you want to leave this page?"
          );
        }
        return true;
      });
      return () => unblock();
    }
  }, [history, formSubmit]);

  useEffect(() => {
    const loadEdtSurveyDetails = async () => {
      // const url = `${BASE_URL}${GET_EDT_SURVEY_DETAILS}`;
      // const response = await api.getCalls("loadEdtSurvey", url);
      // const edtDetails = response.data;
      // console.log(edtDetails);
      // const ramExists = edtDetails.some(
      //   (item) => item.project_id === selectedProject.ProjectID
      // );
      // console.log(ramExists);
      setRamDone(false);

      const quesUrl = `${BASE_URL}${GET_EDT_QUESTIONS}`;
      const quesResponse = await api.getCalls("loadEdtQuestions", quesUrl);
      console.log(quesResponse.data.recordset);
      const questions = quesResponse.data.recordset;
      const surveyRes = questions
        .filter((item) => EDTAnswers[item.id]) // Filter out items with no matching id in EDTAnswers
        .map((item) => ({
          id: item.id,
          text: item.text,
          type: item.type,
          ...EDTAnswers[item.id], // Merge properties from EDTAnswers
        }));

      setEdtSurveyAnswers(surveyRes);
    };
    loadEdtSurveyDetails();
  }, []);

  console.log(edtSurveyAnswers);
  const setBanner = (show, type = "error", error) => {
    dispatch({
      type: actions.SET_Banner,
      payload: {
        show,
        type,
        message: error,
      },
    });
  };

  const handleClick = async () => {
    if (RAMNeeded) {
      await new Promise((resolve) => {
        setFormSubmit(true);
        resolve();
      });
      setTimeout(() => {
        history.push("/riskAssessment");
      }, 1);
    } else {
      try {
        let ProjectID, ProjectAreas;
        // First API call
        if (selectedFlag === "newProject") {
          ProjectAreas = formData.Areas;
          let url1 = `${BASE_URL}${POST_PROJECTDETAILS_URL}`;
          let response1 = await axios.post(url1, formData);
          let { data: { recordset: recordSet = [] } = {}, error: error1 } =
            response1;
          ProjectID = recordSet[0].ProjectID;

          dispatch({
            type: actions.SET_PROJECT_ID,
            payload: ProjectID,
          });

          console.log("Project ID- ", ProjectID);
          console.log("Response1", response1);
          if (error1) {
            console.log("Error in Create Project", response1.error);
            setBanner(true, "error", error1);
            throw new Error(error1); // Stop execution if there's an error
          } else {
            console.log("Project Created Successfully", recordSet[0]);
          }
        } else if (selectedFlag === "existingProject") {
          ProjectID = selectedProject.ProjectID;
          ProjectAreas = selectedProject.Areas;
          dispatch({
            type: actions.SET_PROJECT_ID,
            payload: ProjectID,
          });
        }
        console.log("Project Area: ", ProjectAreas);
        let EDTID;
        // Second API call
        if (ProjectAreas === "Emerging Digital Technologies") {
          // console.log("Inside", ProjectAreas, ProjectID);
          const url2 = `${BASE_URL}${SAVE_EDT_SURVEY}`;
          const params = { ...EDTSurvey, projectId: ProjectID };
          const response2 = await axios.post(url2, params); //'saveEdt'
          console.log("EDT Response - ", response2);
          EDTID = response2.data.edtId;
          dispatch({
            type: actions.SET_EDT,
            payload: response2.data,
          });
          await new Promise((resolve) => {
            setFormSubmit(true);
            resolve();
          });
          setTimeout(() => {
            history.push("/review");
          });
        } else {
          console.log("ERROR NOT EDT SURVEY: ", ProjectAreas);
        }
      } catch (error) {
        console.log("Error in Survey Details", error);
        console.log(`Project Not Created-`, error.message);
        const errorCode = error.response ? error.response.status : 500;
        history.push("/errorPage", { error, errorCode });
      }
    }
  };
  const exportEdtSurveyResponses = () => {
    const toExportEdt = edtSurveyAnswers.map((data) => ({
      Question: data.text,
      Answer: data.children,
      Type: data.type,
    }));

    const today = new Date().toLocaleDateString();
    let name =
      selectedFlag === "existingProject"
        ? selectedProject.ProjectName
        : formData.Name;
    exportToCSV(toExportEdt, `${name}_EDT_Survey_On_${today}`);
  };

  const exportToCSV = (apiData, fileName) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(apiData);

    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  let ComplexityLevel, ComplexityDescription;
  if (selectedFlag === "newProject") {
    if (formData.ComplexityID === 1) {
      ComplexityLevel = "Low";
      ComplexityDescription = "no code or >100 lines of code";
    } else if (formData.ComplexityID === 2) {
      ComplexityLevel = "Medium";
      ComplexityDescription = "100-10,000 lines of code";
    } else if (formData.ComplexityID === 3) {
      ComplexityLevel = "High";
      ComplexityDescription = "more than 10,000 lines of code";
    }
  }

  return (
    <div>
      <Card className="header-card">
        <Heading type="h3" bold={true}>
          EDT SURVEY RESULT
        </Heading>
      </Card>
      <br />
      <br />
      <Card className="summary-card">
        <Grid container columns={2} columnGap="10%">
          <Grid children>
            <div className="project-summary">
              <Grid container columns={2}>
                <Grid children>
                  <Text>
                    <b>Project Name: </b>
                  </Text>
                </Grid>
                <Grid children>
                  <Text>
                    {" "}
                    {selectedFlag === "existingProject" ? (
                      <Text>{selectedProject.ProjectName}</Text>
                    ) : (
                      <Text>{formData.Name}</Text>
                    )}
                  </Text>
                </Grid>
                {(formData.Description || selectedProject.Description) && (
                  <>
                    <Grid children>
                      <Text>
                        <b>Description:</b>
                      </Text>
                    </Grid>
                    <Grid children>
                      <Text>
                        {selectedFlag === "existingProject" ? (
                          <Text>{selectedProject.Description}</Text>
                        ) : (
                          <Text>{formData.Description}</Text>
                        )}
                      </Text>
                    </Grid>
                  </>
                )}

                <Grid children>
                  <Text>
                    <b>Point of Contact: </b>
                  </Text>
                </Grid>
                <Grid children>
                  <Text>
                    {selectedFlag === "existingProject" ? (
                      <Text>{selectedProject.PointOfContactEmailID}</Text>
                    ) : (
                      <Text>{formData.Email}</Text>
                    )}
                  </Text>
                </Grid>
                <Grid children>
                  <Text>
                    <b>Code Complexity: </b>
                  </Text>
                </Grid>
                <Grid children>
                  <Text>
                    {selectedFlag === "existingProject" ? (
                      <Text>
                        {selectedProject.ComplexityLevel} -{" "}
                        {selectedProject.ComplexityDescription}
                      </Text>
                    ) : (
                      <Text>
                        {ComplexityLevel} - {ComplexityDescription}
                      </Text>
                    )}
                  </Text>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid children>
            <div className="project-result-container">
              <div className="project-result">
                <ProgressBar
                  type="circle"
                  value={EDTScore}
                  label={true}
                  size="large"
                  sentiment={
                    EDTScore < 50
                      ? "negative"
                      : EDTScore > 50 && EDTScore < 60
                      ? "default"
                      : "positive"
                  }
                />
                <p>Total Score</p>
              </div>
              <Button
                icon={<Download />}
                iconOnly
                variant="transparent"
                onClick={exportEdtSurveyResponses}
              ></Button>
            </div>
          </Grid>
        </Grid>
        <br />
        <Grid container columns={1} columnGap="20px">
          <Grid children>
            <div style={{ textAlign: "center" }}>
              {isRamDone ? (
                <Button
                  size="medium"
                  variant="filled"
                  sentiment="default"
                  onClick={() => {
                    history.push("/review");
                  }}
                >
                  GO TO DASHBOARD
                </Button>
              ) : (
                <Button
                  size="medium"
                  variant="filled"
                  sentiment="default"
                  onClick={handleClick}
                >
                  {RAMNeeded ? "TAKE RAM SURVEY" : "SUBMIT"}
                </Button>
              )}
            </div>
          </Grid>
        </Grid>
      </Card>
      <br />
      <br />
      <Card className="header-card survey-responses">
        <Accordion iconSet="chevron" accordion={true}>
          <AccordionPanel
            header={<div className="panel-header">EDT SURVEY RESPONSES</div>}
          >
            <Table size="medium">
              <THead>
                <TR>
                  <TH colSpan={3}>Question</TH>
                  <TH>Answer</TH>
                  <TH>Type</TH>
                </TR>
              </THead>
              <TBody>
                {edtSurveyAnswers.length > 0 &&
                  edtSurveyAnswers.map((response, idx) => (
                    <TR key={response.id}>
                      <TD colSpan={3}>
                        <b>{idx + 1}.</b>{" "}
                        {
                          <Text
                            type="span"
                            dangerouslySetInnerHTML={{
                              __html: response.text,
                            }}
                          ></Text>
                        }
                      </TD>
                      <TD>{response.children}</TD>
                      <TD>{response.type}</TD>
                    </TR>
                  ))}
              </TBody>
            </Table>
          </AccordionPanel>
        </Accordion>
      </Card>
    </div>
  );
};

export default EdtSurveyResult;
