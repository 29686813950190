import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Backdrop, Modal, Fade, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Context, actions } from '../context';

const useStyles = makeStyles((theme) => ({
  modal: {
    width: '55%',
    margin: 'auto',
    marginTop: '6%',
  },
  icon: {
    height: '20px',
    width: '20px',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '4px solid #FBCE07',
  },
}));

const InfoModal = () => {
  const [state, dispatch] = useContext(Context);
  const { infoFlag } = state;
  const classes = useStyles();

  const handleModalClose = () => {
    dispatch({
      type: actions.SET_InfoModalFlag,
      payload: false,
    });
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={infoFlag}
        onClose={handleModalClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={infoFlag}>
          <div className={classes.paper}>
            <IconButton
              aria-label="closeButton"
              onClick={handleModalClose}
              style={{ padding: '0', float: 'right' }}
            >
              <CloseIcon className={classes.icon} />
            </IconButton>

            <img
              src="Info.PNG"
              alt="info"
              style={{
                width: '100%',
                // maxWidth: "675px",
                height: '100%',
              }}
            ></img>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default InfoModal;
