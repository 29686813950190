import React, { useEffect } from "react";
import DhcScoreSummary from "./DhcScoreSummary";

const DhcSummary = ({
  contentSurveyResult = [],
  softwareSurveyResult = [],
  listofAgreementLevels,
  selectedProject,
  selectedProject: {
    ProjectName,
    Description,
    PointOfContactEmailID,
    ImpactID,
    ImpactLevel,
    ImpactDescription,
    ComplexityID,
    ComplexityLevel,
    ComplexityDescription,
  } = {},
  graphResult,
  setGraphResult,
  selectedFlag,
  formData,
}) => {
  useEffect(() => {
    console.log("Summary Page - Form Data", formData);
  }, []);
  useEffect(() => {
    surveyCalculation();
  }, []);

  const surveyCalculation = () => {
    let sumOfContentSurvey = contentSurveyResult.reduce((sum, res) => {
      return (
        sum +
        (res.LevelofAgreementID === -1
          ? 0
          : parseInt(res.LevelofAgreementID, 10) >
            parseInt(res.RecommendedLevelofAgreementID, 10)
          ? parseInt(res.RecommendedLevelofAgreementID, 10)
          : parseInt(res.LevelofAgreementID, 10))
      );
    }, 0);
    let sumOfSoftwareSurvey = softwareSurveyResult.reduce((sum, res) => {
      return (
        sum +
        (res.LevelofAgreementID === -1
          ? 0
          : parseInt(res.LevelofAgreementID, 10) >
            parseInt(res.RecommendedLevelofAgreementID, 10)
          ? parseInt(res.RecommendedLevelofAgreementID, 10)
          : parseInt(res.LevelofAgreementID, 10))
      );
    }, 0);
    let percentOfContentQuestions = contentSurveyResult.reduce((sum, res) => {
      return sum + parseInt(res.RecommendedLevelofAgreementID, 10);
    }, 0);

    let percentOfSoftwareQuestions = softwareSurveyResult.reduce((sum, res) => {
      return sum + parseInt(res.RecommendedLevelofAgreementID, 10);
    }, 0);

    let percentOfContentResult = Math.floor(
      (sumOfContentSurvey / percentOfContentQuestions) * 100
    );

    let percentOfSoftwareResult = Math.floor(
      (sumOfSoftwareSurvey / percentOfSoftwareQuestions) * 100
    );

    let overallSummary = Math.floor(
      (percentOfContentResult + percentOfSoftwareResult) / 2
    );

    setGraphResult((prevState) => ({
      ...prevState,
      contentSurveyResult: !isNaN(percentOfContentResult)
        ? percentOfContentResult
        : 0,
      softwareSurveyResult: !isNaN(percentOfSoftwareResult)
        ? percentOfSoftwareResult
        : 0,
      overallSurveyResult: !isNaN(overallSummary) ? overallSummary : 0,
    }));
  };

  return (
    <DhcScoreSummary
      ProjectName={
        selectedFlag === "existingProject" ? ProjectName : formData.Name
      }
      Description={
        selectedFlag === "existingProject" ? Description : formData.Description
      }
      EmailID={
        selectedFlag === "existingProject"
          ? PointOfContactEmailID
          : formData.Email
      }
      overallSurveyResult={graphResult.overallSurveyResult}
      contentSurveyResult={graphResult.contentSurveyResult}
      softwareSurveyResult={graphResult.softwareSurveyResult}
      complexity={{
        ComplexityID,
        ComplexityLevel,
        ComplexityDescription,
      }}
      impact={{ ImpactID, ImpactLevel, ImpactDescription }}
      formData={formData}
      selectedFlag={selectedFlag}
    />
  );
};

export default DhcSummary;
