import React, { useState, useEffect } from "react";
import DhcQuestionView from "./DhcQuestionView";

const surveyHeading = {
  CA: "Content Assurance Survey",
  SE: "Software Engineering Survey",
};

const DhcQuestions = (props) => {
  const [queId, setQueId] = useState(0);
  // const [viewDesc, setViewDesc] = useState(false);
  // console.log("M", props.surveyQuestions.length);
  const [viewDesc, setViewDesc] = useState([]);
  useEffect(() => {
    // console.log("Survey Questions updated:", props.surveyQuestions);
    // setViewDesc(
    //   Array.from({ length: props.surveyQuestions.length }, () => false)
    // );
    setViewDesc((prevData) => {
      return Array.from({ length: props.surveyQuestions.length }, (_, index) =>
        index < prevData.length ? prevData[index] : false
      );
    });
  }, [props.surveyQuestions]);

  // console.log(props);
  // useEffect(() => {
  //   setViewDesc(
  //     Array.from({ length: props.surveyQuestions.length }, () => false)
  //   );
  // }, []);
  // console.log("A", viewDesc);
  let {
    surveyQuestions,
    agreementLevels,
    contentSurveyResult,
    softwareSurveyResult,
    setContentSurveyResult,
    setSoftwareSurveyResult,
    Heading,
    incremental = 0,
    marks = agreementLevels.map((val) => {
      let res = {
        AgreementID: val.AgreementID,
        AgreementValueID: val.AgreementValueID,
        value: incremental,
        label: val.AgreementLevel,
      };
      incremental = (incremental || 0) + 25;
      return res;
    }),
  } = props;

  const onSliderChange = (value, id) => {
    let ids = id,
      [Qid, Sid, RecommendedLevelofAgreementID] = ids.split("_");
    let index = marks.findIndex((mark) => mark.AgreementValueID === value),
      getResult = (inputContent) => {
        let content = inputContent;
        let contentIndex = content.findIndex((res) => res.QuestionID === Qid);

        if (contentIndex >= 0) {
          content[contentIndex].LevelofAgreementID =
            marks[index].AgreementValueID;
          content[contentIndex].SurveyID = Sid;
        } else {
          content.push({
            QuestionID: Qid,
            SurveyID: Sid,
            RecommendedLevelofAgreementID: RecommendedLevelofAgreementID,
            LevelofAgreementID: marks[index].AgreementValueID,
          });
        }
        props.setCount(Heading, content.length);
        return content;
      };

    if (surveyHeading[Heading] === surveyHeading.CA) {
      setContentSurveyResult(getResult(contentSurveyResult));
    } else if (surveyHeading[Heading] === surveyHeading.SE) {
      setSoftwareSurveyResult(getResult(softwareSurveyResult));
    }
  };

  // useEffect(() => {
  //   console.log("SSS", viewDesc);
  // }, [viewDesc]);

  const handleIconClicks = (questionId, index) => {
    setQueId(questionId);
    // console.log("BB", viewDesc);

    const newArray = [...viewDesc];
    newArray[index] = !newArray[index];
    // console.log("Saumya", newArray);
    setViewDesc(newArray);
  };

  return (
    <div className="dhc-question-container">
      {surveyQuestions.map((data, index) => {
        if (
          surveyHeading[Heading] === surveyHeading.CA &&
          data.SurveyID === 2
        ) {
          const found = contentSurveyResult.find(
            (item) => Number(item.QuestionID) === data.QuestionID
          );

          return (
            <div key={index}>
              <DhcQuestionView
                heading={Heading}
                selectedData={found ? found.LevelofAgreementID : ""}
                selected
                {...{
                  handleIconClicks,
                  queId,
                  onSliderChange,
                  marks,
                  data,
                  viewDesc,
                  index,
                }}
              />
            </div>
          );
        } else if (
          surveyHeading[Heading] === surveyHeading.SE &&
          data.SurveyID === 1
        ) {
          const found = softwareSurveyResult.find(
            (item) => Number(item.QuestionID) === data.QuestionID
          );
          return (
            <div key={index}>
              <DhcQuestionView
                heading={Heading}
                selectedData={found ? found.LevelofAgreementID : ""}
                {...{
                  handleIconClicks,
                  queId,
                  onSliderChange,
                  marks,
                  data,
                  viewDesc,
                  index,
                }}
              />
            </div>
          );
        }
      })}
    </div>
  );
};

export default DhcQuestions;
