import React, { useState, useEffect, useContext, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  IconButton,
  TextField,
  Typography,
  Grid,
} from "@material-ui/core";
import { Send } from "@material-ui/icons";
import { api, details } from "../../api";
import { Context, actions } from "../../context";
import { formatDate } from "../../common/helper";

export default function Comments({ expandedFeedback, projectid }) {
  const classes = useStyles();
  const [state, dispatch] = useContext(Context);
  const [reviewComments, setReviewComments] = useState([]);
  const [feedback, setFeedback] = useState("");
  const [disableButton, setDisableButton] = useState(true);
  const loginUser = localStorage.getItem("loginUser")
    ? JSON.parse(localStorage.getItem("loginUser"))
    : {};
  const {
    BASE_URL,
    GET_GETSURVEYCOMMENTS_URL: GET_GetSurveyComments_URL,
    POST_SAVECOMMENTS_URL: POST_SaveComments_URL,
  } = details;

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const previousFeedback = usePrevious(feedback);

  useEffect(() => {
    if (feedback !== previousFeedback) {
      getComments();
    }
    return () => {};
  }, []);

  const addComments = (event, id) => {
    setDisableButton(true);
    event.preventDefault();
    let url = `${BASE_URL}${POST_SaveComments_URL}`;
    let { UserID, EmailID, FirstName, LastName } = loginUser;

    let _comments = {
      EmailID,
      FirstName,
      LastName,
      CommenterID: UserID,
      ProjectID: id,
      Comment: feedback,
      CommentDate: new Date().toISOString(),
    };

    api
      .postCalls("addComments", url, _comments)
      .then((res) => {
        let { error } = res;
        if (error) {
          setBanner(true, "error", error);
          setDisableButton(true);
        } else {
          setFeedback("");
          setDisableButton(true);
          getComments();
        }
      })
      .catch((err) => {
        err && setBanner(true, "error", err);
      });
  };

  const getComments = () => {
    let url = `${BASE_URL}${GET_GetSurveyComments_URL}`;
    api
      .getCalls("fetchSurveyComments", url)
      .then((response) => {
        let { data: { recordset = [] } = {}, error } = response;
        error ? setBanner(true, "error", error) : setReviewComments(recordset);
      })
      .catch((err) => {
        err && setBanner(true, "error", err);
      });
  };

  const setBanner = (show, type = "error", message) => {
    dispatch({
      type: actions.SET_Banner,
      payload: {
        show,
        type,
        message,
      },
    });
  };

  const feedbackContent = (id) => {
    return (
      <Paper
        component="form"
        className={classes.feedbackContent}
        style={{
          marginTop: 15,
          display: !expandedFeedback ? "none" : "",
          padding: "15px 5px",
        }}
      >
        <TextField
          id="index"
          label="Feedback"
          fullWidth
          placeholder="Please Enter Feedback"
          color="primary"
          multiline
          rows={2}
          rowsMax={7}
          value={feedback}
          onChange={(e) => {
            let {
              target: { value },
            } = e;
            setFeedback(value);
            value.trim() ? setDisableButton(false) : setDisableButton(true);
          }}
        />
        <IconButton
          id={id}
          color="primary"
          className={classes.iconButton}
          aria-label="directions"
          onClick={(e) => addComments(e, id)}
          disabled={disableButton}
        >
          <Send />
        </IconButton>
      </Paper>
    );
  };
  const found = reviewComments.findIndex((d) => d.ProjectID === projectid);
  return (
    <>
      {reviewComments.length > 0 && (
        <>
          {found !== -1 && (
            <Typography className={classes.title}>User Feedback:</Typography>
          )}
          {reviewComments
            .map((content, index) => {
              let { FirstName, LastName, Comment, CommentDate, ProjectID } =
                content;
              return (
                ProjectID === projectid && (
                  <>
                    <Grid
                      container
                      wrap="nowrap"
                      spacing={1}
                      style={{ borderTop: "1px solid #D9D9D9" }}
                    >
                      <Grid
                        justifyContent="left"
                        item
                        xs
                        zeroMinWidth
                        style={{ padding: "2px 16px" }}
                      >
                        <Typography className={classes.subContent}>
                          {`${FirstName} ${LastName}`} -{" "}
                          {formatDate(CommentDate)}
                        </Typography>
                        <Typography className={classes.commentSection}>
                          {Comment}
                        </Typography>
                      </Grid>
                    </Grid>
                  </>
                )
              );
            })
            .filter((comment) => comment)}
        </>
      )}

      {feedbackContent(projectid)}
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  feedbackContent: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
  },
  title: {
    marginTop: 8,
    fontSize: "14px",
    fontWeight: 600,
  },
  subContent: {
    margin: 0,
    color: "#7F7F7F",
    fontSize: "14px",
    fontWeight: 400,
  },
  commentSection: {
    fontSize: "14px",
    fontWeight: 400,
  },
}));
