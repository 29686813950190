export const checkArr = () => {
  return [
    {
      name: 'mathematics',
      label: 'Mathematics',
    },
    {
      name: 'computationalScience',
      label: 'Computational Science',
    },
    {
      name: 'dataScience',
      label: 'Data Science',
    },
    {
      name: 'statistics',
      label: 'Statistics',
    },
    {
      name: 'appliedPhysics',
      label: 'Applied Physics',
    },
    {
      name: 'optimization',
      label: 'Optimization',
    },
    {
      name: 'machineVision',
      label: 'Machine Vision',
    },
    {
      name: 'NLP',
      label: 'NLP',
    },
    {
      name: 'AIAutomation',
      label: 'AI Engineering/Automation',
    },
    {
      name: 'Visualization',
      label: 'Visualization/External Content',
    },
    { name: 'noneoftheabove', label: 'None of the above' },
  ];
};

export const checkArrForYesOrNo = () => {
  return [
    {
      name: 'Yes',
      label: 'Yes',
    },
    {
      name: 'No',
      label: 'No',
    },
  ];
};

export const technicalAssuranceOptions = () => {
  return [
    {
      name: 'Yes, less than 9 months ago',
      label: 'Yes, less than 9 months ago',
    },
    {
      name: 'Yes, more than 9 months ago',
      label: 'Yes, more than 9 months ago',
    },
    {
      name: 'No',
      label: 'No',
    },
  ];
};
