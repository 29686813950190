import React, { useState, useContext, useEffect } from "react";
import RiskAssessmentSurveyView from "./RiskAssessmentSurveyView";
import { details } from "../../api";
import axios from "axios";
import { Context, actions } from "../../context";
import { useHistory } from "react-router-dom";
import get from "lodash.get";
import SnackBar from "../SnackBars";
import {
  Button,
  Card,
  Heading,
  Loading,
  Modal,
  Text,
} from "@sede-x/shell-ds-react-framework";
import { InfoCircle } from "@sede-x/shell-ds-react-framework/build/esm/components/Icon/components";
import "./RiskAssesment.css";

const colorList = [];

export default function RiskAssessment() {
  const [open, setOpen] = useState(false);
  const handleOnClose = () => setOpen(false);
  const [customContain, setCustomContain] = useState("");
  const [state, dispatch] = useContext(Context);
  const [errors, setErrors] = useState({});
  const [checked, setChecked] = React.useState({
    mathematics: false,
    computationalScience: false,
    dataScience: false,
    statistics: false,
    appliedPhysics: false,
    optimization: false,
    machineVision: false,
    NLP: false,
    AIAutomation: false,
    Visualization: false,
    noneoftheabove: false,
  });
  const defaultColorCode = [
    { sId: 1, lId: 1, color: "#008443" },
    { sId: 1, lId: 2, color: "#EB8705" },
    { sId: 1, lId: 3, color: "#EB8705" },
    { sId: 2, lId: 1, color: "#EB8705" },
    { sId: 2, lId: 2, color: "#EB8705" },
    { sId: 2, lId: 3, color: "#DD1D21" },
    { sId: 3, lId: 1, color: "#EB8705" },
    { sId: 3, lId: 2, color: "#DD1D21" },
    { sId: 3, lId: 3, color: "#DD1D21" },
  ];
  const {
    GET_RAM_QUESTIONS_URL,
    BASE_URL,
    POST_RAMDETAILS_URL,
    POST_PROJECTDETAILS_URL,
    POST_SURVEYDETAILS_URL,
    SAVE_EDT_SURVEY,
  } = details;
  const {
    newProjectID,
    project,
    RAMFinalData,
    formData,
    surveyAllData,
    ramQuestions,
    selectedProject,
    DHCID,
    EDT,
    banner,
    selectedFlag,
    EDTSurvey,
  } = state;

  const [formSubmit, setFormSubmit] = useState(false);
  const history = useHistory();
  const loginUser = localStorage.getItem("loginUser")
    ? JSON.parse(localStorage.getItem("loginUser"))
    : {};

  useEffect(() => {
    if (!formSubmit) {
      window.addEventListener("beforeunload", alertUser);
      return () => {
        window.removeEventListener("beforeunload", alertUser);
      };
    }
  }, [formSubmit]);

  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "You have unsaved changes, are you sure you want to leave?";
  };

  useEffect(() => {
    console.log(formSubmit);

    if (!formSubmit) {
      const unblock = history.block((location, action) => {
        if (action === "PUSH" && location.pathname !== "/ramResult") {
          return window.confirm(
            "You have unsaved changes, are you sure you want to leave this page?"
          );
        }
        return true;
      });
      return () => unblock();
    }
  }, [history, formSubmit]);

  useEffect(() => {
    console.log("RAMFinalData", RAMFinalData);
    console.log("formData", formData);
    console.log("surveyAllData", surveyAllData);
  }, []);
  const handleCustomFieldData = (e) => {
    setCustomContain(e.target.value);
  };

  // for Project Contents
  const handleChecked = (event) => {
    if (event.target.name === "noneoftheabove") {
      setChecked({
        mathematics: false,
        computationalScience: false,
        dataScience: false,
        statistics: false,
        appliedPhysics: false,
        [event.target.name]: event.target.checked,
      });
    } else {
      setErrors({});
      setChecked({
        ...checked,
        noneoftheabove: false,
        [event.target.name]: event.target.checked,
      });
    }
  };

  const handleSelectionChange = (e, index) => {
    console.log(index, e.target.value);
    ramQuestions[index].selectedChoice = e.target.value;
    dispatch({
      type: actions.SET_RAMQuestions,
      payload: ramQuestions,
    });
  };

  useEffect(() => {
    Promise.all([loadRAMQuestions()]);
    return () => {
      dispatch({
        type: actions.SET_RAMQuestions,
        payload: [],
      });
      setBanner(false, "", "");
    };
  }, []);

  const loadRAMQuestions = async () => {
    try {
      const url = `${BASE_URL}${GET_RAM_QUESTIONS_URL}`;
      const response = await axios.get(url); //loadRAMQuestions
      const { data: { recordset: recordSet = [] } = {}, error } = response;
      console.log(response);
      error
        ? setBanner(true, "error", error)
        : dispatch({
            type: actions.SET_RAMQuestions,
            payload: recordSet.map((item) => {
              const arr = [1, 2, 17];
              if (!arr.includes(item.QuestionID)) {
                return {
                  ...item,
                  selectedChoice: 0,
                };
              } else {
                return {
                  ...item,
                };
              }
            }),
          });
    } catch (error) {
      const errorCode = error.response ? error.response.status : 500;
      console.log(`Risk Assessment Details not Loaded-`, error.message);
      history.push("/errorPage", { error, errorCode });
    }
  };

  const setBanner = (show, type = "error", error) => {
    dispatch({
      type: actions.SET_Banner,
      payload: {
        show,
        type,
        message: error,
      },
    });
  };

  const setAnswerData = (data) => {
    const id = data.QuestionID;
    let objData = "";
    Object.keys(checked).forEach((obj) => {
      if (obj === "noneoftheabove" && checked[obj] === true) {
        objData = `${customContain};`;
      } else {
        if (checked[obj] === true) {
          objData = objData.concat(`${obj};`);
        }
      }
    });
    if (id === 1) {
      if (selectedFlag === "newProject") {
        return formData.Name;
      } else {
        return selectedProject.ProjectName;
      }
    } else if (id === 2) {
      if (selectedFlag === "newProject") {
        return formData.FunnelProjectID && formData.FunnelProjectID.toString();
      } else {
        return (
          selectedProject.FunnelProjectID &&
          selectedProject.FunnelProjectID.toString()
        );
      }
    } else if (id === 18) {
      if (objData === ";") {
        setErrors({ customContain: "Please Enter Contain Text" });
        return "";
      } else {
        return objData;
      }
    }
    return "";
  };

  const handleSubmit = async () => {
    let RamAnswers = ramQuestions.map((data) => {
      return data.selectedChoice
        ? data.selectedChoice.toString()
        : setAnswerData(data);
    });
    console.log("Ram Answers-", RamAnswers);
    const allAnswered = RamAnswers.findIndex((d) => d === "");
    // console.log("allAnswered - ", allAnswered);
    if (allAnswered === -1) {
      try {
        let ProjectID, ProjectAreas;
        // First API call
        if (selectedFlag === "newProject") {
          ProjectAreas = formData.Areas;
          let url1 = `${BASE_URL}${POST_PROJECTDETAILS_URL}`;
          let response1 = await axios.post(url1, formData);
          let { data: { recordset: recordSet = [] } = {}, error: error1 } =
            response1;
          ProjectID = recordSet[0].ProjectID;

          dispatch({
            type: actions.SET_PROJECT_ID,
            payload: ProjectID,
          });

          // console.log("Project ID- ", ProjectID);
          // console.log("Response1", response1);
          if (error1) {
            console.log("Error in Create Project", response1.error);
            setBanner(true, "error", error1);
            throw new Error(error1); // Stop execution if there's an error
          } else {
            console.log("Project Created Successfully", recordSet[0]);
            setBanner(true, "success", "Project Created Successfully");
            // LoadProjects(true);
          }
        } else if (selectedFlag === "existingProject") {
          ProjectID = selectedProject.ProjectID;
          ProjectAreas = selectedProject.Areas;
          dispatch({
            type: actions.SET_PROJECT_ID,
            payload: ProjectID,
          });
        }
        // console.log("Project Area: ", ProjectAreas);
        let DHCIDreturnValue = "",
          EDTID;
        // Second API call
        if (ProjectAreas === "Emerging Digital Technologies") {
          // console.log("Inside", ProjectAreas, ProjectID);
          const url2 = `${BASE_URL}${SAVE_EDT_SURVEY}`;
          const params = { ...EDTSurvey, projectId: ProjectID };
          const response2 = await axios.post(url2, params); //'saveEdt'
          // console.log(response2);
          EDTID = response2.data.edtId;
          dispatch({
            type: actions.SET_EDT,
            payload: response2.data,
          });
        } else {
          let url2 = `${BASE_URL}${POST_SURVEYDETAILS_URL}`;
          const params = { ...surveyAllData, ProjectID };
          let response2 = await axios.post(url2, params);
          let { error: error2, data: { recordset = [], returnValue } = {} } =
            response2;
          DHCIDreturnValue = returnValue;
          console.log("Response2", response2);
          console.log("returnValue", returnValue);
          // Check for errors in the second API call
          if (response2.error) {
            console.log("Error in Survey Details", response2.error);
            setBanner(true, "error", response2.error);
            throw new Error(response2.error); // Stop execution if there's an error
          } else {
            dispatch({
              type: actions.SET_DHCID,
              payload: returnValue,
            });
          }
        }

        // Third API call
        const url3 = `${BASE_URL}${POST_RAMDETAILS_URL}`;
        const finalData = ramQuestions.map((data) => {
          const sectionData = colorList.find(
            (k) => k.SectionID === data.SectionID
          );
          return {
            ProjectID: ProjectID,
            QuestionID: data.QuestionID,
            SectionID: data.SectionID,
            Answer: data.selectedChoice
              ? data.selectedChoice.toString()
              : setAnswerData(data),
            colorCode: sectionData ? sectionData.color : "",
            userID: loginUser.UserID,
            DHCID: DHCIDreturnValue,
            EdtID: EDTID,
          };
        });
        // console.log("RRRReturnValue", DHCIDreturnValue);
        console.log("Final Data URL 3- ", finalData);
        const found = finalData.findIndex((d) => d.Answer === "");
        console.log("FOUND-", found);
        if (found === -1) {
          const response3 = await axios.post(url3, finalData); //"submitRAM"
          const { error } = response3;
          console.log("Response 3- ", response3);
          if (error) setBanner(true, "error", error);
          else {
            setBanner(true, "info", "Successfully Added");
            dispatch({
              type: actions.SET_selectedMenuIndex,
              payload: {
                index: 1,
              },
            });
            await new Promise((resolve) => {
              setFormSubmit(true);
              resolve();
            });
            history.push("/ramResult");
            // setTimeout(() => {
            //   history.push("/ramResult");
            // }, 2);
          }
        } else {
          setBanner(true, "error", "Please Select All the Answer");
        }
      } catch (error) {
        const errorCode = error.response ? error.response.status : 500;
        console.log(`RAM Survey not Submitted-`, error);
        history.push("/errorPage", { error, errorCode });
      }
    } else {
      setBanner(true, "error", "Please Select All the Answers");
    }
  };

  // const handleSubmit = async () => {
  //   try {
  //     const url = `${BASE_URL}${POST_RAMDETAILS_URL}`;
  //     const finalData = ramQuestions.map((data) => {
  //       const sectionData = colorList.find(
  //         (k) => k.SectionID === data.SectionID
  //       );
  //       return {
  //         ProjectID: selectedProject.ProjectID,
  //         QuestionID: data.QuestionID,
  //         SectionID: data.SectionID,
  //         Answer: data.selectedChoice
  //           ? data.selectedChoice.toString()
  //           : setAnswerData(data),
  //         colorCode: sectionData ? sectionData.color : "",
  //         userID: loginUser.UserID,
  //         DHCID: DHCID,
  //         EdtID: EDT.edtId,
  //       };
  //     });
  //     const found = finalData.findIndex((d) => d.Answer === "");
  //     if (found === -1) {
  //       console.log(finalData);
  //       dispatch({
  //         type: actions.SET_RAM_FINAL_DATA,
  //         payload: finalData,
  //       });
  //       const response = await axios.post(url, finalData); //"submitRAM"
  //       console.log(response);
  //       const { error } = response;
  //       if (error) setBanner(true, "error", error);
  //       else {
  //         setBanner(true, "info", "Successfully Added");
  //         return (
  //           dispatch({
  //             type: actions.SET_selectedMenuIndex,
  //             payload: {
  //               index: 1,
  //             },
  //           }),
  //           // history.push('/review')
  //           history.push("/ramResult")
  //         );
  //       }
  //     } else {
  //       setBanner(true, "error", "Please Select All the Answer");
  //     }
  //     return undefined;
  //   } catch (error) {
  //     const errorCode = error.response ? error.response.status : 500;
  //     console.log(`RAM Survey not Submitted-`, error.message);
  //     history.push("/errorPage", { error, errorCode });
  //   }
  // };

  const handleChoiceChange = (e, id) => {
    console.log(id, e.target.value);
    const arr = ramQuestions.map((d) => {
      if (d.QuestionID === id) {
        const selectedData = ramQuestions.find(
          (k) => k.SectionID === d.SectionID && k.QuestionID !== d.QuestionID
        );
        if (id < selectedData.QuestionID) {
          const test = defaultColorCode.find(
            (item) =>
              item.sId === Number(e.target.value) &&
              item.lId === selectedData.selectedChoice
          );
          const found = colorList.findIndex(
            (e1) => e1.SectionID === d.SectionID
          );
          if (found === -1) {
            colorList.push({
              SectionID: d.SectionID,
              color: test ? test.color : "",
            });
          } else {
            colorList.splice(found, 1);
            const colors = test ? test.color : "";
            colorList.push({ SectionID: d.SectionID, color: colors });
          }
        } else {
          const test = defaultColorCode.find(
            (item) =>
              item.sId === selectedData.selectedChoice &&
              item.lId === Number(e.target.value)
          );
          const found = colorList.findIndex(
            (e1) => e1.SectionID === d.SectionID
          );
          if (found === -1) {
            colorList.push({
              SectionID: d.SectionID,
              color: test ? test.color : "",
            });
          } else {
            colorList.splice(found, 1);
            const colors = test ? test.color : "";
            colorList.push({ SectionID: d.SectionID, color: colors });
          }
        }
        console.log(e.target.value);
        return {
          ...d,
          selectedChoice: Number(e.target.value),
        };
      }
      return { ...d };
    });
    dispatch({
      type: actions.SET_RAMQuestions,
      payload: arr,
    });
  };

  return (
    <>
      {banner.show && (
        <SnackBar banner={banner} onClose={() => setBanner(false, "", "")} />
      )}
      <Card className="header-card">
        <Heading type="h3" bold>
          MCDS PROJECT RISK ASSESMENT
        </Heading>
        <div className="header-title">
          <Text className="header-subtitle">
            The survey will take approximately 6 minutes to complete
          </Text>
          <Button
            iconOnly
            icon={<InfoCircle />}
            variant="transparent"
            onClick={() => {
              setOpen(true);
            }}
          />
        </div>
      </Card>
      <br />
      <br />
      <br />
      <br />

      {ramQuestions && ramQuestions.length === 0 ? (
        <Loading />
      ) : (
        ramQuestions.map((data, index) => {
          const arr = [0, 1, 14, 15, 16, 17];
          //selectedData is responsible for the thin side color bar on the right for each section
          const selectedData = colorList.find(
            (item) => item.SectionID === data.SectionID
          );
          if (arr.includes(index)) {
            return (
              <Card key={data.QuestionID} className="card-paper">
                <RiskAssessmentSurveyView
                  {...{
                    data,
                    handleChoiceChange,
                    index,
                    colorList,
                    selectedProject,
                    handleSelectionChange,
                    handleChecked,
                    checked,
                    handleCustomFieldData,
                    customContain,
                    errors,
                    selectedFlag,
                    formData,
                  }}
                />
              </Card>
            );
          } else {
            //color is visible only when the 2nd question of the section is answered
            if (index % 2 !== 0) {
              return false;
            }
            return (
              <>
                <Card className="card-paper">
                  <div
                    className="vertical"
                    style={{
                      borderRight: get(selectedData, "color")
                        ? `4px solid ${get(selectedData, "color", "")}`
                        : "",
                    }}
                  >
                    <RiskAssessmentSurveyView
                      {...{
                        data,
                        handleChoiceChange,
                        index,
                        colorList,
                        selectedProject,
                        handleSelectionChange,
                        handleChecked,
                        checked,
                        handleCustomFieldData,
                        customContain,
                        errors,
                        selectedFlag,
                        formData,
                      }}
                    />
                    <br />
                    {ramQuestions.length !== Number(index + 1) && (
                      <RiskAssessmentSurveyView
                        {...{
                          data: ramQuestions[Number(index + 1)],
                          handleChoiceChange,
                          index,
                          colorList,
                          selectedProject,
                          handleSelectionChange,
                          handleChecked,
                          checked,
                          handleCustomFieldData,
                          customContain,
                          errors,
                          selectedFlag,
                          formData,
                        }}
                      />
                    )}
                  </div>
                </Card>
              </>
            );
          }
        })
      )}
      <br />
      {ramQuestions.length !== 0 && (
        <div style={{ textAlign: "center" }}>
          <Button
            size="medium"
            variant="filled"
            sentiment="default"
            onClick={() => handleSubmit()}
            style={{ marginBottom: "100px" }}
          >
            SUBMIT
          </Button>
        </div>
      )}
      <br />

      <Modal
        size="small"
        showFooter={false}
        open={open}
        // padding="tight"
        // bodyPadding={false}
        centered={true}
        onClose={handleOnClose}
        width="55%"
      >
        <Heading type="h3" style={{ textAlign: "center" }}>
          MCDS Project Risk Assessment
        </Heading>
        <br />
        <Text>
          This form aims to create a qualitative assessment of the risks
          involved in putting a data science/computational science project into
          production. The outcomes of this risk assessment will lead to a
          decision on whether Technical Assurance is required for the project.
          <br />
          <br />
          This survey should be completed by the Project Lead or Digital
          Opportunity/Product Owner.
          <br />
          <br />
        </Text>
        Please rate the project against the risks below, keeping in mind that
        indication should include:
        <ul>
          <li>Worst case Potential risk (how bad it can be)</li>
          <li>Without barriers (missing or failed)</li>
          <li>Based on historical incidents</li>
        </ul>
        <Text>
          Identify potential consequences, specifically the “worst-case”
          consequences that could develop from the hazard under the prevailing
          conditions. Ask the question “What could happen if the controls don't
          work or are not in place?‟
          <br />
          <br />
          The Likelihood level is judged from past experience, by asking the
          question ”How often has a hazard release resulted in a consequence
          similar to the present one?‟
          <br />
          <br />
          Detailed guidance and examples of Consequences for each risk can be
          found{" "}
          <a
            href="          https://eu001-sp.shell.com/:p:/r/sites/AAFAA4423/_layouts/15/Doc.aspx?sourcedoc=%7BA40590EC-1867-44C5-9D78-C7B32BBEEE54%7D&file=MCDS%20RAM%2024.03.2021.pptx&action=edit&mobileredirect=true"
            target="_blank"
          >
            here
          </a>
          .
          <br />
          <br />
          For further questions, please contact Lydia Stanier ({" "}
          <a href="mailto:Lydia.Stanier@shell.com">Lydia.Stanier@shell.com</a>)
          or Tharini Thirukkumar (
          <a href="mailto:tharini.thirukkumar@shell.com">
            Tharini.Thirukkumar@shell.com
          </a>
          )
        </Text>
      </Modal>
    </>
  );
}
