import {
  Avatar,
  Card,
  Grid,
  Heading,
  Text,
} from "@sede-x/shell-ds-react-framework";
import "./ContactDetails.css";

import React from "react";

export default function ContactDetails({ contactArr }) {
  return (
    <>
      <Card className="header-card">
        <Heading type="h3" bold={true}>
          CONTACT INFORMATION
        </Heading>
      </Card>
      <br />
      <br />
      {contactArr[0] && (
        <Grid container columnGap="40px">
          <Grid children key={contactArr[0].imageSrc}>
            <Card className="contact-card">
              <div className="card-contents">
                <Avatar
                  src={contactArr[0].imageSrc}
                  alt="contact-image"
                  size="large"
                  className="contact-avatar-img"
                  style={{ width: "200px", height: "200px" }}
                />

                <Text bold size="large">
                  {contactArr[0].name}
                </Text>
                <Text>{contactArr[0].role}</Text>
                <Text>
                  <a href={`mailto:${contactArr[0].email}`}>
                    {contactArr[0].email}
                  </a>
                </Text>
              </div>
            </Card>
          </Grid>
        </Grid>
      )}
      <br />
      <br />
      <Grid container columns={2} columnGap="52px">
        {contactArr.slice(1).map((item) => (
          <Grid children key={item.imageSrc}>
            <Card className="contact-card">
              <div className="card-contents">
                <Avatar
                  src={item.imageSrc}
                  alt="contact-image"
                  size="large"
                  className="contact-avatar-img"
                  style={{ width: "130px", height: "130px" }} // Adjust as needed
                />

                <Text bold size="large">
                  {item.name}
                </Text>
                <Text>{item.role}</Text>
                <Text>
                  <a href={`mailto:${item.email}`}>{item.email}</a>
                </Text>
              </div>
            </Card>
          </Grid>
        ))}
      </Grid>
      {/* <Grid
        container
        className={classes.container}
      >
        {contactArr.map((item) => {
          return (
            <Grid
              item
              xs={5}
              key={item.imageSrc}
            >
              <Card className={classes.root}>
                <div className={classes.details}>
                  <CardContent className={classes.content}>
                    <Avatar
                      alt="Contact Image"
                      src={item.imageSrc}
                      className={classes.large}
                    />
                    <br />
                    <Typography variant="h6"> {item.name}</Typography>

                    <Typography
                      variant="subtitle1"
                      color="textSecondary"
                    >
                      {item.role}
                    </Typography>
                    <Typography variant="body2">
                      <Box fontWeight="500">
                        {' '}
                        <a href={`mailto:${item.email}`}>{item.email}</a>
                      </Box>
                    </Typography>
                  </CardContent>
                </div>
              </Card>
            </Grid>
          );
        })}
      </Grid> */}
    </>
  );
}
