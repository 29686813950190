import {
  Avatar,
  Button,
  Menu,
  MenuItem,
  Modal,
  NavBar,
  Text,
  Tooltip,
} from "@sede-x/shell-ds-react-framework";
import {
  BarChartSquareSolid,
  HomeSolid,
  InfoSquare,
  PeopleSquare,
  QuestionMarkSquare,
} from "@sede-x/shell-ds-react-framework/build/esm/components/Icon/components";
import React, { useContext, useState } from "react";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";
import { Context, actions } from "../../context";
import { useEffect } from "react";

const MENU_OPTIONS_TO_COMPONENT = {
  Home: HomeSolid,
  Dashboard: BarChartSquareSolid,
  FAQ: QuestionMarkSquare,
  Contact: PeopleSquare,
  // TestPage: PeopleSquare,
};

const VerticalMenu = ({ onClick }) => {
  return (
    <Menu defaultSelectedKeys={["Home"]} mode="vertical">
      {Object.keys(MENU_OPTIONS_TO_COMPONENT).map((text, index) => {
        const Component = MENU_OPTIONS_TO_COMPONENT[text];
        return (
          // <Tooltip
          //   title="Click For More Information"
          //   key={text}
          //   arrow
          //   style={{ cursor: "pointer" }}
          // >
          <MenuItem
            key={text}
            onClick={() => onClick(text, index)}
            itemIcon={<Component />}
          ></MenuItem>
          //</Tooltip>
        );
      })}
    </Menu>
  );
};

const SideNavBar = () => {
  console.log("rendered");
  const history = useHistory();
  const location = useLocation();
  const [_, dispatch] = useContext(Context);

  const [open, setOpen] = useState(false);
  const handleOnClose = () => setOpen(false);

  // Set index of the selected menu everytime when app renders (consistency)
  useEffect(() => {
    if (location.pathname === "/home") {
      storeIndexData(0);
    } else if (location.pathname === "/review") {
      storeIndexData(1);
    } else if (location.pathname === "/FAQ") {
      storeIndexData(2);
    } else if (location.pathname === "/contact") {
      storeIndexData(3);
    } else if (location.pathname === "/survey") {
      storeIndexData(4);
    } else if (location.pathname === "/riskAssessment") {
      storeIndexData(5);
    } else if (location.pathname === "/edt") {
      storeIndexData(6);
    } else if (location.pathname === "/edtResult") {
      storeIndexData(7);
    }
  }, []);

  // storing index in state
  const storeIndexData = (index) => {
    dispatch({
      type: actions.SET_selectedMenuIndex,
      payload: {
        index,
      },
    });
  };

  // handle click of menu items, navigating to the desired route
  const handleClick = (text, index) => {
    storeIndexData(index);
    if (text === "Home") {
      history.push("/home");
    } else if (text === "Dashboard") {
      history.push("/review");
    }
    if (text === "FAQ") {
      history.push("/FAQ");
    }
    if (text === "Contact") {
      history.push("/contact");
    }
    // if (text === 'TestPage') {
    //   history.push('/test');
    // }
  };

  // vertical menu options of navbar

  return (
    <NavBar
      float={true}
      orientation="vertical"
      pectenArea="default"
      avatarArea={
        <Button
          alignment="edge"
          icon={<InfoSquare />}
          iconOnly
          iconPosition="left"
          sentiment="default"
          size="medium"
          variant="transparent"
          onClick={() => setOpen(true)}
        />
      }
    >
      <VerticalMenu onClick={handleClick} />
      <Modal
        size="small"
        showFooter={false}
        open={open}
        padding="tight"
        bodyPadding={false}
        centered={true}
        onClose={handleOnClose}
        width="55%"
      >
        <img
          src="Info.PNG"
          alt="info"
          style={{
            width: "100%",
            height: "100%",
          }}
        />
      </Modal>
    </NavBar>
  );
};

export default SideNavBar;
