import React, { useState, useRef, useEffect, useContext, useMemo } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { amber } from '@material-ui/core/colors';
import {
  Comment,
  ExpandMore,
  GridOnOutlined,
  SearchOutlined,
} from '@material-ui/icons';
import {
  Paper,
  Collapse,
  Avatar,
  Typography,
  IconButton,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Tooltip,
  LinearProgress,
  Chip,
  Divider,
  Button,
  AccordionActions,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import { api, details } from '../../api';
import { SummaryView, Comments, QuestionComment } from '../survey';
import { Context, actions } from '../../context';
import orderBy from 'lodash.orderby';

import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import get from 'lodash.get';

import { Question } from 'survey-react';
import Export from './Export';

const surveyAllTypes = [
  ['CA', 'Quality Metrics - Content Assurance'],
  ['SE', 'Quality Metrics - Software Engineering'],
  ['RAM', 'Risk Assessment Matrix'],
];

const surveyDHCTypes = [
  ['CA', 'Quality Metrics - Content Assurance'],
  ['SE', 'Quality Metrics - Software Engineering'],
];

const levelColorCode = {
  Agree: '#008443',
  'Strongly Agree': '#008443',
  Neutral: '#EB8705',
  Disagree: '#DD1D21',
  'Strongly Disagree': '#DD1D21',
  'Too Early': '#808080',
};

export default function ReviewPage() {
  const classes = useStyles();
  const [state, dispatch] = useContext(Context);
  const [reviews, setReviews] = useState({
    reviewDetails: [],
    reviewInDetails: [],
    reviewRAMDetails: [],
  });
  const [expanded, setExpanded] = React.useState('panel0');
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const [detailExpanded, setDetailExpanded] = useState(false);
  const handleDetailChange = (panel, id) => (_, isExpanded) => {
    setDetailExpanded(isExpanded ? `${panel}:${id}` : false);
  };

  const [expandedFeedback, setExpandedFeedback] = useState(false);
  const [expandedQueFeedback, setExpandedQueFeedback] = useState(false);
  const [questionId, setQuestionId] = useState(0);
  const [collapsedRow, setCollapsedRow] = useState(null);
  const [loading, setLoading] = useState(false);
  const { BASE_URL, GET_REVIEWDETAILS_URL } = details;
  let { reviewDetails, reviewInDetails, reviewRAMDetails } = reviews;

  //Search Filter
  const [searchQuery, setSearchQuery] = useState('');
  const [domainFilters, setDomainFilters] = useState([]);
  const filteredReviews = useMemo(() => {
    if (domainFilters.length === 0) {
      return reviewDetails.filter((item) => {
        return item.ProjectName.toLowerCase().includes(
          searchQuery.toLowerCase()
        );
        // return domainFilters.includes(item.Areas);
      });
    } else {
      let filteredResults;
      filteredResults = reviewDetails.filter((item) => {
        return domainFilters.includes(item.Areas);
      });
      return filteredResults.filter((item) => {
        return item.ProjectName.toLowerCase().includes(
          searchQuery.toLowerCase()
        );
      });
    }
  }, [reviewDetails, searchQuery, domainFilters]);

  //Domain Filter Chip
  const [chipFilters, setChipFilters] = useState([
    { key: 0, label: 'Data Science', variant: 'outlined' },
    { key: 1, label: 'Computational Science', variant: 'outlined' },
    { key: 2, label: 'Financial Operations', variant: 'outlined' },
  ]);

  const handleFilterClick = (key) => {
    const newChipFilters = [...chipFilters];
    newChipFilters[key].variant =
      newChipFilters[key].variant === 'outlined' ? 'default' : 'outlined';
    setChipFilters(newChipFilters);
    // setDomainFilter([...domainFilter, chipFilters[key].label]);
    const newFilter = chipFilters[key].label;
    const filterIndex = domainFilters.indexOf(newFilter);
    if (filterIndex === -1) {
      setDomainFilters([...domainFilters, newFilter]);
    } else {
      const newDomainFilters = [...domainFilters];
      newDomainFilters.splice(filterIndex, 1);
      setDomainFilters(newDomainFilters);
    }
  };

  const usePrevious = (value = []) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const previousReviews = usePrevious(reviewDetails);

  useEffect(() => {
    setLoading(true);
    if (
      reviewDetails.length === 0 ||
      previousReviews.length !== reviewDetails.length
    ) {
      fetchReviews();
    }
  }, []);

  const fetchReviews = async () => {
    let url = `${BASE_URL}${GET_REVIEWDETAILS_URL}`;
    let response = await api.getCalls('fetchReviews', url);
    console.log(response);

    let { data: { recordsets = [] } = {}, error } = response;
    let [Details, InDetails, RAMDetails, RAMAllDetails] = recordsets;
    if (error) {
      setBanner(true, 'error', error);
      setLoading(false);
    } else {
      setReviews((prevState) => ({
        ...prevState,
        reviewDetails: [...Details, ...RAMAllDetails],
        reviewInDetails: InDetails,
        reviewRAMDetails: RAMDetails.filter(
          (d) => d.QuestionID !== 1 && d.QuestionID !== 2
        ),
      }));
      setLoading(false);
    }
  };

  console.log(reviews);
  const setBanner = (show, type = 'error', message) => {
    dispatch({
      type: actions.SET_Banner,
      payload: {
        show,
        type,
        message,
      },
    });
  };

  const handleCommentClick = (queId) => {
    if (
      questionId !== 0 &&
      expandedQueFeedback === true &&
      questionId !== queId
    ) {
      setQuestionId(queId);
    } else {
      setExpandedQueFeedback(!expandedQueFeedback);
      setQuestionId(queId);
    }
  };

  const getReviewInDetail = (details, id, projectid, RAMId) => {
    let [panel, title] = details;

    let arr = [15, 16, 17];
    let tableRow = (
      id,
      question,
      agreement,
      SurveyID,
      Answer,
      ChoiceDesc,
      ColorCode,
      RequiredLevelofAgreementID,
      RecommendedLevelofAgreementID,
      OrderID
    ) => {
      const agreementColor = Object.keys(levelColorCode).filter(
        (key) => key === agreement
      );
      const requiredLevelColor = Object.keys(levelColorCode).filter(
        (key) => key === RequiredLevelofAgreementID
      );
      const recommendedLevelColor = Object.keys(levelColorCode).filter(
        (key) => key === RecommendedLevelofAgreementID
      );
      return (
        <>
          <TableRow key={id}>
            <TableCell
              component="th"
              scope="row"
              className={classes.cellPadding}
              style={{
                borderBottom: `${
                  questionId === id && expandedQueFeedback
                    ? 'none'
                    : '0.2px solid grey'
                }`,
                backgroundColor:
                  questionId === id && expandedQueFeedback && '#F5F5F5',
              }}
            >
              {`${
                panel === 'RAM'
                  ? `${id - 2}. ${question}`
                  : `${OrderID}. ${question}`
              }`}
              <IconButton
                aria-label="QuesFeedback"
                onClick={() => {
                  handleCommentClick(id);
                }}
                className="contentPadding"
              >
                <Tooltip
                  title="Add Comment"
                  arrow
                  className="cursorPointer"
                >
                  <Comment className="commentSection" />
                </Tooltip>
              </IconButton>
            </TableCell>
            {panel === 'RAM' ? (
              <>
                <TableCell
                  className={classes.cellPadding}
                  style={{
                    borderRight: `10px solid ${ColorCode}`,
                    borderBottom: `${
                      questionId === id && expandedQueFeedback
                        ? 'none'
                        : '0.2px solid grey'
                    }`,
                    backgroundColor:
                      questionId === id && expandedQueFeedback && '#F5F5F5',
                  }}
                >
                  {arr.includes(id)
                    ? Answer
                    : id === 18
                    ? Answer.split(';')
                        .slice(0, -1)
                        .map((d, index) => {
                          if (Answer.length - 1 === index) {
                            return <span>{d}</span>;
                          } else {
                            return (
                              <>
                                <span>{d}</span>
                                <br />
                              </>
                            );
                          }
                        })
                    : ChoiceDesc}
                </TableCell>
              </>
            ) : (
              <>
                <TableCell
                  className={classes.cellPadding}
                  style={{
                    color: levelColorCode[agreementColor],
                    borderBottom: `${
                      questionId === id && expandedQueFeedback
                        ? 'none'
                        : '0.2px solid grey'
                    }`,
                    backgroundColor:
                      questionId === id && expandedQueFeedback && '#F5F5F5',
                  }}
                >
                  {agreement}
                </TableCell>
                <TableCell
                  className={classes.cellPadding}
                  style={{
                    color: levelColorCode[requiredLevelColor],
                    borderBottom: `${
                      questionId === id && expandedQueFeedback
                        ? 'none'
                        : '0.2px solid grey'
                    }`,
                    backgroundColor:
                      questionId === id && expandedQueFeedback && '#F5F5F5',
                  }}
                >
                  {RequiredLevelofAgreementID}
                </TableCell>
                <TableCell
                  className={classes.cellPadding}
                  style={{
                    color: levelColorCode[recommendedLevelColor],
                    borderBottom: `${
                      questionId === id && expandedQueFeedback
                        ? 'none'
                        : '0.2px solid grey'
                    }`,
                    backgroundColor:
                      questionId === id && expandedQueFeedback && '#F5F5F5',
                  }}
                >
                  {RecommendedLevelofAgreementID}
                </TableCell>
              </>
            )}
          </TableRow>
          {questionId === id && expandedQueFeedback && (
            <TableRow>
              <TableCell
                colSpan={4}
                style={{ color: 'grey', backgroundColor: '#F5F5F5' }}
              >
                <QuestionComment
                  {...{ expandedQueFeedback, projectid, questionId, SurveyID }}
                />
              </TableCell>
            </TableRow>
          )}
        </>
      );
    };
    return (
      <Accordion
        expanded={detailExpanded === `${panel}:${id ? id : RAMId}`}
        onChange={handleDetailChange(panel, id ? id : RAMId)}
        className={classes.main}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon htmlColor="white" />}
          aria-controls={`panel${panel}-content`}
          id={id}
          className="heading"
        >
          <Typography className="title">{title}</Typography>
        </AccordionSummary>
        <AccordionDetails className="contentPadding">
          <TableContainer component={Paper}>
            <Table
              className={classes.table}
              size="small"
              aria-label={`${panel}-table`}
            >
              <TableHead>
                {panel === 'RAM' ? (
                  <TableRow>
                    <TableCell
                      className={classes.cellPadding}
                      style={{ width: '50%' }}
                    >
                      <b>Question</b>
                    </TableCell>{' '}
                    <TableCell className={classes.cellPadding}>
                      <b> Answer</b>
                    </TableCell>
                  </TableRow>
                ) : (
                  <TableRow>
                    <TableCell
                      className={classes.cellPadding}
                      style={{ width: '56%' }}
                    >
                      <b> Question</b>
                    </TableCell>
                    <TableCell className={classes.cellPadding}>
                      <b>Agreement Level</b>
                    </TableCell>
                    <TableCell className={classes.cellPadding}>
                      <b> Required Level</b>
                    </TableCell>
                    <TableCell className={classes.cellPadding}>
                      <b>Recommended Level</b>
                    </TableCell>
                  </TableRow>
                )}
              </TableHead>
              {panel === 'RAM' ? (
                <TableBody>
                  {
                    reviewRAMDetails.map((row) => {
                      let {
                        Answer,
                        QuestionID,
                        Question,
                        ChoiceDesc,
                        SurveyID,
                        ProjectID,
                        ColorCode,
                        RAMID,
                      } = row;
                      if (RAMId === RAMID) {
                        // console.log(RAMId);
                        //console.log(SurveyID, Question, Answer, ColorCode);
                        return tableRow(
                          QuestionID,
                          Question,
                          '',
                          SurveyID,
                          Answer,
                          ChoiceDesc,
                          ColorCode
                        );
                      }
                    })
                    // .filter((data) => data)}{' '}
                  }
                </TableBody>
              ) : (
                <TableBody>
                  {orderBy(reviewInDetails, 'QuestionID', 'asc')
                    .map((row) => {
                      let {
                        DigitalHeathCheckID,
                        QuestionID,
                        QuestionDisplay,
                        AgreementLevel,
                        SurveyID,
                        OrderID,
                        RequiredLevelofAgreementID,
                        RecommendedLevelofAgreementID,
                      } = row;
                      if (id === DigitalHeathCheckID) {
                        if (panel === 'CA' && SurveyID === 2)
                          return tableRow(
                            QuestionID,
                            QuestionDisplay,
                            AgreementLevel,
                            SurveyID,
                            '',
                            '',
                            '',
                            RequiredLevelofAgreementID,
                            RecommendedLevelofAgreementID,
                            OrderID
                          );
                        else if (panel === 'SE' && SurveyID === 1)
                          return tableRow(
                            QuestionID,
                            QuestionDisplay,
                            AgreementLevel,
                            SurveyID,
                            '',
                            '',
                            '',
                            RequiredLevelofAgreementID,
                            RecommendedLevelofAgreementID,
                            OrderID
                          );
                      }
                    })
                    .filter((data) => data)}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
    );
  };

  // Review Project Names

  return (
    <div className="mainSection">
      {loading && <LinearProgress />}
      {reviewDetails && reviewDetails.length === 0 ? (
        <></>
      ) : (
        <>
          <div className={classes.header}>
            <div className={classes.search}>
              <TextField
                fullWidth
                color="secondary"
                id="input-with-icon-grid"
                placeholder="Search Projects"
                margin="normal"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchOutlined />
                    </InputAdornment>
                  ),
                }}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
            <div className={classes.filters}>
              {chipFilters.map((filter) => (
                <Chip
                  key={filter.key}
                  label={filter.label}
                  variant={filter.variant}
                  color="secondary"
                  onClick={() => handleFilterClick(filter.key)}
                />
              ))}
            </div>
          </div>
          {(filteredReviews || []).map((item, idx) => {
            let {
              digitalheathcheckid,
              ProjectName,
              Areas,
              Description,
              EmailID,
              ComplexityID,
              ComplexityLevel,
              ComplexityDescription,
              ImpactID,
              ImpactLevel,
              ImpactDescription,
              projectid,
            } = item;
            let selectedArr = [];
            let selectedRAMArr = [];
            const ImpactColor =
              ImpactLevel === 'Low'
                ? '#008443'
                : ImpactLevel === 'Medium'
                ? '#EB8705'
                : '#DD1D21';
            const complexityColor =
              ComplexityLevel === 'Low'
                ? '#008443'
                : ComplexityLevel === 'Medium'
                ? '#EB8705'
                : '#DD1D21';
            reviewInDetails
              .filter((d) => d.ProjectID === projectid)
              .map((item) => {
                const found = selectedArr.findIndex(
                  (e1) => e1.DigitalHeathCheckID === item.DigitalHeathCheckID
                );
                if (found === -1) {
                  selectedArr.push({
                    DigitalHeathCheckID: item.DigitalHeathCheckID,
                    contentSurveyResult: item.contentSurveyResult,
                    softwareSurveyResult: item.softwareSurveyResult,
                    CreatedDate: item.CreatedDate,
                  });
                }
              });
            reviewRAMDetails
              .filter((d) => d.ProjectID === projectid)
              .map((item) => {
                const found = selectedRAMArr.findIndex(
                  (e1) => e1.RAMID === item.RAMID
                );
                if (found === -1) {
                  selectedRAMArr.push({
                    RAMID: item.RAMID,
                    TARequiredOrNot: item.TARequiredOrNot,
                    CreatedDate: item.CreatedDate,
                  });
                }
              });
            const selectedDataForRAM = selectedRAMArr.find(
              (d) => d.TARequiredOrNot
            );

            return (
              <div
                className={classes.reviews}
                key={idx}
              >
                <Accordion
                  square
                  expanded={expanded === `panel${idx}`}
                  onChange={handleChange(`panel${idx}`)}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel-content"
                    id="panel-header"
                  >
                    <div className={classes.column}>
                      <Typography
                        variant="subtitle1"
                        className={classes.title}
                      >
                        {ProjectName}
                      </Typography>
                    </div>
                    <div className={classes.column}>
                      <Typography className={classes.secondaryHeading}>
                        {Areas}
                      </Typography>
                    </div>
                    <div className={classes.column}>
                      {digitalheathcheckid ? (
                        <Typography
                          style={{
                            color: `${
                              get(selectedDataForRAM, 'TARequiredOrNot')
                                ? '#DD1D21'
                                : '#008443'
                            }`,
                          }}
                          variant="subtitle2"
                        >
                          {get(selectedDataForRAM, 'TARequiredOrNot')
                            ? 'Technical Assurance is required'
                            : 'Technical Assurance is not required'}
                        </Typography>
                      ) : (
                        <>
                          <Typography
                            style={{
                              color: `${
                                get(selectedDataForRAM, 'TARequiredOrNot')
                                  ? '#DD1D21'
                                  : '#008443'
                              }`,
                            }}
                            variant="subtitle2"
                          >
                            {get(selectedDataForRAM, 'TARequiredOrNot')
                              ? 'Technical Assurance is required'
                              : 'Technical Assurance is not required'}
                          </Typography>
                        </>
                      )}
                    </div>
                  </AccordionSummary>
                  <AccordionDetails className={classes.details}>
                    <Card>
                      <CardContent>
                        <Typography variant="subtitle2">
                          <b>Description:</b> {Description}
                        </Typography>
                        {ImpactID ? (
                          <Typography variant="subtitle2">
                            <b>Impact Classification: </b>{' '}
                            <span style={{ color: ImpactColor }}>
                              {ImpactLevel}
                            </span>
                            {` (${ImpactDescription})`} <br />
                          </Typography>
                        ) : null}
                        {ComplexityID ? (
                          <Typography variant="subtitle2">
                            <b>Complexity: </b>{' '}
                            <span style={{ color: complexityColor }}>
                              {ComplexityLevel}
                            </span>
                            {` (${ComplexityDescription})`} <br />
                          </Typography>
                        ) : null}
                      </CardContent>
                    </Card>
                    {digitalheathcheckid
                      ? orderBy(selectedArr, 'CreatedDate', 'desc').map((e) => {
                          const selectedRAMData = reviewRAMDetails.find(
                            (r) => r.DHCID === e.DigitalHeathCheckID
                          );
                          return (
                            <>
                              <br />
                              <Card>
                                <CardContent>
                                  <Typography variant="subtitle2">
                                    <div className={classes.export}>
                                      {new Date(e.CreatedDate).toDateString()}
                                      <Export
                                        dhcID={e.DigitalHeathCheckID}
                                        date={new Date(
                                          e.CreatedDate
                                        ).toDateString()}
                                        projectName={ProjectName}
                                        ramDetails={reviewRAMDetails}
                                        reviewInDetails={reviewInDetails}
                                      />
                                    </div>
                                  </Typography>
                                  {digitalheathcheckid && (
                                    <SummaryView
                                      DisplayFlag={false}
                                      ProjectName={ProjectName}
                                      Description={Description}
                                      EmailID={EmailID}
                                      overallSurveyResult={
                                        (e.contentSurveyResult +
                                          e.softwareSurveyResult) /
                                        2
                                      }
                                      contentSurveyResult={
                                        e.contentSurveyResult
                                      }
                                      softwareSurveyResult={
                                        e.softwareSurveyResult
                                      }
                                      complexity={{
                                        ComplexityID,
                                        ComplexityLevel,
                                        ComplexityDescription,
                                      }}
                                      impact={{
                                        ImpactID,
                                        ImpactLevel,
                                        ImpactDescription,
                                      }}
                                    />
                                  )}
                                  <div className="subContent">
                                    {(selectedRAMData
                                      ? surveyAllTypes
                                      : surveyDHCTypes
                                    ).map((details, index) =>
                                      getReviewInDetail(
                                        details,
                                        e.DigitalHeathCheckID,
                                        projectid,
                                        get(selectedRAMData, 'RAMID')
                                      )
                                    )}
                                  </div>
                                </CardContent>
                              </Card>
                            </>
                          );
                        })
                      : orderBy(selectedRAMArr, 'CreatedDate', 'desc').map(
                          (e) => {
                            return (
                              <>
                                <Card>
                                  <CardContent>
                                    {/* <Typography
                                      style={{
                                        color: `${
                                          e.TARequiredOrNot
                                            ? '#DD1D21'
                                            : '#008443'
                                        }`,
                                      }}
                                      variant="subtitle2"
                                    >
                                      {e.TARequiredOrNot
                                        ? 'Technical Assurance is required'
                                        : 'Technical Assurance is not required'}
                                    </Typography>
                                    <br /> */}
                                    <Typography variant="subtitle2">
                                      <div className={classes.export}>
                                        {new Date(e.CreatedDate).toDateString()}
                                        <Export
                                          dhcID={e.DigitalHeathCheckID}
                                          date={new Date(
                                            e.CreatedDate
                                          ).toDateString()}
                                          projectName={ProjectName}
                                          ramDetails={reviewRAMDetails}
                                          reviewInDetails={reviewInDetails}
                                          ramId={e.RAMID}
                                        />
                                      </div>
                                    </Typography>

                                    {getReviewInDetail(
                                      ['RAM', 'Risk Assessment Matrix'],
                                      '',
                                      projectid,
                                      e.RAMID
                                    )}
                                    <br />
                                    <br />
                                  </CardContent>{' '}
                                </Card>
                              </>
                            );
                          }
                        )}
                    <Comments
                      projectid={projectid}
                      expandedFeedback={expandedFeedback}
                    />
                    <IconButton
                      aria-label="Feed back"
                      onClick={() => {
                        setExpandedFeedback(!expandedFeedback);
                      }}
                    >
                      <Tooltip
                        title="Add Comment"
                        arrow
                        className="cursorPointer"
                      >
                        <Comment color="primary" />
                      </Tooltip>
                    </IconButton>
                  </AccordionDetails>
                  <Divider />
                </Accordion>
              </div>
            );
          })}
        </>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  // root: {
  //   height: 'auto',
  //   width: 'auto',
  //   textAlign: 'left',
  // },
  root: {
    width: '100%',
    marginTop: '5px',
  },
  title: {
    textTransform: 'capitalize',
  },
  header: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    marginBottom: '35px',
  },
  search: {
    // width: '30%',
  },
  filters: {
    display: 'flex',
    flexBasis: '60%',
    justifyContent: 'space-evenly',
  },
  reviews: { margin: '10px' },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  icon: {
    verticalAlign: 'bottom',
    height: 20,
    width: 20,
  },
  export: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  details: {
    display: 'initial',
  },
  column: {
    flexBasis: '33.33%',
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  main: {
    marginBottom: '4px',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: amber[500],
  },
  table: {
    minWidth: 650,
  },
  cellPadding: {
    padding: '5px',
    borderBottom: '0.2px solid grey',
  },
}));

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
      backgroundColor: '#FBCE07',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);
