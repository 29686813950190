import React, { useState, memo, useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  Button,
  InputLabel,
  TextField,
} from '@material-ui/core';
import { Context, actions } from '../../context';
import { Link } from 'react-router-dom';
import { api, details } from '../../api';
import { useHistory } from 'react-router-dom';
import SnackBar from '../SnackBars';
import Autocomplete from '@material-ui/lab/Autocomplete';

function Details({ onProjectChange }) {
  const [state, dispatch] = useContext(Context);
  const history = useHistory();
  const [redirectFlag, setRedirectFlag] = useState(false);
  const { BASE_URL, GET_RAM_DETAILS_BY_PROJECT_ID_URL } = details;
  const {
    selectedProject,
    banner,
    selectedProject: {
      Description,
      PointOfContactEmailID,
      Areas,
      ComplexityLevel,
      ImpactLevel,
      ImpactDescription,
      ComplexityDescription,
      ProjectName,
      FunnelProjectID,
      ProjectID,
    },
    project = {},
  } = state;
  const classes = useStyles();

  useEffect(() => {
    const params = { ProjectID };
    let url = `${BASE_URL}${GET_RAM_DETAILS_BY_PROJECT_ID_URL}`;
    if (ProjectID && Areas === 'Financial Operations') {
      api.getCalls('getDetails', url, params).then((response) => {
        let { data: { recordset = [] } = {}, error } = response;
        if (recordset.length >= 1) {
          setRedirectFlag(true);
        } else {
          setRedirectFlag(false);
        }
      });
    }
    return () => {
      setBanner(false, '', '');
    };
  }, [selectedProject]);

  const onTakeSurvey = () => {
    dispatch({
      type: actions.SET_TakingSurvey,
      payload: !state.takingSurvey,
    });
    if (Areas === 'Financial Operations') {
      if (!redirectFlag) {
        console.log('redirecting');
        history.push('/riskAssessment');
        dispatch({
          type: actions.SET_selectedMenuIndex,
          payload: {
            index: 2,
          },
        });
      } else {
        console.log('not redirecting');
        setBanner(
          true,
          'warning',
          'Risk Assessment Matrix(RAM) is already evaluated for this project'
        );
      }
    } else {
      history.push('/survey');
      dispatch({
        type: actions.SET_selectedMenuIndex,
        payload: {
          index: 1,
        },
      });
    }
  };

  const setBanner = (show, type = 'error', error) => {
    dispatch({
      type: actions.SET_Banner,
      payload: {
        show,
        type,
        message: error,
      },
    });
  };

  return (
    <>
      {banner.show ? (
        <SnackBar
          banner={banner}
          onClose={() => setBanner(false, '', '')}
        />
      ) : null}
      <form
        noValidate
        autoComplete="off"
        style={{ width: '100%' }}
      >
        <Grid
          container
          spacing={2}
          style={{ padding: '20px' }}
        >
          <Grid
            item
            xs={12}
            className={classes.fields}
          >
            <InputLabel
              id="lblprojectName"
              className={classes.label}
            >
              Project Name
            </InputLabel>
            <Autocomplete
              id="projectName"
              style={{ width: '55%', textAlign: 'left' }}
              debug
              options={project.listOfProjects}
              name="projectName"
              value={selectedProject}
              disableClearable
              onChange={onProjectChange}
              getOptionLabel={(option) => option.ProjectName || ''}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                />
              )}
            />
          </Grid>
          {selectedProject.ProjectID !== undefined && (
            <>
              <Grid
                item
                xs={12}
                className={classes.fields}
              >
                <InputLabel
                  id="FunnelProjectID"
                  className={classes.label}
                >
                  Salesforce ID
                </InputLabel>
                <Typography>{FunnelProjectID}</Typography>
              </Grid>
              <Grid
                item
                xs={12}
                className={classes.fields}
              >
                <InputLabel
                  id="area"
                  className={classes.label}
                >
                  Domain
                </InputLabel>
                <Typography>{Areas}</Typography>
              </Grid>
              <Grid
                item
                xs={12}
                className={classes.fields}
              >
                <InputLabel
                  id="email"
                  className={classes.label}
                >
                  Email
                </InputLabel>
                <Typography>{PointOfContactEmailID}</Typography>
              </Grid>
              <Grid
                item
                xs={12}
                className={classes.fields}
              >
                <InputLabel
                  id="desc"
                  className={classes.label}
                >
                  Project Description
                </InputLabel>
                <Typography className={classes.fieldsAlign}>
                  {Description}
                </Typography>
              </Grid>
              {Areas !== 'Financial Operations' && (
                <>
                  {' '}
                  <Grid
                    item
                    xs={12}
                    className={classes.fields}
                  >
                    <InputLabel
                      id="complexityID"
                      className={classes.label}
                    >
                      Project Complexity
                    </InputLabel>
                    <Typography className={classes.fieldsAlign}>
                      <b>{ComplexityLevel}</b> {'\u00A0'}
                      {`(${ComplexityDescription})`}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    className={classes.fields}
                  >
                    <InputLabel
                      id="impactID"
                      className={classes.label}
                    >
                      Project Impact
                    </InputLabel>
                    <Typography className={classes.fieldsAlign}>
                      {' '}
                      <b>{ImpactLevel}</b> {'\u00A0'}
                      {`(${ImpactDescription})`}
                    </Typography>
                  </Grid>
                </>
              )}
              <Grid
                item
                xs={12}
                className={classes.textButtonAlign}
              >
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={() => onTakeSurvey()}
                  component={Link}
                >
                  Take a New Survey
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </form>
    </>
  );
}

export default memo(Details);

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  fields: {
    display: 'flex',
  },
  label: {
    width: '25%',
    textAlign: 'left',
  },
  fieldsAlign: { width: '75%', textAlign: 'left' },
  textButtonAlign: {
    textAlign: 'end',
  },
}));
