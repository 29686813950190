import { Tabs } from "@sede-x/shell-ds-react-framework";
import React, { useEffect, useContext } from "react";
import { actions, Context } from "../../context";
import { details, api } from "../../api";
import NewProject from "./NewProject";
import ExistingProject from "./ExistingProject";

const Home = () => {
  const [state, dispatch] = useContext(Context);

  const listOfAreas = [
    {
      label: "Data Science, Statistics & A.I",
      value: "Data Science, Statistics & A.I",
    },
    {
      label: "Computational Science",
      value: "Computational Science",
    },
    {
      label: "Emerging Digital Technologies",
      value: "Emerging Digital Technologies",
    },
  ];

  const listOfStages = [
    {
      label: "Ideation (Understand, Frame & Define)",
      value: "Ideation (Understand, Frame & Define)",
    },
    {
      label:
        "Discover (Prepare, Research & Validation - PoC/PoT/Prototype or Tech Design)",
      value:
        "Discover (Prepare, Research & Validation - PoC/PoT/Prototype or Tech Design)",
    },
    {
      label: "Develop & Demonstrate (Pilot, Model, Evaluate, MVP iterations)",
      value: "Develop & Demonstrate (Pilot, Model, Evaluate, MVP iterations)",
    },
    {
      label: "Production Deployment & Replication (MDP)",
      value: "Production Deployment & Replication (MDP)",
    },
  ];

  const edtStages = [
    {
      label: "PoC/PoT",
      value: "PoC/PoT",
    },
    {
      label: "MVP",
      value: "MVP",
    },
    {
      label: "Production",
      value: "Production",
    },
  ];

  const listOfTeamSizes = [
    {
      label: "1-3 members",
      value: "1-3 members",
    },
    {
      label: ">3 members but co-located",
      value: ">3 members but co-located",
    },
    {
      label: ">3 members but not co-located",
      value: ">3 members but not co-located",
    },
  ];

  //Getting state
  const {
    project = {},
    selectedProject,
    banner,
    takingSurvey,
    selectedFlag,
  } = state;

  //Get Project Details request URL
  const { BASE_URL, GET_PROJECTS_URL } = details;

  //Fetches existing projects
  const loadProjects = async (flag) => {
    let url = `${BASE_URL}${GET_PROJECTS_URL}`;
    let response = await api.getCalls("loadProjects", url);
    let { data: { recordset: recordset = [] } = {}, error } = response;
    // console.log("Record Set Load- ", recordset);
    if (!error) {
      dispatch({
        type: actions.SET_Projects,
        payload: {
          ...project,
          listOfProjects: recordset,
        },
      });
      console.log(`LoadProjects()`, state);
      //newly created project
      if (recordset.length > 0) {
        flag &&
          dispatch({
            type: actions.SET_SelectedProject,
            payload: recordset[recordset.length - 1],
          });
      }
    }
  };
  // c-onsole.log(`Re-render`, state);

  //load existing projects on app load
  useEffect(() => {
    loadProjects();
    if (project.listOfProjects.length === 0) {
      loadProjects();
      setValidProject();
    }
  }, [project.validProject]);

  const onProjectChange = (event, value) => {
    let currentProject;
    // console.log("Value-", value);
    if (value) {
      currentProject = project.listOfProjects.find(
        (project) => project.ProjectID === value.value
      );
      // console.log(`IFFFFF()`, currentProject);
    }
    // console.log(value, currentProject);
    currentProject !== null &&
      dispatch({
        type: actions.SET_SelectedProject,
        payload: currentProject,
      });

    //  console.log(`onProjectChange()`, currentProject);
    setValidProject();
  };

  // console.log(`OnProject Change Re-render`, state);

  const setValidProject = () => {
    let { ProjectID = undefined } = selectedProject;
    dispatch({
      type: actions.SET_Projects,
      payload: {
        ...project,
        validProject:
          ProjectID !== undefined && ProjectID !== null ? true : false,
      },
    });
  };

  return (
    <>
      <Tabs
        style={{ height: "auto" }}
        size="large"
        emphasis
        tabPosition="top"
        defaultActiveKey="1"
        tabBarStyle={{
          background: "rgb(245,245,245)",
          justifyContent: "space-around",
        }}
        items={[
          {
            label: "New Project",
            key: "1",
            children: (
              <NewProject
                {...{
                  loadProjects,
                  listOfAreas,
                  listOfStages,
                  listOfTeamSizes,
                  selectedProject,
                  edtStages,
                }}
              />
            ),
          },
          {
            label: "Existing Project",
            key: "2",
            children: (
              <ExistingProject {...{ onProjectChange, loadProjects }} />
            ),
          },
        ]}
      />
    </>
  );
};

export default Home;
