import React, { useState } from "react";
import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
} from "react-router-dom";
import {
  Login,
  Project,
  LinearStepper,
  RiskAssessment,
  Authenticator,
  FAQ,
  ContactForm,
} from "./components";
import { Store } from "./context";

// import './styles/styles.scss';

import FAQPage from "./components/FAQ/FAQPage";
import { ShellThemeProvider } from "@sede-x/shell-ds-react-framework";
import Home from "./components/project/Home";
import SideNavBar from "./components/layout/SideNavBar";
import "./index.css";

import styled, { css } from "styled-components";
import TopNavBar from "./components/layout/TopNavBar";
import EdtSurvey from "./components/survey/EdtSurvey";
import EdtSurveyResult from "./components/survey/EdtSurveyResult";
import DashBoard from "./dasboard/DashBoard";
import DhcSurvey from "./components/survey/DhcSurvey";
import RAMSurveyByProject from "./components/RAM/RAMSurveyByProject";
import ErrorPageNotFound from "./components/ErrorPages/PageNotFound";
import SignOutPage from "./components/SignOutPage/SignOutPage";

const Wrapper = styled.div`
  ${({ theme, narrow }) => css`
    background-color: ${theme.background.base};
    height: 100%;
    width: ${narrow ? "500px" : "100%"};
  `}
`;

const AppContainer = () => {
  return (
    <>
      {/* <SideBar /> */}
      <Wrapper>
        <SideNavBar />
        <TopNavBar />
        <main>
          <Switch>
            <Route path="/home">
              <Home />
            </Route>
            <Route path="/review">
              <DashBoard />
            </Route>
            <Route path="/survey">
              <DhcSurvey />
            </Route>

            <Route path="/edt">
              <EdtSurvey />
            </Route>
            <Route path="/edtResult">
              <EdtSurveyResult />
            </Route>

            <Route path="/riskAssessment">
              <RiskAssessment />
            </Route>
            <Route path="/ramResult">
              <RAMSurveyByProject />
            </Route>
            <Route path="/FAQ">
              <FAQPage />
            </Route>
            <Route path="/contact">
              <ContactForm />
            </Route>
            <Route path="/errorPage">
              <ErrorPageNotFound />
            </Route>
            {/* <Route path="/test">
              <DashBoard />
            </Route> */}
          </Switch>
        </main>
      </Wrapper>
    </>
  );
};

export default function App() {
  return (
    <ShellThemeProvider theme="light">
      <Router>
        <Store>
          <Switch>
            <Route path="/signedOut">
              <SignOutPage />
            </Route>
            <Authenticator>
              <AppContainer />
            </Authenticator>
          </Switch>
        </Store>
      </Router>
    </ShellThemeProvider>
  );
}
