import axios from "axios";
import get from "lodash.get";

axios.interceptors.request.use(function (config) {
  config.headers.Authorization = `Bearer ${
    JSON.parse(localStorage.getItem("tokenDetails")).access_token
  }`;
  return config;
});

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    return Promise.reject(error);
  }
);

export default {
  getCalls: async (methodName, url, params = {}, config = {}) =>
    await axios
      .get(url, { ...config, params })
      .then((response) => {
        if (
          (response.status >= 400 && response.status < 600) ||
          (response.data.status >= 400 && response.data.status < 600)
        ) {
          console.log(response.error);
          return {
            data: {},
            error: `${methodName} - ${response.error || response.data.error}`,
          };
        } else {
          return {
            data: response.data,
            error: null,
          };
        }
      })
      .catch((err) => {
        const { response } = err;
        return {
          data: {},
          error: get(response, "data.error"),
        };
      }),
  postCalls: async (_methodName, url, params, config = {}) =>
    await axios
      .post(url, params, config)
      .then((response) => {
        return {
          data: response.data,
          error: null,
        };
      })
      .catch((err) => {
        const { message } = err;
        return {
          data: {},
          error: message,
        };
      }),
};
