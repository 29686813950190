import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Button } from "@sede-x/shell-ds-react-framework";
import "./SignOutPage.css"; // import the CSS file

const SignedOut = () => {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (!location.state || !location.state.fromTokenRefresher) {
      history.replace("/home"); // or wherever you want to redirect
    }
  }, [history, location]);

  const handleSignIn = () => {
    history.push("/home"); // Redirect to homepage
  };

  return (
    <div className="signed-out-container">
      <div className="image-container">
        <img src="MCDSLogo.jpg" alt="MCDSLogo" className="logo-image" />
        <img
          src="SignOutPageCheese.jpg"
          alt="Signed out"
          className="signed-out-image"
        />
      </div>
      {/* <h1 className="signed-out-text">Signed Out</h1> */}
      <h1 className="signed-out-text">
        You've been signed out due to inactivity.
      </h1>
      <Button
        size="large"
        variant="filled"
        sentiment="default"
        onClick={handleSignIn}
        className="continue-button"
      >
        Sign In
      </Button>
    </div>
  );
};

export default SignedOut;
