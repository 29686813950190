import config from '../../../../config';

const getToken = async (clientId, code) => {
  const details = {
    client_id: clientId, //"wfhc dev",//"",
    grant_type: 'authorization_code',
    redirect_uri: config.REDIRECT_URL,
    code_verifier: window.localStorage.getItem('verifier'),
    code,
  };

  const formBody = new Array();
  // tslint:disable-next-line:forin
  for (const property in details) {
    if (details.hasOwnProperty(property)) {
      const encodedKey = property;
      const encodedValue = details[property];
      formBody.push(`${encodedKey}=${encodedValue}`);
    }
  }
  const formBodyObj = formBody.join('&');
  const url = new URL(config.PINGID_TOKEN_URL);

  const searchParams = new URLSearchParams(Object.entries(details));
  searchParams.forEach((value, key) => {
    url.searchParams.append(key, value);
  });

  const response = await fetch(config.PINGID_TOKEN_URL, {
    method: 'POST',
    // dataType: 'json',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Accept: 'application/json',
    },
    body: formBodyObj,
  });

  const result = await response.json();

  if (result.error_description) {
    throw new Error('You are not authorized to access application');
  }
  return result;
};

export default getToken;
