import React from 'react';
import ScoreIndicator from './ScoreIndicator';
import { Divider } from '@sede-x/shell-ds-react-framework';

const ScoreSummary = ({
  overallSurveyResult,
  contentSurveyResult,
  softwareSurveyResult,
}) => {
  return (
    <div className="summary-container">
      <div className="overall-score">
        <ScoreIndicator
          Score={overallSurveyResult}
          text="Overall Score"
          size="large"
        />
      </div>
      <Divider
        size="medium"
        colour="medium"
        direction="vertical"
      />

      <div className="ca-score">
        <div className="ca-score-header">
          <h4>Content Assurance Survey</h4>
        </div>
        <ScoreIndicator
          Score={contentSurveyResult}
          text="Score"
        />
      </div>
      <div className="se-score">
        <div className="se-score-header">
          <h4>Software Engineering Survey</h4>
        </div>
        <ScoreIndicator
          Score={softwareSurveyResult}
          text="Score"
        />
      </div>
    </div>
  );
};

export default ScoreSummary;
