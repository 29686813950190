import { api, details } from '../../../api';

const validateUser = async (_user) => {
  const { BASE_URL, GET_USERS_URL } = details;
  const url = `${BASE_URL}${GET_USERS_URL}`;

  const response = await api.getCalls('userLogin', url);
  const { data: { recordset: recordset = [] } = {} } = response;
  return {
    ...recordset[0],
  };
};

export default validateUser;
