import React from "react";
import ScoreSummary from "../../dasboard/ScoreSummary";
import { Card, Grid, Text } from "@sede-x/shell-ds-react-framework";
import get from "lodash.get";

const DhcScoreSummary = ({
  ProjectName,
  Description,
  EmailID,
  overallSurveyResult,
  contentSurveyResult,
  softwareSurveyResult,
  complexity,
  complexity: { ComplexityLevel, ComplexityDescription },
  impact,
  impact: { ImpactLevel, ImpactDescription },
  selectedFlag,
  formData,
}) => {
  if (selectedFlag === "newProject") {
    if (formData.ComplexityID === 1) {
      ComplexityLevel = "Low";
      ComplexityDescription = "no code or >100 lines of code";
    } else if (formData.ComplexityID === 2) {
      ComplexityLevel = "Medium";
      ComplexityDescription = "100-10,000 lines of code";
    } else if (formData.ComplexityID === 3) {
      ComplexityLevel = "High";
      ComplexityDescription = "more than 10,000 lines of code";
    }
    if (formData.ImpactID === 4) {
      ImpactLevel = "Low";
      ImpactDescription =
        "Feasibility study/PoC/support with theoretical methods and modelling";
    } else if (formData.ImpactID === 5) {
      ImpactLevel = "Medium";
      ImpactDescription =
        "Pilot Mid size project (within portfolio) which uses simulation modelling";
    } else if (formData.ImpactID === 6) {
      ImpactLevel = "High";
      ImpactDescription =
        "Flagship projects(Business) critical process HSSE relevant";
    } else if (formData.ImpactID === 10) {
      ImpactLevel = "Low";
      ImpactDescription =
        "Feasibility Study/PoC with theoretical methods & modelling, ML or statistical content";
    } else if (formData.ImpactID === 11) {
      ImpactLevel = "Medium";
      ImpactDescription =
        "Pilot mid-size project (within a portfolio) using simulation modelling, ML or statistical methodology";
    } else if (formData.ImpactID === 12) {
      ImpactLevel = "High";
      ImpactDescription = "Flagship project with business-critical risks";
    }
  }

  const ImpactColor =
    ImpactLevel === "Low"
      ? "#008443"
      : ImpactLevel === "Medium"
      ? "#EB8705"
      : "#DD1D21";

  const complexityColor =
    ComplexityLevel === "Low"
      ? "#008443"
      : ComplexityLevel === "Medium"
      ? "#EB8705"
      : "#DD1D21";
  return (
    <>
      <div className="summary-project-details">
        <Card className="summary-card">
          <div className="project-summary">
            <Grid container columns="20% auto">
              <Grid children>
                <Text bold>Project Name: </Text>
              </Grid>
              <Grid children>
                <Text bold>{ProjectName}</Text>
              </Grid>
              {Description && (
                <>
                  <Grid children>
                    <Text bold>Description: </Text>
                  </Grid>
                  <Grid children>
                    <Text>{Description}</Text>
                  </Grid>
                </>
              )}

              {get(impact, "ImpactID") && (
                <>
                  <Grid children>
                    <Text bold>Impact Classification: </Text>
                  </Grid>
                  <Grid children>
                    <Text>
                      <span style={{ color: ImpactColor }}>
                        {ImpactLevel} -
                      </span>
                      {ImpactDescription}
                    </Text>
                  </Grid>
                </>
              )}

              {get(complexity, "ComplexityID") && (
                <>
                  <Grid children>
                    <Text bold>Complexity: </Text>
                  </Grid>
                  <Grid children>
                    <Text>
                      <span style={{ color: complexityColor }}>
                        {ComplexityLevel} -
                      </span>
                      {ComplexityDescription}
                    </Text>
                  </Grid>
                </>
              )}
              <Grid children>
                <Text bold>Point of Contact: </Text>
              </Grid>
              <Grid children>
                <Text>{EmailID}</Text>
              </Grid>
            </Grid>
          </div>
        </Card>
        <br />
        <br />
        <Card className="summary-card">
          <ScoreSummary
            overallSurveyResult={overallSurveyResult}
            contentSurveyResult={contentSurveyResult}
            softwareSurveyResult={softwareSurveyResult}
          />
        </Card>
        <br />
        <br />
      </div>
    </>
  );
};

export default DhcScoreSummary;
