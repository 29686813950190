export default {
  SET_Users: "setUsers",
  SET_LoginUser: "setLoginUser",
  SET_Projects: "setProject",
  SET_Banner: "setBanner",
  SET_SelectedProject: "setSelectedProject",
  SET_TakingSurvey: "setTakingSurvey",
  SET_ProjectDetails: "setProjectDetails",
  SET_SurveyQuestions: "setSurveyQuestions",
  SET_AgreementLevels: "setAgreementLevels",
  SET_SelectedFlag: "setSelectedFlag",
  SET_RAMQuestions: "setRAMQuestions",
  SET_selectedMenuIndex: "setSelectedMenuIndex",
  SET_DHCID: "SET_DHCID",
  SET_EDT: "SET_EDT",
  SET_FAQDetails: "setFAQDetails",
  SET_InfoModalFlag: "setInfoModalFlag",
  SET_EDTAnswers: "setEDTAnswers",
  SET_FORM_DATA: "setFormData",
  SET_CONTENT_SURVEY_RESULT: "setContentFinalSurveyResult",
  SET_SOFTWARE_SURVEY_RESULT: "setSoftwareFinalSurveyResult",
  SET_SURVEY_ALL_DATA: "setSureyAllData",
  SET_RAM_FINAL_DATA: "setRAMFinalData",
  SET_PROJECT_ID: "setNewProjectID",
  SET_EDT_Survey: "setEDTSurvey",
  SET_EDT_Score: "setEDTScore",
  SET_RAM_NEEDED: "setRAMNeeded",
};
