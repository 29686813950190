import React, { memo } from 'react';
import clsx from 'clsx';
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Create from './Create';
import Details from './Details';

function StyledRadio(props) {
  const classes = useStyles();

  return (
    <Radio
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}

const ProjectView = memo(
  ({
    banner,
    project,
    listOfAreas,
    listOfStages,
    listOfTeamSizes,
    onProjectChange,
    loadProjects,
    handleChange,
    selectedProject,
    selectedFlag,
  }) => {
    const classes = useStyles();
    return (
      <>
        <Grid
          container
          spacing={2}
          className={classes.root}
        >
          <Grid
            item
            xs={12}
          >
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="Project"
                name="project"
                value={selectedFlag}
                onChange={handleChange}
                row
              >
                <FormControlLabel
                  value="newProject"
                  control={<StyledRadio />}
                  label="New Project"
                />
                <FormControlLabel
                  value="searchProject"
                  control={<StyledRadio />}
                  label="Existing Project"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          {selectedFlag === 'newProject' ? (
            <>
              <Create
                {...{
                  loadProjects,
                  listOfAreas,
                  listOfStages,
                  listOfTeamSizes,
                  selectedProject,
                }}
              />
              <div className={classes.footer}>
                <i>
                  The DHC should be used as a 'Self-help' tool to allow you to
                  reflect on how the project is progressing. Hence, it may be
                  worth completing with the project team. There are 2 DHC
                  surveys: Content Assurance and Software Engineering, as well
                  as a Risk Assessment Matrix which will determine whether a
                  formal TA review is required.
                </i>
              </div>
            </>
          ) : project.listOfProjects.length !== 0 ? (
            <Details {...{ onProjectChange }} />
          ) : (
            <></>
          )}
        </Grid>
      </>
    );
  }
);

export default ProjectView;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: 'auto',
    width: '80%',
  },
  footer: {
    marginTop: '50px',
    marginRight: '10px',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  inputField: { width: 500 },
  margin: {
    height: theme.spacing(2),
  },
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow:
      'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#ffc107',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#ffc107',
    },
  },
}));
