import { Avatar, NavBar, Text } from "@sede-x/shell-ds-react-framework";
import React from "react";
import "./NavBars.css";

const TopNavBar = () => {
  const loginUser = localStorage.getItem("loginUser")
    ? JSON.parse(localStorage.getItem("loginUser"))
    : {};
  return (
    <NavBar
      float={true}
      pectenArea="default"
      avatarArea={
        <Text
          className="user-info"
          color="light"
        >{`${loginUser.FirstName} ${loginUser.LastName}`}</Text>
      }
    >
      <div className="page-title">
        <img src="MCDSLogo.jpg" alt="MCDSLogo" className="logo-image-1" />
        <h3>Digital Health Check Portal</h3>
      </div>
    </NavBar>
  );
};

export default TopNavBar;
