import {
  Alert,
  Button,
  Card,
  FormField,
  Grid,
  Heading,
  Loading,
  Option,
  Select,
  Text,
} from "@sede-x/shell-ds-react-framework";
import React, { Fragment } from "react";
import "./EdtSurvey.css";
import { useEffect, useState, useContext } from "react";
import _, { set } from "lodash";
import EdtQuestion from "./EdtQuestion";
import { Context, actions } from "../../context";
import { Snackbar } from "@material-ui/core";
import {
  BASE_URL,
  GET_EDT_QUESTIONS,
  SAVE_EDT_SURVEY,
} from "../../api/APIdetails";
import { api } from "../../api";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const answerOptions = [
  {
    label: "Strongly Agree",
    value: 4,
  },
  {
    label: "Agree",
    value: 3,
  },
  {
    label: "Neutral",
    value: 2,
  },
  {
    label: "Disagree",
    value: 1,
  },
  {
    label: "Strongly Disagree",
    value: 0,
  },
  {
    label: "Too Early/ NA",
    value: -1,
  },
];

const questionTypes = {
  general: "general",
  blockchain: "blockchain",
  IOT: "IOT",
  HPC: "HPC",
};

const EdtSurvey = () => {
  const [fetchedQuestions, setFetchedQuestions] = useState({});
  const [domain, setDomain] = useState();
  const [answers, setAnswers] = useState({});
  const [state, dispatch] = useContext(Context);
  const { selectedProject, formData, selectedFlag, EDTSurvey, EDTScore } =
    state;
  const [error, setError] = useState();
  const [formSubmit, setFormSubmit] = useState(false);
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  useEffect(() => {
    if (!formSubmit) {
      window.addEventListener("beforeunload", alertUser);
      return () => {
        window.removeEventListener("beforeunload", alertUser);
      };
    }
  }, [formSubmit]);

  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "You have unsaved changes, are you sure you want to leave?";
  };

  useEffect(() => {
    console.log(formSubmit);

    if (!formSubmit) {
      const unblock = history.block((location, action) => {
        if (action === "PUSH") {
          return window.confirm(
            "You have unsaved changes, are you sure you want to leave this page?"
          );
        }
        return true;
      });
      return () => unblock();
    }
  }, [history, formSubmit]);

  const handleAnswerChange = (questionId, answer) => {
    console.log(answer);
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: answer,
    }));
  };

  const handleSubmit = async () => {
    const unansweredGeneralQuestions = fetchedQuestions.general.filter(
      (question) => !answers[question.id]
    );

    console.log(unansweredGeneralQuestions);
    if (unansweredGeneralQuestions.length > 0) {
      setError("Please answer all questions");
      setTimeout(() => {
        setError(undefined);
      }, 5000);
      console.log("Please answer all questions");
      return;
    }

    if (domain) {
      const unansweredDomainQuestions = fetchedQuestions[domain].filter(
        (question) => !answers[question.id]
      );
      if (unansweredDomainQuestions.length > 0) {
        setError("Please answer all questions");
        setTimeout(() => {
          setError(undefined);
        }, 5000);
        console.log("Please answer all questions");
        return;
      }
    }

    console.log("Submitting answers", answers);
    const score = calculateScore();
    dispatch({
      type: actions.SET_EDT_Score,
      payload: score,
    });
    await new Promise((resolve) => {
      setFormSubmit(true);
      resolve();
    });
    saveEdtSurvey(score);
    console.log(score);
  };

  const saveEdtSurvey = async (score) => {
    const url = `${BASE_URL}${SAVE_EDT_SURVEY}`;
    const params = {
      score,
      data: answers,
    };
    dispatch({
      type: actions.SET_EDT_Survey,
      payload: params,
    });
    // const response = await axios.post(url, params); //'saveEdt'
    // console.log(response);
    // dispatch({
    //   type: actions.SET_EDT,
    //   payload: response.data,
    // });
    dispatch({
      type: actions.SET_EDTAnswers,
      payload: answers,
    });
    console.log(answers);
    history.push("/edtResult");
  };

  const calculateScore = () => {
    const highestScore = 4;
    let sum = 0;
    let total = Object.keys(answers).length * highestScore;
    for (let key in answers) {
      if (answers.hasOwnProperty(key)) {
        sum = sum + answers[key].value;
      }
    }
    const finalScore = Math.round((sum / total) * 100);
    if (finalScore < 0) return 0;
    return finalScore;
  };
  useEffect(() => {
    const loadEdtQuestions = async () => {
      setLoading(true);
      let url = `${BASE_URL}${GET_EDT_QUESTIONS}`;
      let response = await api.getCalls("loadEdtQuestions", url);
      let { data: { recordsets = [] } = {}, error } = response;
      if (error) {
        setError("Error fetching data");
      }
      console.log(recordsets[0]);
      const questions = recordsets[0];
      console.log(questions);
      const getAllQuestions = (questions) =>
        _.groupBy(questions, ({ type }) => type);
      setFetchedQuestions(getAllQuestions(questions));
      setLoading(false);
    };
    loadEdtQuestions();
  }, []);

  // console.log(fetchedQuestions);
  // console.log(selectedProject);

  return (
    <div>
      <Card className="header-card">
        <Heading type="h3" bold={true}>
          EDT SURVEY
        </Heading>
      </Card>
      <br />
      <Card className="summary-card">
        <div className="project-summary">
          <Grid container columns="20% auto">
            <Grid children>
              <Text bold>Project Name:</Text>
            </Grid>
            <Grid children>
              {selectedFlag === "existingProject" ? (
                <Text bold>{selectedProject.ProjectName}</Text>
              ) : (
                <Text bold>{formData.Name}</Text>
              )}
            </Grid>
            {(selectedProject.FunnelProjectID || formData.FunnelProjectID) && (
              <>
                <Grid children>
                  <Text bold>Project Opportunity ID:</Text>
                </Grid>
                <Grid children>
                  {selectedFlag === "existingProject" ? (
                    <Text>{selectedProject.FunnelProjectID}</Text>
                  ) : (
                    <Text>{formData.FunnelProjectID}</Text>
                  )}
                </Grid>
              </>
            )}

            <Grid children>
              <Text bold> Domain:</Text>
            </Grid>
            <Grid children>
              {selectedFlag === "existingProject" ? (
                <Text>{selectedProject.Areas}</Text>
              ) : (
                <Text>{formData.Areas}</Text>
              )}
            </Grid>
          </Grid>
        </div>
      </Card>
      <div className="questions-container">
        {loading && <Loading size="large" type="default" />}
        {error && (
          <Alert
            size="small"
            className="slide-in alert"
            state="warning"
            iconVisibility
            float
            dismissible
            onDismissClick={() => setError(undefined)}
            title={error}
          />
        )}
        {fetchedQuestions.general &&
          fetchedQuestions.general.map((question, idx) => (
            <EdtQuestion
              key={question.id}
              index={idx}
              questionId={question.id}
              questionText={question.text}
              questionCategory={question.category}
              answerOptions={answerOptions}
              handleAnswerChange={handleAnswerChange}
            />
          ))}
        {!loading && !error && (
          <Grid container columns="70% auto" columnGap="10%">
            <Grid children>
              <Text bold>Does your project fall under any specialty?</Text>
            </Grid>
            <Grid children>
              <FormField size="large">
                <Select
                  placeholder="Choose answer"
                  size="large"
                  onChange={(value) => {
                    setDomain(value);
                  }}
                >
                  <Option value={questionTypes.blockchain}>Blockchain</Option>
                  <Option value={questionTypes.IOT}>IOT</Option>
                  <Option value={questionTypes.HPC}>HPC</Option>
                </Select>
              </FormField>
            </Grid>
          </Grid>
        )}

        {domain &&
          fetchedQuestions[domain].map((question, idx) => (
            <EdtQuestion
              key={question.id}
              index={idx}
              questionId={question.id}
              questionText={question.text}
              answerOptions={answerOptions}
              handleAnswerChange={handleAnswerChange}
              type="domain"
              generalLength={fetchedQuestions.general.length}
            />
          ))}
      </div>
      <br />
      {!loading && !error && (
        <Grid container columns={1} columnGap="20px">
          <Grid children>
            <div style={{ textAlign: "center" }}>
              <Button
                size="medium"
                variant="filled"
                sentiment="default"
                onClick={handleSubmit}
              >
                SUBMIT EDT SURVEY
              </Button>
            </div>
            <br />
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default EdtSurvey;
