import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  IconButton,
  TextField,
  Typography,
  Grid,
} from "@material-ui/core";
import { Send } from "@material-ui/icons";
import { api, details } from "../../api";
import { Context, actions } from "../../context";
import { formatDate } from "../../common/helper";

export default function QuestionComment({
  expandedQueFeedback,
  projectid,
  questionId,
  SurveyID,
}) {
  const classes = useStyles();
  const [state, dispatch] = useContext(Context);
  const loginUser = localStorage.getItem("loginUser")
    ? JSON.parse(localStorage.getItem("loginUser"))
    : {};
  const [reviewComments, setReviewComments] = useState([]);
  const [feedback, setFeedback] = useState("");
  const [disableButton, setDisableButton] = useState(true);
  const { BASE_URL, POST_QUESTION_COMMENT_URL, GET_QUESTION_COMMENT_URL } =
    details;

  useEffect(() => {
    getComments();
  }, []);

  const addComments = (event, projectid) => {
    event.preventDefault();
    let url = `${BASE_URL}${POST_QUESTION_COMMENT_URL}`;
    let { UserID, EmailID, FirstName, LastName } = loginUser;

    let _comments = {
      EmailID,
      FirstName,
      LastName,
      CommenterID: UserID,
      ProjectID: projectid,
      Comment: feedback,
      CommentDate: new Date().toISOString(),
      QuestionID: questionId,
      SurveyID,
    };
    api
      .postCalls("addQueComments", url, _comments)
      .then((res) => {
        let { error } = res;
        if (error) setBanner(true, "error", error);
        else {
          setFeedback("");
          setDisableButton(true);
          getComments();
        }
      })
      .catch((err) => {
        err && setBanner(true, "error", err);
      });
  };

  const getComments = () => {
    let url = `${BASE_URL}${GET_QUESTION_COMMENT_URL}`;
    api
      .getCalls("fetchSurveyQueComments", url)
      .then((response) => {
        let { data: { recordset = [] } = {}, error } = response;
        error ? setBanner(true, "error", error) : setReviewComments(recordset);
      })
      .catch((err) => {
        err && setBanner(true, "error", err);
      });
  };

  const setBanner = (show, type = "error", message) => {
    dispatch({
      type: actions.SET_Banner,
      payload: {
        show,
        type,
        message,
      },
    });
  };

  const feedbackContent = (projectid) => {
    return (
      <Paper
        component="form"
        className={classes.feedbackContent}
        style={{
          marginTop: 15,
          display: !expandedQueFeedback ? "none" : "",
          padding: "15px 5px",
        }}
      >
        <TextField
          id="index"
          label="Feedback"
          fullWidth
          placeholder="Please Enter Feedback"
          color="primary"
          multiline
          rows={2}
          rowsMax={6}
          value={feedback}
          onChange={(e) => {
            let {
              target: { value },
            } = e;
            setFeedback(value);
            value.trim() ? setDisableButton(false) : setDisableButton(true);
          }}
        />
        <IconButton
          id={projectid}
          color="primary"
          className={classes.iconButton}
          aria-label="directions"
          onClick={(e) => addComments(e, projectid)}
          disabled={disableButton}
        >
          <Send />
        </IconButton>
      </Paper>
    );
  };
  const found = reviewComments.findIndex(
    (d) =>
      d.ProjectID === projectid &&
      d.QuestionID === questionId &&
      d.SurveyID === SurveyID
  );
  return (
    <>
      {reviewComments.length > 0 && (
        <>
          {found !== -1 && (
            <Typography className={classes.title}>User Feedback:</Typography>
          )}
          {reviewComments
            .map((content, index) => {
              let {
                FirstName,
                LastName,
                Comment,
                CommentDate,
                ProjectID,
                QuestionID,
                SurveyID,
              } = content;
              return (
                ProjectID === projectid &&
                QuestionID === questionId &&
                SurveyID === SurveyID && (
                  <Grid
                    container
                    wrap="nowrap"
                    spacing={1}
                    style={{ borderTop: "1px solid #D9D9D9" }}
                  >
                    <Grid
                      justifyContent="left"
                      item
                      xs
                      zeroMinWidth
                      style={{ padding: "2px 5px" }}
                    >
                      <Typography className={classes.subContent}>
                        {`${FirstName} ${LastName}`} - {formatDate(CommentDate)}
                      </Typography>
                      <Typography className={classes.commentSection}>
                        {Comment}
                      </Typography>
                    </Grid>
                  </Grid>
                )
              );
            })
            .filter((comment) => comment)}
        </>
      )}
      {feedbackContent(projectid)}
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  feedbackContent: {
    padding: "2px 2px",
    display: "flex",
    alignItems: "center",
  },
  title: {
    marginTop: 8,
    fontSize: "14px",
    fontWeight: 600,
    color: "#404040",
  },
  subContent: {
    margin: 0,
    color: "#595959",
    fontSize: "14px",
    fontWeight: 400,
  },
  commentSection: {
    fontSize: "14px",
    fontWeight: 400,
    color: "#595959",
  },
}));
