import React, { useEffect, useState, useContext } from 'react';
import {
  getAccessToken,
  redirectToPingId,
  decodeAccessToken,
  validateUser,
} from './services';
import { actions, Context } from '../../context';
import TokenRefresher from './TokenRefresher';
import { useHistory } from 'react-router-dom';
import config from '../../config';
import InfoModal from '../../common/infoModal';
import { Loading } from '@sede-x/shell-ds-react-framework';

const Authenticator = ({ children }) => {
  const [state, dispatch] = useContext(Context);
  const { infoFlag } = state;
  const [userData, setUserData] = useState(null);
  const [_error, setError] = useState('');
  const [authTokens, setAuthTokens] = useState(null);
  const history = useHistory();

  useEffect(() => {
    const validateUserAndUpdate = async () => {
      const {
        access_token: accessToken,
        refresh_token: refreshToken,
        expires_in: expiresIn,
        clientId,
      } = authTokens;
      const basicUserDetails = decodeAccessToken(accessToken);
      const userDetails = await validateUser(basicUserDetails);
      localStorage.setItem(
        'loginUser',
        JSON.stringify({ isLogIn: true, ...userDetails, clientId })
      );
      dispatch({
        type: actions.SET_selectedMenuIndex,
        payload: {
          index: 0,
        },
      });
      setUserData({
        ...userDetails,
        accessToken,
        refreshToken,
        expiresIn,
        clientId,
      });
    };

    const authenticate = async () => {
      try {
        const clientId = config.CLIENT_ID;
        const accessTokens = await getAccessToken(clientId);
        localStorage.setItem('tokenDetails', JSON.stringify(accessTokens));
        if (!accessTokens) {
          return redirectToPingId(clientId);
        }
        setAuthTokens({ ...accessTokens, clientId });
        history.push('/home');
      } catch (err) {
        setError(err);
      }
      return undefined;
    };

    if (userData) {
      return;
    }

    if (authTokens) {
      validateUserAndUpdate();
    } else {
      authenticate();
    }
  }, [authTokens]);

  if (!userData) {
    return (
      <div className="auth-loader">
        <Loading />
      </div>
    );
  }

  return (
    <>
      <TokenRefresher
        onRefresh={(updatedAuthTokens) => {
          setUserData({ ...userData, ...updatedAuthTokens });
        }}
      />
      {/* {infoFlag && <InfoModal />} */}
      {children}
    </>
  );
};

export default Authenticator;
