import {
  Accordion,
  AccordionPanel,
  Table,
} from "@sede-x/shell-ds-react-framework";
import {
  TBody,
  TD,
  TH,
  THead,
  TR,
} from "@sede-x/shell-ds-react-framework/build/esm/components/Table/Table.styles";
import React from "react";
import orderBy from "lodash.orderby";

const levelColorCode = {
  Agree: "#008443",
  "Strongly Agree": "#008443",
  Neutral: "#EB8705",
  Disagree: "#DD1D21",
  "Strongly Disagree": "#DD1D21",
  "Too Early": "#808080",
};

const ReviewDetails = ({
  details,
  id,
  projectid,
  RAMId,
  reviewRAMDetails,
  reviewInDetails,
}) => {
  let [panel, title] = details;
  const tableRow = (
    id,
    question,
    agreement,
    SurveyID,
    Answer,
    ChoiceDesc,
    ColorCode,
    RequiredLevelofAgreementID,
    RecommendedLevelofAgreementID,
    OrderID
  ) => {
    const agreementColor = Object.keys(levelColorCode).filter(
      (key) => key === agreement
    );
    const requiredLevelColor = Object.keys(levelColorCode).filter(
      (key) => key === RequiredLevelofAgreementID
    );
    const recommendedLevelColor = Object.keys(levelColorCode).filter(
      (key) => key === RecommendedLevelofAgreementID
    );
    //Feedback part to be done later on
    return (
      <>
        <TR key={id}>
          {panel === "RAM" ? (
            <TD colSpan={2}>
              <b>{id - 2}.</b> {question}
            </TD>
          ) : (
            <TD colSpan={3}>
              <b>{OrderID}.</b> {question}
            </TD>
          )}

          {panel === "RAM" ? (
            <TD
              style={{
                borderRight: `10px solid ${ColorCode}`,
              }}
            >
              {[15, 16, 17].includes(id)
                ? Answer
                : id === 18
                ? Answer.split(";")
                    .slice(0, -1)
                    .map((d, index) => {
                      if (Answer.length - 1 === index) {
                        return <span>{d}</span>;
                      } else {
                        return (
                          <>
                            <span>{d}</span>
                            <br />
                          </>
                        );
                      }
                    })
                : ChoiceDesc}
            </TD>
          ) : (
            <>
              <TD
                style={{
                  color: levelColorCode[agreementColor],
                }}
              >
                {agreement}
              </TD>
              <TD
                style={{
                  color: levelColorCode[requiredLevelColor],
                }}
              >
                {RequiredLevelofAgreementID}
              </TD>
              <TD
                style={{
                  color: levelColorCode[recommendedLevelColor],
                }}
              >
                {RecommendedLevelofAgreementID}
              </TD>
            </>
          )}
        </TR>
      </>
    );
  };
  return (
    <Accordion iconSet="chevron" accordion={true}>
      <AccordionPanel className="details-panel" header={title}>
        <Table size="medium">
          <THead>
            {panel === "RAM" ? (
              <TR>
                <TH colSpan={2}>Question</TH>
                <TH>Answer</TH>
              </TR>
            ) : (
              <TR>
                <TH colSpan={3}>Question</TH>
                <TH>Agreement Level</TH>
                <TH>Required Level</TH>
                <TH>Recommended Level</TH>
              </TR>
            )}
          </THead>
          {panel === "RAM" ? (
            <TBody>
              {reviewRAMDetails.map((row) => {
                let {
                  Answer,
                  QuestionID,
                  Question,
                  ChoiceDesc,
                  SurveyID,
                  ProjectID,
                  ColorCode,
                  RAMID,
                } = row;

                if (RAMId === RAMID) {
                  return tableRow(
                    QuestionID,
                    Question,
                    "",
                    SurveyID,
                    Answer,
                    ChoiceDesc,
                    ColorCode
                  );
                }
              })}
            </TBody>
          ) : (
            <TBody>
              {orderBy(reviewInDetails, "QuestionID", "asc")
                .map((row) => {
                  let {
                    DigitalHeathCheckID,
                    QuestionID,
                    QuestionDisplay,
                    AgreementLevel,
                    SurveyID,
                    OrderID,
                    RequiredLevelofAgreementID,
                    RecommendedLevelofAgreementID,
                  } = row;
                  if (id === DigitalHeathCheckID) {
                    if (panel === "CA" && SurveyID === 2)
                      return tableRow(
                        QuestionID,
                        QuestionDisplay,
                        AgreementLevel,
                        SurveyID,
                        "",
                        "",
                        "",
                        RequiredLevelofAgreementID,
                        RecommendedLevelofAgreementID,
                        OrderID
                      );
                    else if (panel === "SE" && SurveyID === 1)
                      return tableRow(
                        QuestionID,
                        QuestionDisplay,
                        AgreementLevel,
                        SurveyID,
                        "",
                        "",
                        "",
                        RequiredLevelofAgreementID,
                        RecommendedLevelofAgreementID,
                        OrderID
                      );
                  }
                })
                .filter((data) => data)}
            </TBody>
          )}
        </Table>
      </AccordionPanel>
    </Accordion>
  );
};

export default ReviewDetails;
