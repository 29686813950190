import {
  FormField,
  Grid,
  Option,
  Select,
  Text,
  Tooltip,
} from "@sede-x/shell-ds-react-framework";
import React, { useCallback } from "react";
import orderBy from "lodash.orderby";
import { InfoCircle } from "@sede-x/shell-ds-react-framework/build/esm/components/Icon/components";

const DhcQuestionView = React.memo(
  ({
    data,
    marks,
    heading,
    onSliderChange,
    handleIconClicks,
    queId,
    viewDesc,
    selectedData,
    index,
  }) => {
    const handleSliderChange = useCallback(
      (value) => {
        onSliderChange(
          value,
          `${data.QuestionID}_${data.SurveyID}_${data.RecommendedLevelofAgreementID}`
        );
      },
      [onSliderChange, data]
    );
    const handleIconClick = useCallback(
      () => handleIconClicks(data.QuestionID, index),
      [handleIconClicks, data.QuestionID, index]
    );
    return (
      <Grid
        container
        columns="75% auto"
        //   columnGap="10%"
        columnGap="36px"
        style={{ paddingBottom: "36px", alignItems: "baseline" }}
      >
        <Grid children styles={{ display: "inline" }}>
          <div className="dhc-questions">
            <Text className="category-text">{data.CategoryName}</Text>
            <div className="question-text">
              <Text type="span">
                <b>{data.OrderID}. </b>
                {data.QuestionDisplay + " "}
              </Text>
              <Tooltip
                overlay="Click For More Information"
                trigger={
                  <InfoCircle
                    style={styles.infoCircle}
                    onClick={handleIconClick}
                  />
                }
                placement="bottom"
              />
            </div>
            {viewDesc[index] && (
              <Text className="category-text">{data.Description}</Text>
            )}
          </div>
        </Grid>
        <Grid children>
          <FormField size="large">
            <Select
              name="AgreementValueID"
              placeholder="Choose answer"
              allowClear={false}
              onChange={handleSliderChange}
              defaultValue={selectedData}
              // {...(selectedData && { defaultValue: selectedData })}
            >
              {orderBy(marks, "AgreementID", "desc").map((item, i) => {
                let { AgreementValueID, label } = item;
                return (
                  <Option key={i} value={AgreementValueID}>
                    {label}
                  </Option>
                );
              })}
            </Select>
          </FormField>
        </Grid>
      </Grid>
    );
  }
);

const styles = {
  infoCircle: {
    display: "inline",
    // justifyContent: "center",
    // alignItems: "center",
    // verticalalign: "middle",
    // iconPosition: "stat",
  },
};

export default DhcQuestionView;
