import React from "react";
import * as XLSX from "xlsx";
import orderBy from "lodash.orderby";
import get from "lodash.get";
import { Button, Text, Tooltip } from "@sede-x/shell-ds-react-framework";
import { Download } from "@sede-x/shell-ds-react-framework/build/esm/components/Icon/components";

//Transforming data
const handleExport = (
  DigitalHeathCheckID,
  createdDate,
  projectName,
  RAMId,
  reviewRAMDetails,
  reviewInDetails,
  shouldExport
) => {
  // console.log("handleExport called", shouldExport); // New log statement
  // console.log("reviewInDetails:", reviewInDetails); // New log statement
  // console.log("reviewRAMDetails:", reviewRAMDetails); // New log statement
  // console.log("DigitalHeathCheckID:", DigitalHeathCheckID); // New log statement
  // console.log("RAMId:", RAMId);
  let toExportCA;
  let toExportSE;
  if (DigitalHeathCheckID) {
    const filteredCaDetails = reviewInDetails.filter(
      (detail) =>
        detail.DigitalHeathCheckID === DigitalHeathCheckID &&
        detail.SurveyID === 2
    );
    const filteredSeDetails = reviewInDetails.filter(
      (detail) =>
        detail.DigitalHeathCheckID === DigitalHeathCheckID &&
        detail.SurveyID === 1
    );

    const orderedCA = orderBy(filteredCaDetails, "QuestionID", "asc");
    const orderedSE = orderBy(filteredSeDetails, "QuestionID", "asc");

    toExportCA = orderedCA.map((data) => ({
      Question: data.QuestionDisplay,
      "Agreement Level": data.AgreementLevel,
      "Required Level": data.RequiredLevelofAgreementID,
      "Recommended Level": data.RecommendedLevelofAgreementID,
    }));

    toExportSE = orderedSE.map((data) => ({
      Question: data.QuestionDisplay,
      "Agreement Level": data.AgreementLevel,
      "Required Level": data.RequiredLevelofAgreementID,
      "Recommended Level": data.RecommendedLevelofAgreementID,
    }));
  }

  // if RAM is present
  let toExportRAM;
  if (RAMId) {
    const filteredRAM = reviewRAMDetails.filter(
      (details) => details.RAMID === RAMId
    );
    console.log(filteredRAM);
    toExportRAM = filteredRAM.map((data) => {
      if (data.QuestionID > 14) {
        return {
          Question: data.Question,
          Answer: data.Answer,
        };
      }
      return {
        Question: data.Question,
        Answer: data.ChoiceDesc,
      };
    });
  }
  console.log("handleExport finished"); // New log statement
  if (shouldExport) {
    const exportName = `${projectName}_Survey_On_${createdDate}`;
    exportToCSV(
      DigitalHeathCheckID,
      toExportCA,
      toExportSE,
      toExportRAM,
      exportName
    );
  } else {
    return { toExportCA, toExportSE, toExportRAM };
  }
};

// New function handleExportAll
const handleExportAll = (reviewDetails, reviewRAMDetails, reviewInDetails) => {
  console.log("handleExportAll called"); // New log statement

  const wb = XLSX.utils.book_new();
  let allCAData = [];
  let allSEData = [];
  let allRAMData = [];

  reviewDetails.forEach((project, index) => {
    console.log("project:", project);
    let localRamID;
    if (project.digitalheathcheckid) {
      // Only check for digitalheathcheckid
      const selectedRAMData = reviewRAMDetails.find(
        (r) => r.DHCID === project.digitalheathcheckid
      );
      localRamID = get(selectedRAMData, "RAMID");
    }

    const data = handleExport(
      project.digitalheathcheckid,
      new Date(project.CreatedDate).toDateString(),
      project.ProjectName,
      localRamID,
      reviewRAMDetails,
      reviewInDetails,
      false // Pass false for shouldExport
    );

    console.log("toExportCA:", data.toExportCA);
    console.log("toExportSE:", data.toExportSE);
    console.log("toExportRAM:", data.toExportRAM);

    if (data.toExportCA && data.toExportSE) {
      data.toExportCA.forEach((item) => {
        allCAData.push({ ProjectName: project.ProjectName, ...item });
      });
      data.toExportSE.forEach((item) => {
        allSEData.push({ ProjectName: project.ProjectName, ...item });
      });
    }

    if (data.toExportRAM) {
      data.toExportRAM.forEach((item) => {
        allRAMData.push({ ProjectName: project.ProjectName, ...item });
      });
    }
  });

  if (allCAData.length > 0) {
    const ws1 = XLSX.utils.json_to_sheet(allCAData);
    XLSX.utils.book_append_sheet(wb, ws1, `Content Assurance`);
  }

  if (allSEData.length > 0) {
    const ws2 = XLSX.utils.json_to_sheet(allSEData);
    XLSX.utils.book_append_sheet(wb, ws2, `Software Engineering`);
  }

  if (allRAMData.length > 0) {
    const ws3 = XLSX.utils.json_to_sheet(allRAMData);
    XLSX.utils.book_append_sheet(wb, ws3, `RAM`);
  }

  if (wb.SheetNames.length > 0) {
    const date = new Date();
    const dateString = `${date.getFullYear()}-${
      date.getMonth() + 1
    }-${date.getDate()}`;
    const exportName = `All_Surveys_${dateString}`;
    XLSX.writeFile(wb, exportName + fileExtension);
  } else {
    console.log("No data to export");
  }
};

//Exporting to excel
const fileExtension = ".xlsx";
const exportToCSV = (
  DigitalHeathCheckID,
  caData,
  seData,
  ramData,
  exportName
) => {
  const wb = XLSX.utils.book_new();
  if (DigitalHeathCheckID) {
    var ws1 = XLSX.utils.json_to_sheet(caData);
    var ws2 = XLSX.utils.json_to_sheet(seData);
    XLSX.utils.book_append_sheet(wb, ws1, "Content Assurance");
    XLSX.utils.book_append_sheet(wb, ws2, "Software Engineering");
  }

  if (ramData) {
    var ws3 = XLSX.utils.json_to_sheet(ramData);
    XLSX.utils.book_append_sheet(wb, ws3, "RAM");
  }

  XLSX.writeFile(wb, exportName + fileExtension);
};

const Export = ({
  dhcID,
  date,
  projectName,
  ramDetails,
  reviewInDetails,
  ramId,
  shouldExport,
}) => {
  return (
    <>
      <Tooltip
        overlay="Export to Excel"
        trigger={
          <Button
            aria-label="export"
            variant="transparent"
            icon={<Download />}
            onClick={() => {
              let localRamID;
              if (dhcID && !ramId) {
                const selectedRAMData = ramDetails.find(
                  (r) => r.DHCID === dhcID
                );
                localRamID = get(selectedRAMData, "RAMID");
              } else if (ramId) {
                localRamID = ramId;
              }

              handleExport(
                dhcID,
                date,
                projectName,
                localRamID,
                ramDetails,
                reviewInDetails,
                shouldExport
              );
            }}
          ></Button>
        }
      />
    </>
  );
};

export default Export;
export { handleExportAll };
