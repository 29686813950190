import {
  FormField,
  Grid,
  Option,
  Select,
  Text,
} from '@sede-x/shell-ds-react-framework';
import React from 'react';

const EdtQuestion = ({
  index,
  questionId,
  questionText,
  questionCategory,
  answerOptions,
  handleAnswerChange,
  type = 'general',
  generalLength,
}) => {
  return (
    <Grid
      container
      columns="70% auto"
      columnGap="10%"
      style={{ paddingBottom: '36px', alignItems: 'center' }}
      className="edt-question"
    >
      <Grid children>
        <div>
          <Text className="category-text">{questionCategory}</Text>
          <Text bold>
            <b>{type === 'domain' ? generalLength + index + 1 : index + 1}.</b>{' '}
            {
              <Text
                type="span"
                dangerouslySetInnerHTML={{
                  __html: questionText,
                }}
              ></Text>
            }
          </Text>
        </div>
      </Grid>
      <Grid children>
        <FormField size="large">
          <Select
            placeholder="Choose answer"
            size="large"
            allowClear={false}
            onChange={(_, option) => handleAnswerChange(questionId, option)}
          >
            {answerOptions.map((option) => (
              <Option
                value={option.value}
                key={option.value}
              >
                {option.label}
              </Option>
            ))}
          </Select>
        </FormField>
      </Grid>
    </Grid>
  );
};

export default EdtQuestion;
