import React, { useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Login, Project, RiskAssessment, Authenticator } from '../components';
import {
  ThemeProvider,
  createMuiTheme,
  makeStyles,
} from '@material-ui/core/styles';
import { amber } from '@material-ui/core/colors';

import '../styles/styles.scss';
import ReviewPage from './review/ReviewPage';
import DhcSurvey from './survey/DhcSurvey';
import DashBoard from '../dasboard/DashBoard';
import Home from './project/Home';

const useStyles = makeStyles((theme) => ({
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    padding: '5px',
    [theme.breakpoints.up('md')]: {
      marginLeft: '250px',
    },
    backgroundColor: '#FFFAF1',
    margin: '65px 0px',
    height: 'auto',
  },
}));

const PrivateRoute = () => {
  const classes = useStyles();

  return (
    <>
      {/* {auth && auth.userData && ( */}
      <>
        <main className={classes.content}>
          <Switch>
            <ThemeProvider theme={Theme}>
              <Route
                exact
                path="/home"
              >
                <Home />
              </Route>
              <Route path="/review">
                <DashBoard />
              </Route>
              <Route path="/survey">
                <DhcSurvey />
              </Route>
              <Route path="/riskAssessment">
                <RiskAssessment />
              </Route>
            </ThemeProvider>
          </Switch>
        </main>
      </>
      {/* )} */}
    </>
  );
};
const Theme = createMuiTheme({
  palette: {
    primary: amber,
  },
});
export { PrivateRoute };
