import {
  Option,
  Select,
  Text,
  Grid,
  Button,
} from "@sede-x/shell-ds-react-framework";
import React, { useEffect, useContext, useState } from "react";
import { actions, Context } from "../../context";
import { details, api } from "../../api";
import "./ExistingProject.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import SnackBar from "../SnackBars";
import {
  Add,
  Cross,
  Check,
  Edit,
} from "@sede-x/shell-ds-react-framework/build/esm/components/Icon/components";
import { GET_ALL_EMAIL_IDS } from "../../api/APIdetails";
import { checkArr } from "./DataConfig";
import axios from "axios";

const ExistingProject = ({ onProjectChange, loadProjects }) => {
  const [state, dispatch] = useContext(Context);
  const history = useHistory();
  const [redirectFlag, setRedirectFlag] = useState(false);
  const {
    BASE_URL,
    GET_RAM_DETAILS_BY_PROJECT_ID_URL,
    PUT_POC_EMAIL_IDS,
    PUT_MCDS_ORG,
  } = details;
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [allEmailIds, setAllEmailIds] = useState([]);
  const [value, setValue] = useState();
  const [editMode, setEditMode] = useState(false);
  const [editSelectMode, setEditSelectMode] = useState(false);
  const [editMCDS, setEditMCDS] = useState(false);
  const [editMCDSBtn, setMCDSBtn] = useState(false);
  const [selectedMCDS, setSelectedMCDS] = useState([]);
  const [selectedMCDSCheck, setSelectedMCDSCheck] = useState([]);

  useEffect(() => {
    loadEmailIds();
  }, []);

  useEffect(() => {
    // console.log("New Project");
    setEditMode(false);
    setEditSelectMode(false);
    setEditMCDS(false);
    setMCDSBtn(false);
  }, [onProjectChange]);
  const {
    selectedProject,
    banner,
    selectedFlag,
    selectedProject: {
      Description,
      PointOfContactEmailID,
      Areas,
      ComplexityLevel,
      ImpactLevel,
      ImpactDescription,
      ComplexityDescription,
      ProjectName,
      FunnelProjectID,
      ProjectID,
      MCDSOrg,
    },
    project = {},
  } = state;

  useEffect(() => {
    dispatch({
      type: actions.SET_SelectedProject,
      payload: {},
    });
  }, []);

  useEffect(() => {
    const params = { ProjectID };
    let url = `${BASE_URL}${GET_RAM_DETAILS_BY_PROJECT_ID_URL}`;
    if (ProjectID && Areas === "Financial Operations") {
      api.getCalls("getDetails", url, params).then((response) => {
        let { data: { recordset = [] } = {}, error } = response;
        if (recordset.length >= 1) {
          setRedirectFlag(false);
        } else {
          setRedirectFlag(false);
        }
      });
    }
    setEditMode(false);
    setMCDSBtn(false);
    return () => {
      setBanner(false, "", "");
    };
  }, [selectedProject]);

  // console.log(redirectFlag);
  console.log(selectedProject);

  const onTakeSurvey = () => {
    dispatch({
      type: actions.SET_TakingSurvey,
      payload: !state.takingSurvey,
    });
    dispatch({
      type: actions.SET_SelectedFlag,
      payload: "existingProject",
    });

    if (Areas === "Financial Operations") {
      if (!redirectFlag) {
        console.log("redirecting");
        history.push("/riskAssessment");
        dispatch({
          type: actions.SET_selectedMenuIndex,
          payload: {
            index: 5,
          },
        });
      } else {
        console.log("not redirecting");
        setBanner(
          true,
          "warning",
          "Risk Assessment Matrix(RAM) is already evaluated for this project"
        );
      }
    } else if (Areas === "Emerging Digital Technologies") {
      history.push("/edt");
      dispatch({
        type: actions.SET_selectedMenuIndex,
        payload: {
          index: 6,
        },
      });
    } else {
      history.push("/survey");
      dispatch({
        type: actions.SET_selectedMenuIndex,
        payload: {
          index: 4,
        },
      });
    }
  };

  const setBanner = (show, type = "error", error) => {
    dispatch({
      type: actions.SET_Banner,
      payload: {
        show,
        type,
        message: error,
      },
    });
  };

  const putMCDSOrg = async () => {
    console.log("Check Clicked");
    const params = { ProjectID };
    try {
      let McdsCheck = "";
      if (
        typeof selectedMCDS !== "undefined" &&
        selectedMCDS !== null &&
        Array.isArray(selectedMCDS)
      ) {
        const valueString = selectedMCDS.join(",");
        McdsCheck = valueString;
      }
      console.log("Mcds- " + McdsCheck);
      const body = {
        McdsCheck,
      };
      let url = `${BASE_URL}${PUT_MCDS_ORG}`;
      // console.log("URL: ", url);
      let response = await axios.put(url, body, { params: { ProjectID } });
      //   console.log(response);
      let { data: { recordsets = [] } = {}, error } = response;
      if (error) {
        setBanner(true, "error", error);
        console.log(error);
      } else {
        setBanner(true, "success", "MCDS Organisation Updated Successfully");
        loadProjects(true);
      }
    } catch (error) {
      console.log(`PUT Email-`, error.message);

      const errorCode = error.response ? error.response.status : 500;
      history.push("/errorPage", { error, errorCode });
    }
  };

  const putPOCemail = async () => {
    // console.log("Check Clicked");
    const params = { ProjectID };
    try {
      let Email = "";
      if (
        typeof value !== "undefined" &&
        value !== null &&
        Array.isArray(value)
      ) {
        const valueString = value.join(",");
        Email = Email ? Email + "," + valueString : valueString;
      }
      // console.log("Email List:", Email);
      const body = {
        Email,
      };
      let url = `${BASE_URL}${PUT_POC_EMAIL_IDS}`;
      // console.log("URL: ", url);
      let response = await axios.put(url, body, { params: { ProjectID } });
      //   console.log(response);
      let { data: { recordsets = [] } = {}, error } = response;
      if (error) {
        setBanner(true, "error", error);
        console.log(error);
      } else {
        setBanner(true, "success", "Email Updated Successfully");
        loadProjects(true);
      }
    } catch (error) {
      console.log(`PUT Email-`, error.message);

      const errorCode = error.response ? error.response.status : 500;
      history.push("/errorPage", { error, errorCode });
    }
  };

  // useEffect(() => {
  //   console.log("UseEffect-", state);
  //   if (ProjectID) {
  //     onProjectChange(null, { value: ProjectID });
  //   }
  // }, [state]);
  const loadEmailIds = async () => {
    try {
      let url = `${BASE_URL}${GET_ALL_EMAIL_IDS}`;
      let response = await axios.get(url);
      //   console.log(response);
      let { data: { recordsets = [] } = {}, error } = response;
      console.log(recordsets);
      setAllEmailIds(recordsets[0]);
    } catch (error) {
      // console.log(error.toJSON.message());
      // console.log(err.response.status);
      // console.log(err.response.headers);
      console.log(`Email-`, error.message);
      // setErrMsg(error.message);
      // if (!error.response) {
      //   history.push("/errorPage");
      // } else {
      // console.log(error.response.status);
      const errorCode = error.response ? error.response.status : 500;
      history.push("/errorPage", { error, errorCode });
      // }
      // console.log(error.toJSON());
    }
  };

  const handleSearch = (value) => {
    setSearchTerm(value);
  };

  const handleEmailSelect = (value) => {
    if (selectedEmails.length < 3) {
      setSelectedEmails(value);
      setValue(value);
    }
  };

  const filteredOptions = allEmailIds.filter((option) =>
    option.EmailID.toString().toLowerCase().includes(searchTerm.toLowerCase())
  );

  const toggleEditMode = () => {
    setEditMode(!editMode);
    setEditSelectMode(true);
  };
  const toggleCheckMode = () => {
    setEditMode(!editMode);
    putPOCemail();
  };

  const handleMCDSSelect = (value) => {
    setSelectedMCDSCheck(value);
    setSelectedMCDS(value);
  };
  console.log(selectedMCDS + "   hh  " + selectedMCDSCheck);
  const toggleMCDSBtn = () => {
    setMCDSBtn(!editMCDSBtn);
    setEditMCDS(true);
  };
  const toggleMCDSCheckMode = () => {
    setMCDSBtn(!editMCDSBtn);
    putMCDSOrg();
  };

  return (
    <div className="exist-container">
      {banner.show && (
        <SnackBar banner={banner} onClose={() => setBanner(false, "", "")} />
      )}
      {project && (
        <Select
          placeholder="Search Project Name"
          size="medium"
          showSearch={true}
          placement="bottom"
          allowClear={false}
          onChange={onProjectChange}
        >
          {project.listOfProjects.map((projectItem, index) => (
            <Option value={projectItem.ProjectID} key={projectItem.ProjectID}>
              {projectItem.ProjectName}
            </Option>
          ))}
        </Select>
      )}
      <div className="project-details">
        {selectedProject && selectedProject.ProjectID !== undefined && (
          <>
            <Grid container columns={2}>
              {FunnelProjectID && (
                <>
                  <Grid children>
                    <Text bold={true}>Project Opportunity ID</Text>
                  </Grid>
                  <Grid children>
                    <Text>{FunnelProjectID}</Text>
                  </Grid>
                </>
              )}
              <Grid children>
                <Text bold={true}>MCDS Project Organisation</Text>
              </Grid>
              <Grid children>
                <div className="contact-values">
                  {editMCDS ? (
                    <Select
                      size="medium"
                      mode="tags"
                      showSearch
                      allowClear
                      onChange={handleMCDSSelect}
                      // onSearch={handleSearch}
                      value={selectedMCDSCheck}
                      placeholder={`Select the MCDS Organisation`}
                      // disabled={selectedEmails.length === 3}
                    >
                      {/* Render options only for the filtered options */}
                      {/* {searchTerm && */}
                      {checkArr().map((option) => (
                        <Option key={option.name} value={option.name}>
                          {option.name}
                          {/* {option} */}
                        </Option>
                      ))}
                    </Select>
                  ) : (
                    <Text>{MCDSOrg}</Text>
                  )}
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "auto auto",
                    }}
                  >
                    {editMCDSBtn ? (
                      <Button
                        iconOnly
                        icon={<Check />}
                        variant="transparent"
                        onClick={toggleMCDSCheckMode}
                      ></Button>
                    ) : (
                      <Button
                        iconOnly
                        icon={<Edit />}
                        variant="transparent"
                        onClick={toggleMCDSBtn}
                      ></Button>
                    )}

                    {/* {editMCDSBtn && (
                      <Button
                        iconOnly
                        icon={<Cross />} // Replace with your Cross icon
                        variant="transparent"
                        onClick={() => {
                          setSelectedMCDSCheck([]);
                          setSelectedMCDS(undefined);
                        }}
                        style={{ gridColumn: "1 / span 2" }}
                      ></Button>
                    )} */}
                  </div>
                </div>
              </Grid>
              <Grid children>
                <Text bold={true}>Domain</Text>
              </Grid>
              <Grid children>
                <Text>{Areas}</Text>
              </Grid>
              <Grid children>
                <Text bold={true}>Email</Text>
              </Grid>

              <Grid children>
                <div className="contact-values">
                  {editSelectMode ? (
                    <Select
                      size="medium"
                      mode="tags"
                      showSearch
                      allowClear
                      onChange={handleEmailSelect}
                      onSearch={handleSearch}
                      // value={searchTerm ? selectedEmails : undefined}
                      value={selectedEmails}
                      placeholder={`Max. 3 Shell email addresses in total`}
                      disabled={selectedEmails.length === 3}
                    >
                      {/* Render options only for the filtered options */}
                      {searchTerm &&
                        filteredOptions.map((option) => (
                          <Option key={option.EmailID} value={option.EmailID}>
                            {option.EmailID}
                            {/* {option} */}
                          </Option>
                        ))}
                    </Select>
                  ) : (
                    <Grid children>
                      <Text>{PointOfContactEmailID}</Text>
                    </Grid>
                  )}
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "auto auto",
                    }}
                  >
                    {editMode ? (
                      <Button
                        iconOnly
                        icon={<Check />}
                        variant="transparent"
                        onClick={toggleCheckMode}
                      ></Button>
                    ) : (
                      <Button
                        iconOnly
                        icon={<Edit />}
                        variant="transparent"
                        onClick={toggleEditMode}
                      ></Button>
                    )}

                    {editMode && selectedEmails.length === 3 && (
                      <Button
                        iconOnly
                        icon={<Cross />} // Replace with your Cross icon
                        variant="transparent"
                        onClick={() => {
                          setSelectedEmails([]);
                          setSearchTerm("");
                          setValue(undefined);
                          // setSelectedEmails(!emailBtnDis);
                        }}
                        style={{ gridColumn: "1 / span 2" }}
                      ></Button>
                    )}
                  </div>
                </div>
              </Grid>
              {Description && (
                <>
                  <Grid children>
                    <Text bold={true}>Project Description</Text>
                  </Grid>
                  <Grid children>
                    <Text>{Description}</Text>
                  </Grid>
                </>
              )}

              {Areas !== "Financial Operations" && (
                <>
                  <Grid children>
                    <Text bold={true}>Project Complexity</Text>
                  </Grid>
                  <Grid children>
                    <Text>
                      {" "}
                      <b>{ComplexityLevel}</b> {"\u00A0"}
                      {`(${ComplexityDescription})`}
                    </Text>
                  </Grid>
                  {ImpactDescription && (
                    <>
                      <Grid children>
                        <Text bold={true}>Project Impact</Text>
                      </Grid>
                      <Grid children>
                        <Text>
                          <b>{ImpactLevel}</b> {"\u00A0"}
                          {`(${ImpactDescription})`}
                        </Text>
                      </Grid>
                    </>
                  )}
                </>
              )}
            </Grid>
            <br />
            <br />
            <br />
            <div style={{ textAlign: "center" }}>
              <Button
                size="medium"
                variant="filled"
                sentiment="default"
                onClick={() => onTakeSurvey()}
              >
                TAKE A NEW SURVEY
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ExistingProject;
