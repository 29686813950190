import getURLParameter from './getURLParameter';
import getToken from './getToken';

const hasPingIdRespondedWithError = () =>
  getURLParameter('error', window.location.href);

const extractCodeFromUrl = () => getURLParameter('code', window.location.href);

const getAccessToken = async (clientId) => {
  if (hasPingIdRespondedWithError()) {
    throw new Error('You are not authorized to use the application.');
  }
  const code = extractCodeFromUrl();
  if (code) {
    return getToken(clientId, code);
  }
  return '';
};

export default getAccessToken;
