export const checkArr = () => {
  return [
    {
      name: "IDA CC DSML",
      label: "IDA CC DSML",
    },
    {
      name: "IDT Digital Innovation",
      label: "IDT Digital Innovation",
    },
    {
      name: "PTX",
      label: "PTX",
    },
    {
      name: "Commercial Data Science",
      label: "Commercial Data Science",
    },
    {
      name: "Other",
      label: "Other",
    },
  ];
};
