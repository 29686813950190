import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SurveyView from './SurveyView';

const surveyHeading = {
  CA: 'Content Assurance Survey',
  SE: 'Software Engineering Survey',
};

export default function Survey(props) {
  const [queId, setQueId] = useState(0);
  const [viewDesc, setViewDesc] = useState(false);
  const classes = useStyles();

  console.log(props);

  let {
    surveyQuestions,
    agreementLevels,
    contentSurveyResult,
    softwareSurveyResult,
    setContentSurveyResult,
    setSoftwareSurveyResult,
    Heading,
    incremental = 0,
    marks = agreementLevels.map((val) => {
      let res = {
        AgreementID: val.AgreementID,
        AgreementValueID: val.AgreementValueID,
        value: incremental,
        label: val.AgreementLevel,
      };
      incremental = (incremental || 0) + 25;
      return res;
    }),
  } = props;
  const onSliderChange = (event = {}, value, id) => {
    let ids = id,
      [Qid, Sid, RecommendedLevelofAgreementID] = ids.split('_');
    let index = marks.findIndex((mark) => mark.AgreementValueID === value),
      getResult = (inputContent) => {
        let content = inputContent;
        let contentIndex = content.findIndex((res) => res.QuestionID === Qid);

        if (contentIndex >= 0) {
          content[contentIndex].LevelofAgreementID =
            marks[index].AgreementValueID;
          content[contentIndex].SurveyID = Sid;
        } else {
          content.push({
            QuestionID: Qid,
            SurveyID: Sid,
            RecommendedLevelofAgreementID: RecommendedLevelofAgreementID,
            LevelofAgreementID: marks[index].AgreementValueID,
          });
        }
        props.setCount(Heading, content.length);
        return content;
      };

    if (surveyHeading[Heading] === surveyHeading.CA) {
      setContentSurveyResult(getResult(contentSurveyResult));
    } else if (surveyHeading[Heading] === surveyHeading.SE) {
      setSoftwareSurveyResult(getResult(softwareSurveyResult));
    }
  };

  const handleIconClicks = (questionId) => {
    setQueId(questionId);
    setViewDesc(!viewDesc);
  };

  return (
    <div className={classes.survey}>
      {surveyQuestions.map((data, index) => {
        if (
          surveyHeading[Heading] === surveyHeading.CA &&
          data.SurveyID === 2
        ) {
          const found = contentSurveyResult.find(
            (item) => Number(item.QuestionID) === data.QuestionID
          );

          return (
            <div key={index}>
              <SurveyView
                heading={Heading}
                selectedData={found ? found.LevelofAgreementID : ''}
                selected
                {...{
                  handleIconClicks,
                  queId,
                  onSliderChange,
                  marks,
                  data,
                  viewDesc,
                  classes,
                }}
              />
            </div>
          );
        } else if (
          surveyHeading[Heading] === surveyHeading.SE &&
          data.SurveyID === 1
        ) {
          const found = softwareSurveyResult.find(
            (item) => Number(item.QuestionID) === data.QuestionID
          );
          return (
            <div key={index}>
              <SurveyView
                heading={Heading}
                selectedData={found ? found.LevelofAgreementID : ''}
                {...{
                  handleIconClicks,
                  queId,
                  onSliderChange,
                  marks,
                  data,
                  viewDesc,
                  classes,
                }}
              />
            </div>
          );
        }
      })}
    </div>
  );
}
const useStyles = makeStyles((theme) => ({
  root: {
    width: 300 + theme.spacing(3) * 4,
  },
  margin: {
    height: theme.spacing(3),
  },
  survey: {
    flexGrow: 1,
    marginLeft: '50px',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
}));
