import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ContactDetails from "./ContactDetails";
import pecten from "../../styles/images/pecten.svg";
import dayeeta_roy from "../../styles/images/dayeeta_roy.jpg";
import lydia_stanier from "../../styles/images/lydia_stanier.jpg";
import chiranjib_sur from "../../styles/images/chiranjib_sur.jpg";
import paul_gelderblom from "../../styles/images/paul_gelderblom.jpg";
import tharini_thiru from "../../styles/images/tharini_thirukkumar.jpg";

const contactArr = [
  {
    name: "Paul Gelderblom",
    email: "paul.gelderblom@shell.com",
    imageSrc: paul_gelderblom,
    role: "Application Owner",
  },
  {
    name: "Lydia Stanier",
    email: "lydia.stanier@shell.com",
    imageSrc: lydia_stanier,
    role: "Business Analyst",
  },
  {
    name: "Tharini Thirukkumar",
    email: "tharini.thirukkumar@shell.com",
    imageSrc: tharini_thiru,
    role: "MCDS Opportunity Manager",
  },
];

export default function ContactForm() {
  return (
    <>
      <ContactDetails {...{ contactArr }} />
    </>
  );
}
