import {
  Accordion,
  AccordionPanel,
  Button,
  Card,
  FormField,
  Grid,
  Loading,
  ProgressBar,
  Table,
  Tag,
  Text,
  TextInput,
} from "@sede-x/shell-ds-react-framework";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import {
  ArrowDown,
  ArrowUp,
  CalendarNote,
  Download,
  Search,
} from "@sede-x/shell-ds-react-framework/build/esm/components/Icon/components";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import "./DashBoard.css";
import {
  BASE_URL,
  GET_EDT_SURVEY_DETAILS,
  GET_EDT_SURVEY_RESULTS,
  GET_EDT_SURVEY_RES_NO_RAM,
} from "../api/APIdetails";
import { api, details } from "../api";
import {
  TBody,
  TD,
  TH,
  THead,
  TR,
} from "@sede-x/shell-ds-react-framework/build/esm/components/Table/Table.styles";
import EdtReviews from "./EdtReviews";
import { Context, actions } from "../context";
import { handleExportAll } from "../components/review/Export";
import get from "lodash.get";
import orderBy from "lodash.orderby";
import concat from "lodash/concat";
import Export from "../components/review/Export";
import ScoreSummary from "./ScoreSummary";
import ReviewDetails from "./ReviewDetails";

const surveyAllTypes = [
  ["CA", "Quality Metrics - Content Assurance"],
  ["SE", "Quality Metrics - Software Engineering"],
  ["RAM", "Risk Assessment Matrix"],
];

const surveyDHCTypes = [
  ["CA", "Quality Metrics - Content Assurance"],
  ["SE", "Quality Metrics - Software Engineering"],
];

const DashBoard = () => {
  const [state, dispatch] = useContext(Context);
  const [descendingOrder, setDescendingOrder] = useState(true);
  const [reviews, setReviews] = useState({
    reviewDetails: [],
    reviewInDetails: [],
    reviewRAMDetails: [],
  });
  const { RAMNeeded } = state;
  const [loading, setLoading] = useState(false);
  const { BASE_URL, GET_REVIEWDETAILS_URL } = details;
  let { reviewDetails, reviewInDetails, reviewRAMDetails } = reviews;

  const [edtSurveyResults, setEdtSurveyResults] = useState([]);

  // Toggle Sort Order
  function toggleSortOrder() {
    const sortedReviews = [...reviewDetails];
    const isNextSortOrderDescending = !descendingOrder;
    if (isNextSortOrderDescending) {
      console.log(descendingOrder, "descend");
      sortedReviews.sort(
        (a, b) =>
          new Date(b.CreatedDate || b.LastModifiedDate) -
          new Date(a.CreatedDate || a.LastModifiedDate)
      );
    } else {
      console.log(descendingOrder, "ascend");
      sortedReviews.sort(
        (a, b) =>
          new Date(a.CreatedDate || a.LastModifiedDate) -
          new Date(b.CreatedDate || b.LastModifiedDate)
      );
    }
    setReviews((prevState) => ({
      ...prevState,
      reviewDetails: sortedReviews,
    }));
    setDescendingOrder(isNextSortOrderDescending);
  }

  //Search Filter
  const [searchQuery, setSearchQuery] = useState("");
  const [domainFilters, setDomainFilters] = useState([]);

  const filteredReviews = useMemo(() => {
    // If there are no domain filters selected, return only the search results
    if (domainFilters.length === 0) {
      return reviewDetails.filter((item) => {
        return item.ProjectName.toLowerCase().includes(
          searchQuery.toLowerCase()
        );
      });
    } // Else, return the search results filtered by the domain filters
    else {
      let filteredResults;
      filteredResults = reviewDetails.filter((item) => {
        let projectMCDFilterArray;
        if (item.MCDSOrg !== null) {
          // console.log(item.MCDSOrg);
          projectMCDFilterArray = item.MCDSOrg.split(",");
          // console.log(
          //   "Filter Array- ",
          //   projectMCDFilterArray,
          //   item.ProjectName
          // );

          // filter out projects requiring Technical Assurance
          if (domainFilters.includes("Technical Assurance")) {
            let selectedRAMArr = [];
            reviewRAMDetails
              .filter((d) => d.ProjectID === item.projectid)
              .forEach((item) => {
                const found = selectedRAMArr.findIndex(
                  (e1) => e1.RAMID === item.RAMID
                );
                if (found === -1) {
                  selectedRAMArr.push({
                    RAMID: item.RAMID,
                    TARequiredOrNot: item.TARequiredOrNot,
                    CreatedDate: item.CreatedDate,
                  });
                }
              });
            const selectedDataForRAM = selectedRAMArr.find(
              (d) => d.TARequiredOrNot
            );
            if (item.Areas !== "Emerging Digital Technologies") {
              // if other domain filters are also selected
              if (domainFilters.length > 1) {
                return (
                  get(selectedDataForRAM, "TARequiredOrNot") &&
                  projectMCDFilterArray.some((e) => domainFilters.includes(e))
                );
              }
              return get(selectedDataForRAM, "TARequiredOrNot");
            } else {
              // if other domain filters are also selected
              if (domainFilters.length > 1) {
                return (
                  item.TARequiredOrNot &&
                  projectMCDFilterArray.some((e) => domainFilters.includes(e))
                );
              }
              return item.TARequiredOrNot;
            }
          }
          return projectMCDFilterArray.some((e) => domainFilters.includes(e));
        }
      });
      console.log("Results", filteredResults);
      return filteredResults.filter((item) => {
        return item.ProjectName.toLowerCase().includes(
          searchQuery.toLowerCase()
        );
      });
    }
  }, [reviewDetails, searchQuery, domainFilters]);

  //Domain Filter Chip
  // const [chipFilters, setChipFilters] = useState([
  //   { key: 0, label: "IDA CC DSML", variant: "outlined" },
  //   { key: 1, label: "IDT Digital Innovation", variant: "outlined" },
  //   { key: 2, label: "PTX", variant: "outlined" },
  //   { key: 3, label: "Commercial Data Science", variant: "outlined" },
  //   { key: 4, label: "Other", variant: "outlined" },
  //   { key: 5, label: "Technical Assurance", variant: "outlined" },
  // ]);
  const initialFilters = [
    { key: 0, label: "IDA CC DSML", variant: "outlined" },
    { key: 1, label: "IDT Digital Innovation", variant: "outlined" },
    { key: 2, label: "PTX", variant: "outlined" },
    { key: 3, label: "Commercial Data Science", variant: "outlined" },
    { key: 4, label: "Other", variant: "outlined" },
  ];

  const [chipFilters, setChipFilters] = useState(
    RAMNeeded
      ? [
          ...initialFilters,
          { key: 5, label: "Technical Assurance", variant: "outlined" },
        ]
      : initialFilters
  );

  const handleFilterClick = (key) => {
    const newChipFilters = [...chipFilters];
    newChipFilters[key].variant =
      newChipFilters[key].variant === "outlined" ? "filled" : "outlined";
    setChipFilters(newChipFilters);
    // setDomainFilter([...domainFilter, chipFilters[key].label]);
    const newFilter = chipFilters[key].label;
    const filterIndex = domainFilters.indexOf(newFilter);
    if (filterIndex === -1) {
      setDomainFilters([...domainFilters, newFilter]);
    } else {
      const newDomainFilters = [...domainFilters];
      newDomainFilters.splice(filterIndex, 1);
      console.log(newDomainFilters);
      setDomainFilters(newDomainFilters);
    }
  };

  const usePrevious = (value = []) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const previousReviews = usePrevious(reviewDetails);

  useEffect(() => {
    setLoading(true);
    if (
      reviewDetails.length === 0 ||
      previousReviews.length !== reviewDetails.length
    ) {
      fetchReviews();
    }
  }, []);

  useEffect(() => {
    console.log("Review Details", reviewDetails);
  }, [reviewDetails][reviews]);

  const fetchReviews = async () => {
    //fetch non - edt reviews
    let url = `${BASE_URL}${GET_REVIEWDETAILS_URL}`;
    let response = await api.getCalls("fetchReviews", url);
    let { data: { recordsets = [] } = {}, error } = response;
    let [Details, InDetails, RAMDetails, RAMAllDetails] = recordsets;

    console.log("Record Sets:", recordsets);
    //fetch edt reviews
    const edtUrl = `${BASE_URL}${GET_EDT_SURVEY_DETAILS}`;
    const edtResponse = await api.getCalls("loadEdtSurvey", edtUrl);

    // const edtResUrl = `${BASE_URL}${GET_EDT_SURVEY_RESULTS}`;
    const edtResUrl = RAMNeeded
      ? `${BASE_URL}${GET_EDT_SURVEY_RESULTS}`
      : `${BASE_URL}${GET_EDT_SURVEY_RES_NO_RAM}`;
    const edtResResponse = await api.getCalls("loadEdtRes", edtResUrl);
    setEdtSurveyResults(edtResResponse.data);

    if (error || edtResponse.error) {
      setBanner(true, "error", error);
      setLoading(false);
    } else {
      if (RAMNeeded) {
        const updatedReviewDetails = concat(
          Details,
          RAMAllDetails,
          edtResponse.data
        );
        // console.log(updatedReviewDetails);

        setReviews((prevState) => ({
          ...prevState,
          reviewDetails: updatedReviewDetails.sort(
            (a, b) =>
              new Date(b.CreatedDate || b.LastModifiedDate) -
              new Date(a.CreatedDate || a.LastModifiedDate)
          ),
          reviewInDetails: InDetails,
          reviewRAMDetails: RAMDetails.filter(
            (d) => d.QuestionID !== 1 && d.QuestionID !== 2
          ),
        }));
        setLoading(false);
      } else {
        const updatedReviewDetails = concat(
          Details,
          RAMAllDetails,
          edtResResponse.data
        );
        // console.log("Update Review Details - ", updatedReviewDetails);
        setReviews((prevState) => ({
          ...prevState,
          reviewDetails: updatedReviewDetails.sort(
            (a, b) =>
              new Date(b.CreatedDate || b.LastModifiedDate || b.date_created) -
              new Date(a.CreatedDate || a.LastModifiedDate || a.date_created)
          ),
          reviewInDetails: InDetails,
          reviewRAMDetails: RAMDetails.filter(
            (d) => d.QuestionID !== 1 && d.QuestionID !== 2
          ),
        }));
        setLoading(false);
      }
    }
  };

  console.log(edtSurveyResults);
  const setBanner = (show, type = "error", message) => {
    dispatch({
      type: actions.SET_Banner,
      payload: {
        show,
        type,
        message,
      },
    });
  };

  // console.log(reviewDetails);

  const exportEdtSurveyResponses = (survey, ProjectName) => {
    console.log(survey.results);
    const toExportEdt = survey.results.map((data) => ({
      Question: data.text,
      Answer: data.answer,
      Type: data.type,
    }));

    const date = new Date(survey.date_created).toLocaleDateString();
    exportToCSV(toExportEdt, `${ProjectName}_EDT_Survey_On_${date}`);
  };

  const exportToCSV = (apiData, fileName) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(apiData);

    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const exportAllEdtSurveyResponses = (edtSurveyResults) => {
    let allData = [];

    // Loop over all surveys
    edtSurveyResults.forEach((survey) => {
      const toExportEdt = survey.results.map((data) => ({
        ProjectName: survey.ProjectName,
        ProjectOpportunityID: survey.project_id,
        Question: data.text,
        Answer: data.answer,
        Type: data.type,
      }));

      allData = [...allData, ...toExportEdt];
    });

    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(allData); // generate worksheet

    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    const date = new Date().toLocaleDateString();
    FileSaver.saveAs(data, `All_EDT_Surveys_on_${date}` + fileExtension);
  };

  return (
    <div className="dashboard-main">
      {loading && (
        <div className="auth-loader">
          <Loading />
        </div>
      )}
      {reviewDetails && reviewDetails.length > 0 && (
        <div className="dashboard-header">
          <TextInput
            prefix={{ node: <Search /> }}
            placeholder="Search Projects"
            size="small"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <div className="dashboard-filters">
            {chipFilters.map((filter) => (
              <Tag
                key={filter.key}
                children={filter.label}
                variant={filter.variant}
                sentiment="information"
                onClick={() => handleFilterClick(filter.key)}
                style={{ cursor: "pointer" }}
              />
            ))}
            <div className="date-toggle" onClick={toggleSortOrder}>
              <CalendarNote />
              {descendingOrder ? <ArrowUp /> : <ArrowDown />}
            </div>
          </div>
          <div className="export-all-edt">
            <Button
              icon={<Download />}
              variant="transparent"
              onClick={() => exportAllEdtSurveyResponses(edtSurveyResults)}
            >
              Export All EDT Surveys
            </Button>
            <Button
              aria-label="export-all"
              variant="transparent"
              icon={<Download />}
              onClick={() =>
                handleExportAll(
                  reviewDetails,
                  reviewRAMDetails,
                  reviewInDetails
                )
              }
            >
              Export All Data Science/Computational Science Surveys
            </Button>
          </div>
        </div>
      )}

      <br />
      <div className="other-reviews">
        <Accordion iconSet="chevron" accordion={true}>
          {(filteredReviews || []).map((item, idx) => {
            // console.log(item);
            let {
              digitalheathcheckid,
              ProjectName,
              FunnelProjectID,
              Areas,
              EmailID,
              Description,
              PointOfContactEmailID,
              ComplexityID,
              ComplexityLevel,
              ComplexityDescription,
              ImpactID,
              ImpactLevel,
              ImpactDescription,
              projectid,
              MCDSOrg,
            } = item;
            let selectedArr = [];
            let selectedRAMArr = [];
            const ImpactColor =
              ImpactLevel === "Low"
                ? "#008443"
                : ImpactLevel === "Medium"
                ? "#EB8705"
                : "#DD1D21";
            const complexityColor =
              ComplexityLevel === "Low"
                ? "#008443"
                : ComplexityLevel === "Medium"
                ? "#EB8705"
                : "#DD1D21";
            reviewInDetails
              .filter((d) => d.ProjectID === projectid)
              .forEach((item) => {
                const found = selectedArr.findIndex(
                  (e1) => e1.DigitalHeathCheckID === item.DigitalHeathCheckID
                );
                if (found === -1) {
                  selectedArr.push({
                    DigitalHeathCheckID: item.DigitalHeathCheckID,
                    contentSurveyResult: item.contentSurveyResult,
                    softwareSurveyResult: item.softwareSurveyResult,
                    CreatedDate: item.CreatedDate,
                  });
                }
              });
            reviewRAMDetails
              .filter((d) => d.ProjectID === projectid)
              .forEach((item) => {
                const found = selectedRAMArr.findIndex(
                  (e1) => e1.RAMID === item.RAMID
                );
                if (found === -1) {
                  selectedRAMArr.push({
                    RAMID: item.RAMID,
                    TARequiredOrNot: item.TARequiredOrNot,
                    CreatedDate: item.CreatedDate,
                  });
                }
              });
            const selectedDataForRAM = selectedRAMArr.find(
              (d) => d.TARequiredOrNot
            );
            return (
              <AccordionPanel
                key={idx}
                header={
                  <div className="panel-header">
                    <div>
                      <Text bold={true}>{ProjectName}</Text>
                    </div>
                    <div>
                      <Text> {MCDSOrg}</Text>
                    </div>
                    <div>
                      <Text> {Areas}</Text>
                    </div>
                    {RAMNeeded ? (
                      <div>
                        {digitalheathcheckid ? (
                          <Text
                            style={{
                              color: `${
                                get(selectedDataForRAM, "TARequiredOrNot")
                                  ? "#DD1D21"
                                  : "#008443"
                              }`,
                            }}
                            variant="subtitle2"
                          >
                            {get(selectedDataForRAM, "TARequiredOrNot")
                              ? "Technical Assurance is required"
                              : "Technical Assurance is not required"}
                          </Text>
                        ) : Areas === "Emerging Digital Technologies" ? (
                          <div>
                            {item.TARequiredOrNot ? (
                              <Text style={{ color: "rgb(221, 29, 33)" }}>
                                Technical Assurance is required
                              </Text>
                            ) : (
                              <Text style={{ color: "rgb(0, 132, 67)" }}>
                                Technical Assurance is not required
                              </Text>
                            )}
                          </div>
                        ) : (
                          <>
                            <Text
                              style={{
                                color: `${
                                  get(selectedDataForRAM, "TARequiredOrNot")
                                    ? "#DD1D21"
                                    : "#008443"
                                }`,
                              }}
                              variant="subtitle2"
                            >
                              {get(selectedDataForRAM, "TARequiredOrNot")
                                ? "Technical Assurance is required"
                                : "Technical Assurance is not required"}
                            </Text>
                          </>
                        )}
                      </div>
                    ) : null}
                  </div>
                }
              >
                <br />
                <Card className="summary-card">
                  <div className="project-summary">
                    <Grid container columns="20% auto">
                      {FunnelProjectID && (
                        <>
                          <Grid children>
                            <Text bold>Project Opportunity ID:</Text>
                          </Grid>
                          <Grid children>
                            <Text>{FunnelProjectID}</Text>
                          </Grid>
                        </>
                      )}

                      {Description && (
                        <>
                          <Grid children>
                            <Text bold>Description:</Text>
                          </Grid>
                          <Grid children>
                            <Text>{Description}</Text>
                          </Grid>
                        </>
                      )}

                      {ImpactID && (
                        <>
                          <Grid children>
                            <Text bold>Impact Classification: </Text>
                          </Grid>
                          <Grid children>
                            <Text>
                              <span style={{ color: ImpactColor }}>
                                {ImpactLevel}{" "}
                              </span>
                              - {ImpactDescription}
                            </Text>
                          </Grid>
                        </>
                      )}
                      {ComplexityID && (
                        <>
                          <Grid children>
                            <Text bold>Complexity: </Text>
                          </Grid>
                          <Grid children>
                            <Text>
                              <span style={{ color: complexityColor }}>
                                {ComplexityLevel}{" "}
                              </span>
                              - {ComplexityDescription}
                            </Text>
                          </Grid>
                        </>
                      )}
                      <Grid children>
                        <Text bold>Point of Contact:</Text>
                      </Grid>
                      <Grid children>
                        <Text>{EmailID || PointOfContactEmailID}</Text>
                      </Grid>
                    </Grid>
                  </div>
                </Card>
                {digitalheathcheckid ? (
                  orderBy(selectedArr, "CreatedDate", "desc").map((e) => {
                    const selectedRAMData = reviewRAMDetails.find(
                      (r) => r.DHCID === e.DigitalHeathCheckID
                    );
                    return (
                      <Card className="review-survey-panel">
                        <div className="export-header">
                          {new Date(e.CreatedDate).toDateString()}
                          <Export
                            dhcID={e.DigitalHeathCheckID}
                            date={new Date(e.CreatedDate).toDateString()}
                            projectName={ProjectName}
                            ramDetails={reviewRAMDetails}
                            reviewInDetails={reviewInDetails}
                            shouldExport={true}
                          />
                        </div>
                        {digitalheathcheckid && (
                          <ScoreSummary
                            overallSurveyResult={
                              (e.contentSurveyResult + e.softwareSurveyResult) /
                              2
                            }
                            contentSurveyResult={e.contentSurveyResult}
                            softwareSurveyResult={e.softwareSurveyResult}
                          />
                        )}
                        <br />
                        <div className="sub-content">
                          {(selectedRAMData
                            ? surveyAllTypes
                            : surveyDHCTypes
                          ).map((details, index) => (
                            <ReviewDetails
                              key={index}
                              RAMId={get(selectedRAMData, "RAMID")}
                              details={details}
                              projectid={projectid}
                              id={e.DigitalHeathCheckID}
                              reviewRAMDetails={reviewRAMDetails}
                              reviewInDetails={reviewInDetails}
                            />
                          ))}
                        </div>
                      </Card>
                    );
                  })
                ) : Areas === "Emerging Digital Technologies" ? (
                  <>
                    {edtSurveyResults
                      .filter((el) => el.project_id === item.project_id)
                      .map((survey) => (
                        <Card key={survey.id} className="survey-results">
                          <div className="export-header">
                            <Text>
                              {new Date(survey.date_created).toDateString()}
                            </Text>
                            <Button
                              icon={<Download />}
                              iconOnly
                              variant="transparent"
                              onClick={() =>
                                exportEdtSurveyResponses(
                                  survey,
                                  item.ProjectName
                                )
                              }
                            ></Button>
                          </div>
                          <div className="edt-survey-score">
                            <div className="project-result">
                              <h4>EDT Survey Score</h4>
                              <ProgressBar
                                type="circle"
                                value={survey.score}
                                label={true}
                                sentiment={
                                  survey.score < 50
                                    ? "negative"
                                    : survey.score > 50 && survey.score < 60
                                    ? "default"
                                    : "positive"
                                }
                              />
                              <p>
                                {survey.score < 50
                                  ? "Below Minimum"
                                  : survey.score > 50 && survey.score < 60
                                  ? "Good"
                                  : "Very Good"}
                              </p>
                            </div>
                          </div>
                          <br />
                          <div className="survey-results">
                            <Accordion iconSet="chevron" accordion={true}>
                              <AccordionPanel
                                header={
                                  <div className="panel-header">
                                    EDT SURVEY RESULTS
                                  </div>
                                }
                              >
                                <Table size="medium">
                                  <THead>
                                    <TR>
                                      <TH colSpan={3}>Question</TH>
                                      <TH>Answer</TH>
                                      <TH>Type</TH>
                                    </TR>
                                  </THead>
                                  <TBody>
                                    {survey.results.map((ques, idx) => (
                                      <TR key={ques.id}>
                                        <TD colSpan={3}>
                                          <b>{idx + 1}.</b>{" "}
                                          {
                                            <Text
                                              type="span"
                                              dangerouslySetInnerHTML={{
                                                __html: ques.text,
                                              }}
                                            ></Text>
                                          }
                                        </TD>
                                        <TD>{ques.answer}</TD>
                                        <TD>{ques.type}</TD>
                                      </TR>
                                    ))}
                                  </TBody>
                                </Table>
                              </AccordionPanel>
                            </Accordion>
                          </div>
                        </Card>
                      ))}
                    {RAMNeeded ? (
                      <div className="ram-results survey-results">
                        <Accordion iconSet="chevron" accordion={true}>
                          <AccordionPanel
                            header={
                              <div className="panel-header">
                                RAM SURVEY DETAILS
                              </div>
                            }
                          >
                            <Table size="medium">
                              <THead>
                                <TR>
                                  <TH colSpan={2}>Question</TH>
                                  <TH>Answer</TH>
                                </TR>
                              </THead>
                              <TBody>
                                {item.ramResults &&
                                  item.ramResults.map(
                                    (row) =>
                                      row.QuestionID > 2 && (
                                        <TR key={row.QuestionID}>
                                          <TD colSpan={2}>
                                            <b>{row.QuestionID - 2}.</b>{" "}
                                            {row.Question}
                                          </TD>
                                          <TD>
                                            {[15, 16, 17].includes(
                                              row.QuestionID
                                            )
                                              ? row.Answer
                                              : row.QuestionID === 18
                                              ? row.Answer.split(";")
                                                  .slice(0, -1)
                                                  .map((d, index) => {
                                                    if (
                                                      row.Answer.length - 1 ===
                                                      index
                                                    ) {
                                                      return <span>{d}</span>;
                                                    } else {
                                                      return (
                                                        <>
                                                          <span>{d}</span>
                                                          <br />
                                                        </>
                                                      );
                                                    }
                                                  })
                                              : row.ChoiceDesc}
                                          </TD>
                                        </TR>
                                      )
                                  )}
                              </TBody>
                            </Table>
                          </AccordionPanel>
                        </Accordion>
                      </div>
                    ) : null}
                  </>
                ) : (
                  orderBy(selectedRAMArr, "CreatedDate", "desc").map((e) => (
                    <Card className="review-survey-panel">
                      <div className="export-header">
                        {new Date(e.CreatedDate).toDateString()}
                        <Export
                          dhcID={e.DigitalHeathCheckID}
                          date={new Date(e.CreatedDate).toDateString()}
                          projectName={ProjectName}
                          ramDetails={reviewRAMDetails}
                          reviewInDetails={reviewInDetails}
                          ramId={e.RAMID}
                        />
                      </div>

                      <br />
                      <div className="sub-content">
                        <ReviewDetails
                          RAMId={e.RAMID}
                          details={["RAM", "Risk Assessment Matrix"]}
                          projectid={projectid}
                          id=""
                          reviewRAMDetails={reviewRAMDetails}
                          reviewInDetails={reviewInDetails}
                        />
                      </div>
                    </Card>
                  ))
                )}
              </AccordionPanel>
            );
          })}
        </Accordion>
      </div>
      {/* <hr />
      <hr />
      {(domainFilters.length === 0 || domainFilters.includes('EDT')) && (
        <EdtReviews searchQuery={searchQuery} />
      )} */}

      <br />
    </div>
  );
};

export default DashBoard;
