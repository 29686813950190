import React, { useState, useEffect } from "react";
import { refreshAuthToken } from "./services";
import useInterval from "../../common/useInterval";
import { useHistory } from "react-router-dom";
import { Button } from "@sede-x/shell-ds-react-framework";
import { Cross } from "@sede-x/shell-ds-react-framework/build/esm/components/Icon/components";
import "./TokenRefresher.css";

const ADVANCE_REFRESH_OFFSET_IN_SECONDS = 5 * 60;
const SESSION_TIMEOUT_IN_SECONDS = 10 * 60; // 10 minutes
const WARNING_TIME_IN_SECONDS = 60; // 1 minute

const TokenRefresher = ({ onRefresh }) => {
  const history = useHistory();

  const [lastActivity, setLastActivity] = useState(Date.now());
  const [showWarning, setShowWarning] = useState(false);
  const [remainingTime, setRemainingTime] = useState(WARNING_TIME_IN_SECONDS);

  const tokenDetails = JSON.parse(localStorage.getItem("tokenDetails"));
  const userDetails = JSON.parse(localStorage.getItem("loginUser"));

  const refreshIntervalInMillis =
    (tokenDetails.expires_in || 305 - ADVANCE_REFRESH_OFFSET_IN_SECONDS) * 1000;
  useInterval(async () => {
    try {
      const accessTokenDetails = await refreshAuthToken(
        userDetails.clientId,
        tokenDetails.refresh_token
      );
      localStorage.setItem("tokenDetails", JSON.stringify(accessTokenDetails));
      onRefresh({
        refresh_token: accessTokenDetails.refresh_token,
        accessToken: accessTokenDetails.access_token,
      });
    } catch (err) {
      console.error("Token Refresh failed", err);
    }
  }, refreshIntervalInMillis);

  useInterval(() => {
    const idleTimeInSeconds = (Date.now() - lastActivity) / 1000;
    if (idleTimeInSeconds >= SESSION_TIMEOUT_IN_SECONDS) {
      // Session timeout logic here. For example:
      localStorage.clear();
      // Navigate to new page
      history.push({
        pathname: "/signedOut",
        state: { fromTokenRefresher: true },
      });
    } else if (
      idleTimeInSeconds >=
      SESSION_TIMEOUT_IN_SECONDS - WARNING_TIME_IN_SECONDS
    ) {
      // Show warning
      setShowWarning(true);
      setRemainingTime(SESSION_TIMEOUT_IN_SECONDS - idleTimeInSeconds);
    } else {
      setShowWarning(false);
    }
  }, 1000); // Check every second

  // Update lastActivity whenever there is any activity
  useEffect(() => {
    const activityHandler = () => {
      if (!showWarning) {
        setLastActivity(Date.now());
      }
    };
    window.addEventListener("click", activityHandler);
    window.addEventListener("keydown", activityHandler);
    return () => {
      window.removeEventListener("click", activityHandler);
      window.removeEventListener("keydown", activityHandler);
    };
  }, [showWarning]);

  return showWarning ? (
    <div className="modal-background">
      <div className="modal-wrapper">
        <div className="modal-cross">
          <Button
            iconOnly
            icon={<Cross />}
            variant="transparent"
            onClick={() => {
              setShowWarning(false);
              setLastActivity(Date.now());
            }}
            className="close-button"
          />
        </div>
        <div className="modal-content">
          <h2>Session Timeout</h2>
          <p>
            Session will be timed out in{" "}
            <span className="timer">{Math.ceil(remainingTime)}</span> seconds.
          </p>
          <Button
            size="medium"
            variant="filled"
            sentiment="default"
            onClick={() => {
              setLastActivity(Date.now());
              setShowWarning(false);
            }}
            className="continue-button"
          >
            Continue
          </Button>
        </div>
      </div>
    </div>
  ) : null;
};

export default TokenRefresher;
