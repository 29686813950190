const config = {
  REDIRECT_URL: "https://digitalhealthcheck.dev.aze.shell.io/",
  PINGID_TOKEN_URL: "https://sso-uat.shell.com/as/token.oauth2",
  PINGID_AUTH_URL: "https://sso-uat.shell.com/as/authorization.oauth2",
  CLIENT_ID: "ee8aa58a-5d3c-48c5-b972-22afc2ac3066",
  // REDIRECT_URL: "http://localhost:3000/",
  // PINGID_TOKEN_URL: "https://sso-dev.shell.com/as/token.oauth2",
  // PINGID_AUTH_URL: "https://sso-dev.shell.com/as/authorization.oauth2",
  // CLIENT_ID: "ee8aa58a-5d3c-48c5-b972-22afc2ac3066",
};

export default config;
