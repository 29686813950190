import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { Context, actions } from "../../context";
import {
  Accordion,
  AccordionPanel,
  Button,
  Card,
  Divider,
  Grid,
  Heading,
  Table,
  Tag,
  Text,
  Tooltip,
} from "@sede-x/shell-ds-react-framework";
import { Download } from "@sede-x/shell-ds-react-framework/build/esm/components/Icon/components";
import { BASE_URL, GET_RAM_SURVEY_BY_PROJECT_ID } from "../../api/APIdetails";
import { api } from "../../api";
import axios from "axios";
import {
  TBody,
  TD,
  TH,
  THead,
  TR,
} from "@sede-x/shell-ds-react-framework/build/esm/components/Table/Table.styles";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const RAMSurveyByProject = () => {
  const [state, dispatch] = useContext(Context);
  const history = useHistory();
  const {
    selectedProject,
    RAMFinalData,
    formData,
    surveyAllData,
    newProjectID,
    selectedFlag,
  } = state;
  const [technicalAssurance, setTechnicalAssurance] = useState(false);
  const [surveyResponses, setSurveyResponses] = useState([]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    console.log("RAMFinalData", RAMFinalData);
    console.log("formData", formData);
    console.log("surveyAllData", surveyAllData);
  }, []);

  useEffect(() => {
    if (newProjectID) {
      loadRamSurveyByProject(newProjectID);
    }
  }, [newProjectID]);

  const loadRamSurveyByProject = async (id) => {
    try {
      const url = `${BASE_URL}${GET_RAM_SURVEY_BY_PROJECT_ID}/${id}`;
      const response = await axios.get(url); //loadRamSurveyByProject
      setSurveyResponses(response.data.recordset);
      setTechnicalAssurance(response.data.recordset[0].TARequiredOrNot);
    } catch (error) {
      const errorCode = error.response ? error.response.status : 500;
      console.log(`RAM Survey Details not Loaded-`, error.message);
      history.push("/errorPage", { error, errorCode });
    }
  };

  const exportRamSurveyResponses = () => {
    const toExportRam = surveyResponses
      .filter((response) => response.QuestionID > 2)
      .map((data) => {
        if (data.QuestionID > 14) {
          return {
            Question: data.Question,
            Answer: data.Answer,
          };
        }
        return {
          Question: data.Question,
          Answer: data.ChoiceDesc,
        };
      });

    const today = new Date().toLocaleDateString();

    exportToCSV(toExportRam, `${formData.Name}_RAM_Survey_On_${today}`);
  };

  const exportToCSV = (apiData, fileName) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(apiData);

    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const surveyTableRow = (response) => (
    <TR>
      <TD colSpan={2}>
        <b>{response.QuestionID - 2}.</b> {response.Question}
      </TD>
      <TD
        style={{
          borderRight: `10px solid ${response.ColorCode}`,
        }}
      >
        {[15, 16, 17].includes(response.QuestionID)
          ? response.Answer
          : response.QuestionID === 18
          ? response.Answer.split(";")
              .slice(0, -1)
              .map((d, index) => {
                if (response.Answer.length - 1 === index) {
                  return <span>{d}</span>;
                } else {
                  return (
                    <>
                      <span>{d}</span>
                      <br />
                    </>
                  );
                }
              })
          : response.ChoiceDesc}
      </TD>
    </TR>
  );

  return (
    <div>
      <Card className="header-card">
        <Heading type="h3" bold>
          RAM SURVEY RESULT
        </Heading>
      </Card>
      <br />
      <br />
      <Card className="summary-card">
        <Grid container columns={2}>
          <Grid children>
            <div className="project-summary">
              <Grid container columns={2}>
                <Grid children>
                  <Text>
                    <b>Project Name: </b>
                  </Text>
                </Grid>
                <Grid children>
                  {selectedFlag === "existingProject" ? (
                    <Text>{selectedProject.ProjectName}</Text>
                  ) : (
                    <Text>{formData.Name}</Text>
                  )}
                </Grid>
                {(selectedProject.Description || formData.Description) && (
                  <>
                    <Grid children>
                      <Text>
                        <b>Description:</b>
                      </Text>
                    </Grid>
                    <Grid children>
                      {selectedFlag === "existingProject" ? (
                        <Text>{selectedProject.Description}</Text>
                      ) : (
                        <Text>{formData.Description}</Text>
                      )}
                    </Grid>
                  </>
                )}

                <Grid children>
                  <Text>
                    <b>Point of Contact: </b>
                  </Text>
                </Grid>
                <Grid children>
                  {selectedFlag === "existingProject" ? (
                    <Text>{selectedProject.PointOfContactEmailID}</Text>
                  ) : (
                    <Text>{formData.Email}</Text>
                  )}
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid children>
            <div className="ram-result-container">
              <Divider direction="vertical" />
              <Tag
                variant="outlined"
                sentiment={technicalAssurance ? "negative" : "positive"}
              >
                {technicalAssurance
                  ? "Technical Assurance is Required"
                  : "Technical Assurance Not Required"}
              </Tag>
              <Tooltip
                overlay="Download Result"
                trigger={
                  <Button
                    icon={<Download />}
                    iconOnly
                    variant="transparent"
                    onClick={exportRamSurveyResponses}
                  ></Button>
                }
              />
            </div>
          </Grid>
        </Grid>
        <br />
        <Grid container columns={1} columnGap="20px">
          <Grid children>
            <div style={{ textAlign: "center" }}>
              <Button
                size="medium"
                variant="filled"
                sentiment="default"
                onClick={() => {
                  dispatch({
                    type: actions.SET_selectedMenuIndex,
                    payload: {
                      index: 1,
                    },
                  });
                  history.push("/review");
                  setLoader(!loader);
                }}
                loading={loader}
              >
                GO TO DASHBOARD
              </Button>
            </div>
          </Grid>
        </Grid>
      </Card>
      <br />
      <br />
      {surveyResponses.length > 0 && (
        <Card className="header-card survey-responses">
          <Accordion iconSet="chevron" accordion={true}>
            <AccordionPanel
              header={<div className="panel-header">RAM SURVEY RESPONSES</div>}
            >
              <Table size="medium">
                <THead>
                  <TR>
                    <TH colSpan={2}>Question</TH>
                    <TH>Answer</TH>
                  </TR>
                </THead>
                <TBody>
                  {surveyResponses.map((response) => {
                    if (response.QuestionID > 2 && response.QuestionID <= 18) {
                      return surveyTableRow(response);
                    }
                  })}
                </TBody>
              </Table>
            </AccordionPanel>
          </Accordion>
        </Card>
      )}
    </div>
  );
};

export default RAMSurveyByProject;
