// utils/API.js
// export const BASE_URL = "http://localhost:80/api/";
export const BASE_URL = "https://digitalhealthcheck-api.azurewebsites.net/api/";
export const POST_USERS_URL = "users/getUsers";
export const GET_USERS_URL = "users/getUser";
export const GET_PROJECTS_URL = "project/getProjectDetails";
export const GET_PROJECTOTHERDETAILS_URL = "project/getProjectOtherDetails";
export const GET_AGREEMENTLEVEL_URL = "agreement/getAgreementLevels";
export const POST_PROJECTDETAILS_URL = "project/postProjectDetails";
export const GET_REVIEWDETAILS_URL = "project/getReviewDetails";
export const GET_QUESTIONS_URL = "survey/getSurveyQuestions";
export const POST_SURVEYDETAILS_URL = "survey/SaveSurveyDetails";
export const POST_SAVECOMMENTS_URL = "survey/SaveSurveyComment";
export const GET_GETSURVEYCOMMENTS_URL = "survey/getSurveyComments";
export const GET_RAM_QUESTIONS_URL = "riskAssessment/getRAMQuestions";
export const POST_RAMDETAILS_URL = "riskAssessment/SaveRAMDetails";
export const GET_RAM_DETAILS_BY_PROJECT_ID_URL =
  "riskAssessment/getRAMByProjectID";
export const GET_RAM_SURVEY_BY_PROJECT_ID =
  "riskAssessment/getRAMSurveyByProjectID";
export const POST_QUESTION_COMMENT_URL = "survey/SaveSurveyQuestionComments";
export const GET_QUESTION_COMMENT_URL = "survey/getSurveyQueComments";
export const GET_FAQ_DETAILS = "FAQ/getFAQDetails";
export const GET_EDT_QUESTIONS = "edt/getEdtQuestions";
export const SAVE_EDT_SURVEY = "edt/saveEdtSurvey";
export const GET_EDT_SURVEY_DETAILS = "edt/getEdtSurveyDetails";
export const GET_EDT_SURVEY_RESULTS = "edt/getEdtSurveyResults";
export const GET_EDT_SURVEY_RES_NO_RAM = "edt/getEdtSurveyResultsNoRAM";
export const GET_ALL_EMAIL_IDS = "users/getAllEmails";
export const PUT_POC_EMAIL_IDS = `project/projectPOCUpdate`;
export const PUT_MCDS_ORG = `project/projectMCDSOrgUpdate`;
