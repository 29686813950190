import React from "react";

import {
  checkArr,
  checkArrForYesOrNo,
  technicalAssuranceOptions,
} from "./DataConfig";
import {
  FormField,
  RadioButton,
  Text,
  Checkbox,
  TextInput,
} from "@sede-x/shell-ds-react-framework";

export default function RiskAssessmentSurveyView(props) {
  const {
    data,
    handleChoiceChange,
    index,
    selectedProject,
    handleSelectionChange,
    handleChecked,
    checked,
    handleCustomFieldData,
    customContain,
    errors,
    selectedFlag,
    formData,
  } = props;

  // console.log(data);

  return (
    <div className="ram-view-container">
      <Text bold>
        {`${
          data.QuestionID === 1 || data.QuestionID === 2
            ? ""
            : `${data.QuestionID - 2}.`
        } ${data.Question}`}
      </Text>

      {data.SectionID !== 0 ? (
        data.choiceList.map((cData, _i) => {
          return (
            <FormField aria-label="selectedChoice" name="selectedChoice">
              <RadioButton
                name={data.QuestionID}
                value={parseInt(cData.orderId, 10)}
                label={cData.choiceDes}
                onChange={(e) => handleChoiceChange(e, data.QuestionID)}
              />
            </FormField>
          );
        })
      ) : data.QuestionID === 2 && selectedProject.FunnelProjectID ? (
        selectedFlag === "existingProject" ? (
          <Text>{selectedProject.FunnelProjectID}</Text>
        ) : (
          <Text>{formData.FunnelProjectID}</Text>
        )
      ) : data.QuestionID === 1 ? (
        selectedFlag === "existingProject" ? (
          <Text>{selectedProject.ProjectName}</Text>
        ) : (
          <Text>{formData.Name}</Text>
        )
      ) : data.QuestionID === 16 || data.QuestionID === 17 ? (
        <>
          {checkArrForYesOrNo().map((item) => (
            <FormField aria-label="selectedChoice" name="selectedChoice">
              <RadioButton
                name={data.QuestionID}
                value={item.name}
                label={item.label}
                onChange={(e) => handleSelectionChange(e, index)}
              />
            </FormField>
          ))}
        </>
      ) : data.QuestionID === 18 ? (
        <>
          {checkArr().map((item) => (
            <FormField>
              <Checkbox
                checked={checked[item.name]}
                onChange={handleChecked}
                name={item.name}
                label={item.label}
              />
            </FormField>
          ))}

          {checked.noneoftheabove && (
            <FormField
              name="customContain"
              id="customContain"
              size="small"
              value={customContain}
              onChange={(e) => handleCustomFieldData(e)}
              bottomLeftHelper={{
                content: errors.customContain ? errors.customContain : "",
                sentiment: "negative",
              }}
              autoComplete="new-password"
            >
              <TextInput
                name="customContain"
                autoComplete="new-password"
                placeholder="Enter content type"
              />
            </FormField>
          )}
        </>
      ) : data.QuestionID === 15 ? (
        <>
          {technicalAssuranceOptions().map((item) => (
            <FormField aria-label="selectedChoice" name="selectedChoice">
              <RadioButton
                name={data.QuestionID}
                value={item.name}
                label={item.label}
                onChange={(e) => handleSelectionChange(e, index)}
              />
            </FormField>
          ))}
        </>
      ) : (
        <></>
      )}
    </div>
  );
}
