import actions from "../actions";

const Reducer = (state, action) => {
  let { type, payload } = action;
  switch (type) {
    case actions.SET_Users:
      return {
        ...state,
        users: payload,
      };
    case actions.SET_LoginUser:
      return {
        ...state,
        loginUser: payload,
      };
    case actions.SET_Projects:
      return {
        ...state,
        project: payload,
      };
    case actions.SET_SelectedProject:
      return {
        ...state,
        selectedProject: payload,
      };
    case actions.SET_TakingSurvey:
      return {
        ...state,
        takingSurvey: payload,
      };
    case actions.SET_ProjectDetails: {
      let { listOfImpacts, listOfComplexity } = payload;
      return {
        ...state,
        listOfImpacts,
        listOfComplexity,
      };
    }
    case actions.SET_SurveyQuestions:
      return {
        ...state,
        surveyQuestions: payload,
      };
    case actions.SET_RAMQuestions:
      return {
        ...state,
        ramQuestions: payload,
      };
    case actions.SET_AgreementLevels:
      return {
        ...state,
        agreementLevels: payload,
      };
    case actions.SET_Banner:
      return {
        ...state,
        banner: payload,
      };
    case actions.SET_SelectedFlag:
      return {
        ...state,
        selectedFlag: payload,
      };
    case actions.SET_selectedMenuIndex:
      return {
        ...state,
        selectedMenu: payload,
      };
    case actions.SET_DHCID:
      return {
        ...state,
        DHCID: payload,
      };
    case actions.SET_EDT:
      return {
        ...state,
        EDT: payload,
      };
    case actions.SET_EDTAnswers:
      return {
        ...state,
        EDTAnswers: payload,
      };
    case actions.SET_InfoModalFlag:
      return {
        ...state,
        infoFlag: payload,
      };
    case actions.SET_FAQDetails:
      return {
        ...state,
        FAQList: payload,
      };
    case actions.SET_FORM_DATA:
      return {
        ...state,
        formData: payload,
      };
    case actions.SET_CONTENT_SURVEY_RESULT:
      return {
        ...state,
        contentFinalSurveyResult: payload,
      };

    case actions.SET_SOFTWARE_SURVEY_RESULT:
      return {
        ...state,
        softwareFinalSurveyResult: payload,
      };

    case actions.SET_SURVEY_ALL_DATA:
      return {
        ...state,
        surveyAllData: payload,
      };

    case actions.SET_RAM_FINAL_DATA:
      return {
        ...state,
        RAMFinalData: payload,
      };

    case actions.SET_PROJECT_ID:
      return {
        ...state,
        newProjectID: payload,
      };

    case actions.SET_EDT_Survey:
      return {
        ...state,
        EDTSurvey: payload,
      };

    case actions.SET_EDT_Score:
      return {
        ...state,
        EDTScore: payload,
      };

    case actions.SET_RAM_NEEDED:
      return {
        ...state,
        RAMNeeded: payload,
      };
    // case "ADD_POST":
    //   return {
    //     ...state,
    //     posts: state.posts.concat(action.payload)
    //   };
    // case "REMOVE_POST":
    //   return {
    //     ...state,
    //     posts: state.posts.filter(post => post.id !== action.payload)
    //   };
    default:
      return state;
  }
};

export default Reducer;
