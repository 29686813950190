import React, { createContext, useReducer } from "react";
import Reducer from "../reducer";

const initialState = {
  project: { listOfProjects: [], validProject: false, isNewProject: false },
  takingSurvey: false,
  selectedProject: {},
  listOfImpacts: [],
  listOfComplexity: [],
  surveyQuestions: [],
  ramQuestions: [],
  agreementLevels: [],
  banner: {
    type: "error",
    message: "",
    vertical: "top",
    horizontal: "center",
    show: false,
  },
  users: [],
  loginUser: {
    isLogIn: false,
    UserID: "",
    EmailID: "",
    FirstName: "",
    LastName: "",
  },
  selectedFlag: "",
  selectedMenu: 1,
  DHCID: null,
  EDT: { edtId: "", score: "" },
  EDTAnswers: {},
  FAQList: [],
  infoFlag: true,
  formData: {},
  contentFinalSurveyResult: null,
  softwareFinalSurveyResult: null,
  surveyAllData: {},
  RAMFinalData: {},
  newProjectID: null,
  EDTSurvey: null,
  EDTScore: null,
  RAMNeeded: true,
};

const Store = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, initialState);
  return (
    <Context.Provider value={[state, dispatch]}>{children}</Context.Provider>
  );
};

export const Context = createContext(initialState);
export default Store;
