import jwt from "jsonwebtoken";

const decodeAccessToken = (rawToken) => {
  const decodedToken = jwt.decode(rawToken, { complete: true });
  const {
    mail = "",
    givenName: firstName = "",
    sn: lastName = "",
  } = decodedToken.payload || {};

  return {
    mailId: mail.toLowerCase(),
    firstName,
    lastName,
  };
};

export default decodeAccessToken;
