import React from "react";
import config from "../../../config";

const refreshAuthToken = async (clientId, refreshToken) => {
  const details = {
    client_id: clientId,
    grant_type: "refresh_token",
    refresh_token: refreshToken,
  };

  const body = Object.keys(details)
    .map((key) => `${key}=${details[key]}`)
    .join("&");

  const response = await fetch(config.PINGID_TOKEN_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Accept: "application/json",
    },
    body,
  });

  const result = await response.json();
  return result;
};

export default refreshAuthToken;
