import React from "react";

import swiss_cheese from "../../styles/images/swiss_cheese.png";
import qa from "../../styles/images/qa.jpg";
import {
  Accordion,
  AccordionPanel,
  Card,
  Heading,
} from "@sede-x/shell-ds-react-framework";
import "./FAQPage.css";

const FAQPage = () => {
  return (
    <>
      <Card className="header-card">
        <Heading type="h3" bold={true}>
          FREQUENTLY ASKED QUESTIONS (FAQ)
        </Heading>
      </Card>
      <br />
      <div className="faq-container">
        <Accordion iconSet="chevron" accordion={true}>
          <AccordionPanel header={"Digital Quality Assurance Portal Workflow"}>
            <div className="faq-questions-container">
              <div className="image-container">
                <img
                  src="Slide8.png"
                  alt="workflow-1"
                  style={{ width: "100%", height: "100%" }}
                ></img>
              </div>
            </div>
          </AccordionPanel>
          <AccordionPanel header={"  What is Quality Assurance (QA)?"}>
            <div className="faq-questions-container">
              <div className="question">
                <h3>When did it start?</h3>
                <div className="answer">
                  <p>
                    Quality Assurance started in 2018 for PTX/D to improve the
                    overall quality of the delivery in projects in the data
                    science and computational science space, and the scope and
                    understanding has grown and spread across other areas of
                    Shell today.
                  </p>
                </div>
              </div>
              <div className="question">
                <h3>What is Quality Assurance?</h3>
                <div className="answer">
                  <div className="image-container">
                    <img
                      src={swiss_cheese}
                      alt="swiss_cheese_model"
                      style={{ width: "100%", height: "100%" }}
                    ></img>
                  </div>
                  <a
                    href="https://eu001-sp.shell.com/sites/AAAAB1732/Pages/Digital-Quality-Assurance.aspx"
                    target="_blank"
                  >
                    Click here for more information
                  </a>
                </div>
              </div>
              <div className="question">
                <h3>Why we do it?</h3>
                <div className="answer">
                  <p>
                    “Quality” is a mindset and culture. This is NOT a checkbox
                    exercise. QA is mandatory for all projects and the
                    criticality and risks of the projects (as revealed by the
                    RAM), determines the next steps.
                  </p>

                  <p>
                    Project “Quality” is owned by “Everyone” involved in the
                    project and adaptation of this is led by the projects which
                    is supported by MCDS discipline via the standards
                    (Computational Science, Data Science, and Implementation
                    Standards). The QA process is there to help and empower
                    teams on their quality journey.
                  </p>

                  <p>
                    For projects which are in scope for Technical Assurance (TA)
                    the DHC forms an important part of the documentation to
                    assess the technical maturity of a project.
                  </p>
                </div>
              </div>
              <div className="question">
                <h3>Quality Control versus Quality Assurance</h3>
                <div className="answer">
                  <img
                    src={qa}
                    alt="qc_vs_qa"
                    style={{ width: "100%", height: "100%" }}
                  ></img>
                </div>
              </div>
            </div>
          </AccordionPanel>
          <AccordionPanel header={"The Digital Health Check (DHC)"}>
            <div className="faq-questions-container">
              <div className="question">
                <h3>What and why?</h3>
                <div className="answer">
                  <p>
                    The DHC is a scalable framework to recommend “every” project
                    completes a self-assessment on how they are doing against
                    various software engineering and statistical/data science
                    modelling aspects over time. The DHC and Risk Assessment
                    Matrix (RAM) should be used from early stages of the project
                    lifecycle to understand the exposure of risk.{" "}
                  </p>
                </div>
              </div>
              <div className="question">
                <h3>Frequency</h3>
                <div className="answer">
                  <p>
                    The DHC is recommended to be done every 6 months or at every
                    project milestone/stage gate.{" "}
                  </p>
                </div>
              </div>
              <div className="question">
                <h3>The Process</h3>
                <div className="answer">
                  <p>
                    Complete the various surveys in the DHC, looking at code
                    implementation, testing and modelling approach, and based on
                    your answers, the portal will produce a score based on your
                    answers. This score is for the project team to assess
                    internally and discuss ways they can improve their score
                    over the project lifecycle.​
                  </p>
                </div>
              </div>
            </div>
          </AccordionPanel>
          <AccordionPanel header={" MCDS"}>
            <div className="faq-questions-container">
              <div className="question">
                <h3>What is MCDS?</h3>
                <div className="answer">
                  <p>
                    MCDS is the Maths, Computational and Data Science discipline
                    in the Shell R&D Technical function. The MCDS community
                    includes all technical staff across PTX, IDT and Commercial
                    Data Science, which offers upskilling and learning sessions,
                    career development and mentoring opportunities, to name just
                    a few. Take a look at the MCDS{" "}
                    <a
                      href="https://spoa-prod.shell.com/#/RD_FS_MathData/dashboard"
                      target="_blank"
                    >
                      website
                    </a>{" "}
                    for more information
                  </p>
                  <p>
                    The discipline has 4 key technical standards which define
                    the technical requirements for projects in the MCDS scope
                    which can be found on the website:
                  </p>
                  <ul>
                    <li>
                      <a
                        href="https://eu001-sp.shell.com/:b:/r/sites/AAFAA4377/ReportLibrary20/2021/SRN-01896/Contents/SRN-01896.pdf?csf=1&web=1&e=5Qr8Yg"
                        target="_blank"
                      >
                        MCDS Data Science and Statistics Content Standard
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://eu001-sp.shell.com/:b:/r/sites/AAFAA4377/ReportLibrary20/2021/SRN-01895/Contents/SRN-01895.pdf?csf=1&web=1&e=xZkV15"
                        target="_blank"
                      >
                        MCDS Computational Science Content Standard
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://eu001-sp.shell.com/:b:/r/sites/AAFAA4377/ReportLibrary20/2021/SRN-01897/Contents/SRN-01897.pdf?csf=1&web=1&e=5Qavi4"
                        target="_blank"
                      >
                        MCDS Implementation Standard
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://eu001-sp.shell.com/sites/AAFAA4377/ReportLibrary20/Forms/Consumer%20View.aspx?id=%2Fsites%2FAAFAA4377%2FReportLibrary20%2F2023%2FSRN%2D05516%2FContents%2FSRN%2D05516%2Epdf&parent=%2Fsites%2FAAFAA4377%2FReportLibrary20%2F2023%2FSRN%2D05516%2FContents"
                        target="_blank"
                      >
                        MCDS AI Ethics Standard
                      </a>
                    </li>
                  </ul>
                  <p>
                    For information on the Technical Assurance process, the Risk
                    Assessment Matrix and other MCDS queries, please visit the
                    <a
                      href="https://eu001-sp.shell.com/sites/SPO002863/_layouts/15/AccessDenied.aspx?Source=https%3A%2F%2Feu001%2Dsp%2Eshell%2Ecom%2Fsites%2FSPO002863&correlation=9c2920a1%2D6066%2D8000%2D8a02%2D3af84fd55e31"
                      target="_blank"
                    >
                      {" "}
                      website.
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </AccordionPanel>
        </Accordion>

        <br />
      </div>
    </>
  );
};

export default FAQPage;
