import {
  Accordion,
  AccordionPanel,
  Card,
  FormField,
  Grid,
  Loading,
  ProgressBar,
  Table,
  Tag,
  Text,
  TextInput,
} from '@sede-x/shell-ds-react-framework';
import { Search } from '@sede-x/shell-ds-react-framework/build/esm/components/Icon/components';
import React, { useEffect, useMemo, useState } from 'react';
import './DashBoard.css';
import {
  BASE_URL,
  GET_EDT_SURVEY_DETAILS,
  GET_EDT_SURVEY_RESULTS,
} from '../api/APIdetails';
import { api } from '../api';
import {
  TBody,
  TD,
  TH,
  THead,
  TR,
} from '@sede-x/shell-ds-react-framework/build/esm/components/Table/Table.styles';

const EdtReviews = ({ searchQuery }) => {
  const [loading, setLoading] = useState(true);
  const [edtSurveyDetails, setEdtSurveyDetails] = useState([]);
  const [edtSurveyResults, setEdtSurveyResults] = useState([]);
  const test = "hello hi how <a href='www.google.com'>G</a>";

  useEffect(() => {
    const loadEdtSurveyDetails = async () => {
      setLoading(true);
      const url = `${BASE_URL}${GET_EDT_SURVEY_DETAILS}`;
      const response = await api.getCalls('loadEdtSurvey', url);
      setEdtSurveyDetails(response.data);

      const resUrl = `${BASE_URL}${GET_EDT_SURVEY_RESULTS}`;
      const resResponse = await api.getCalls('loadEdtRes', resUrl);
      setEdtSurveyResults(resResponse.data);
      setLoading(false);
    };
    loadEdtSurveyDetails();
  }, []);

  const filteredEdtSurveyDetails = useMemo(() => {
    return edtSurveyDetails.filter((item) => {
      return item.ProjectName.toLowerCase().includes(searchQuery.toLowerCase());
    });
  }, [edtSurveyDetails, searchQuery]);

  // console.log(edtSurveyDetails);
  // console.log(edtSurveyResults);
  return (
    <>
      {/* {loading && (
        <Loading
          size="large"
          type="default"
        />
      )} */}
      <div className="reviews-container">
        {!loading && edtSurveyDetails && (
          <Accordion
            iconSet="chevron"
            accordion={true}
          >
            {filteredEdtSurveyDetails.map((item) => (
              <AccordionPanel
                header={
                  <div className="panel-header">
                    <div>
                      <Text bold={true}>{item.ProjectName}</Text>
                    </div>
                    <div>
                      <Text> {item.Areas}</Text>
                    </div>
                    <div>
                      {item.TARequiredOrNot ? (
                        <Text style={{ color: 'rgb(221, 29, 33)' }}>
                          Technical Assurance is required
                        </Text>
                      ) : (
                        <Text style={{ color: 'rgb(0, 132, 67)' }}>
                          Technical Assurance is not required
                        </Text>
                      )}
                    </div>
                  </div>
                }
                key={item.id}
              >
                <br />
                <Card className="summary-card">
                  <div className="project-summary">
                    <Grid
                      container
                      columns="30% auto"
                    >
                      {/* <Grid children>
                        <Text>
                          <b>Project Name: </b>
                        </Text>
                      </Grid>
                      <Grid children>
                        <Text>{item.ProjectName}</Text>
                      </Grid> */}
                      <Grid children>
                        <Text>
                          <b>Description:</b>
                        </Text>
                      </Grid>
                      <Grid children>
                        <Text>{item.Description}</Text>
                      </Grid>
                      <Grid children>
                        <Text>
                          <b>Point of Contact: </b>
                        </Text>
                      </Grid>
                      <Grid children>
                        <Text>{item.PointOfContactEmailID}</Text>
                      </Grid>
                      <Grid children>
                        <Text>
                          <b>Code Complexity: </b>
                        </Text>
                      </Grid>
                      <Grid children>
                        <Text>
                          {item.ComplexityLevel} - {item.ComplexityDescription}
                        </Text>
                      </Grid>
                    </Grid>
                  </div>
                </Card>
                {edtSurveyResults
                  .filter((el) => el.project_id === item.project_id)
                  .map((survey) => (
                    <Card
                      key={survey.id}
                      className="survey-results"
                    >
                      <Grid
                        container
                        columns={2}
                      >
                        <Grid children>
                          <Text>
                            {new Date(survey.date_created).toDateString()}
                          </Text>
                        </Grid>
                        <Grid children>
                          <div className="project-result">
                            <ProgressBar
                              type="circle"
                              value={survey.score}
                              label={true}
                              sentiment={
                                survey.score < 50
                                  ? 'negative'
                                  : survey.score > 50 && survey.score < 60
                                  ? 'default'
                                  : 'positive'
                              }
                            />
                            <p>
                              {survey.score < 50
                                ? 'Below Minimum Score'
                                : survey.score > 50 && survey.score < 60
                                ? 'Good Score'
                                : 'Very Good Score'}
                            </p>
                          </div>
                        </Grid>
                      </Grid>
                      <br />
                      <div className="survey-results">
                        <Accordion
                          iconSet="chevron"
                          accordion={true}
                        >
                          <AccordionPanel
                            header={
                              <div className="panel-header">
                                EDT SURVEY RESULTS
                              </div>
                            }
                          >
                            <Table size="medium">
                              <THead>
                                <TR>
                                  <TH colSpan={3}>Question</TH>
                                  <TH>Answer</TH>
                                  <TH>Type</TH>
                                </TR>
                              </THead>
                              <TBody>
                                {survey.results.map((ques, idx) => (
                                  <TR key={ques.id}>
                                    <TD colSpan={3}>
                                      <b>{idx + 1}.</b>{' '}
                                      {
                                        <Text
                                          type="span"
                                          dangerouslySetInnerHTML={{
                                            __html: ques.text,
                                          }}
                                        ></Text>
                                      }
                                    </TD>
                                    <TD>{ques.answer}</TD>
                                    <TD>{ques.type}</TD>
                                  </TR>
                                ))}
                              </TBody>
                            </Table>
                          </AccordionPanel>
                        </Accordion>
                      </div>
                    </Card>
                  ))}
                {filteredEdtSurveyDetails && (
                  <div className="ram-results survey-results">
                    <Accordion
                      iconSet="chevron"
                      accordion={true}
                    >
                      <AccordionPanel
                        header={
                          <div className="panel-header">RAM SURVEY DETAILS</div>
                        }
                      >
                        <Table size="medium">
                          <THead>
                            <TR>
                              <TH colSpan={2}>Question</TH>
                              <TH>Answer</TH>
                            </TR>
                          </THead>
                          <TBody>
                            {item.ramResults &&
                              item.ramResults.map(
                                (row) =>
                                  row.QuestionID > 2 && (
                                    <TR key={row.QuestionID}>
                                      <TD colSpan={2}>
                                        <b>{row.QuestionID - 2}.</b>{' '}
                                        {row.Question}
                                      </TD>
                                      <TD>
                                        {[15, 16, 17].includes(row.QuestionID)
                                          ? row.Answer
                                          : row.QuestionID === 18
                                          ? row.Answer.split(';')
                                              .slice(0, -1)
                                              .map((d, index) => {
                                                if (
                                                  row.Answer.length - 1 ===
                                                  index
                                                ) {
                                                  return <span>{d}</span>;
                                                } else {
                                                  return (
                                                    <>
                                                      <span>{d}</span>
                                                      <br />
                                                    </>
                                                  );
                                                }
                                              })
                                          : row.ChoiceDesc}
                                      </TD>
                                    </TR>
                                  )
                              )}
                          </TBody>
                        </Table>
                      </AccordionPanel>
                    </Accordion>
                  </div>
                )}
              </AccordionPanel>
            ))}
          </Accordion>
        )}
      </div>
    </>
  );
};

export default EdtReviews;
