import React from "react";
import { useLocation, useHistory } from "react-router-dom";

const PageNotFound = () => {
  const history = useHistory();
  const location = useLocation();
  const errorCode = location.state ? location.state.errorCode : "Unknown Error";
  const error = location.state ? location.state.error : "No Error";

  let errorMessage;
  switch (errorCode) {
    case 404:
      errorMessage = "Oops! Page not found.";
      break;
    case 500:
      errorMessage = "Oops! Something went wrong on our server.";
      break;
    default:
      errorMessage = "Oops! Something unexpected happened.";
      break;
  }
  const goBack = () => {
    history.goBack();
  };

  return (
    <div style={styles.container}>
      <div style={styles.errorBox}>
        <h1 style={styles.errorCode}>Error - {errorCode}</h1>
        <p style={styles.errorText}>{errorMessage}</p>
        {/* <p style={styles.errorText}>{error.message}</p> */}
        <p>
          Please refresh the page. If this doesn't work, please reach out to a
          focal listed in the contact page.
        </p>
        <button onClick={goBack} style={styles.button}>
          Go Back
        </button>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    // backgroundColor: "#f8f8f8",
  },
  errorBox: {
    textAlign: "center",
    padding: "40px",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    backgroundColor: "#fff",
  },
  errorCode: {
    fontSize: "48px",
    color: "#e74c3c",
    margin: "0",
  },
  errorText: {
    fontSize: "24px",
    color: "#333",
    margin: "20px 0 0",
  },
  button: {
    padding: "10px 20px",
    fontSize: "16px",
    backgroundColor: "#fecb00",
    color: "#000000",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    marginTop: "20px",
  },
};

export default PageNotFound;
