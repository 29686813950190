import { useEffect, useRef } from 'react';

function useInterval(callback, delay) {
  const savedCallback = useRef(callback);

  // Remember the latest callback if it changes.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    let id;
    if (delay) {
      id = setInterval(() => savedCallback.current(), delay);
    }
    return () => clearInterval(id);
  }, [delay]);
}

export default useInterval;
