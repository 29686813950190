import React, { useState } from 'react';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';

export default function SnackBars(props) {
  const classes = useStyles();
  const {
    banner: { type, message, show, vertical, horizontal } = {},
    onClose,
  } = props;
  const [open, setOpen] = useState(show);

  const handleClose = (_event, reason) => {
    if (onClose) {
      onClose();
    }
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      {/* <Collapse in={open}>
        <Alert
          className={classes.root}
          variant='outlined'
          severity={type}
          onClose={handleClose}
        >
          {message}
        </Alert>
      </Collapse> */}

      <Snackbar
        // anchorOrigin={{ vertical, horizontal }}
        key={`${vertical},${horizontal}`}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        className={classes.root}
      >
        <Alert
          onClose={handleClose}
          severity={type}
          variant="outlined"
        >
          {message}
        </Alert>
      </Snackbar>
    </>
  );
}

function Alert(props) {
  return (
    <MuiAlert
      elevation={6}
      variant="filled"
      {...props}
    />
  );
}

const useStyles = makeStyles((_theme) => ({
  root: {
    width: '100%',
    // "& > * + *": {
    //   marginTop: theme.spacing(2)
    // }
  },
}));
