import React, { useContext, useState, useRef, useEffect } from 'react';
import {
  TextField,
  Button,
  Grid,
  Typography,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Input,
  ListItemText,
  Checkbox,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { api, details } from '../../api';
import { Context, actions } from '../../context';
import omit from 'lodash.omit';
import SnackBar from '../SnackBars';
import { useHistory } from 'react-router-dom';

export default function Create({
  loadProjects,
  listOfAreas,
  listOfStages,
  listOfTeamSizes,
}) {
  const history = useHistory();
  const [state, dispatch] = useContext(Context);
  const { BASE_URL, POST_PROJECTDETAILS_URL, GET_PROJECTOTHERDETAILS_URL } =
    details;
  const { banner, listOfComplexity = [], listOfImpacts = [] } = state;
  const loginUser = localStorage.getItem('loginUser')
    ? JSON.parse(localStorage.getItem('loginUser'))
    : {};
  const [form, setFormData] = useState({
    Areas: 'Data Science',
    ProjectStage: 'D0 Ideation',
    TeamSize: '1-3 members',
    FunnelProjectID: null,
    Name: null,
    Email: loginUser.EmailID + '',
    Description: null,
    ImpactID: null,
    ComplexityID: null,
  });
  const classes = useStyles();
  const [errors, setErrors] = useState({});
  const [selectedListOfImpact, setSelectedListOfImpact] = useState([]);
  const [FOFlag, setFOFlag] = useState(true);

  const handleClick = () => {
    const isValid = validate(errors);
    if (isValid === true) {
      postProjectDetails();
    } else {
      setErrors({ ...isValid });
    }
  };

  const validate = (errors) => {
    const { Name, ImpactID, ComplexityID, Areas, ProjectStage, TeamSize } =
      form;
    let validForm = {
      Name,
      ImpactID,
      ComplexityID,
    };

    if (Areas === 'Financial Operations') {
      validForm = omit(validForm, ['ImpactID', 'ComplexityID']);
    }
    let isError = false,
      inValid = false;
    Object.keys(validForm).forEach((key) => {
      if (
        form[key] === null ||
        form[key] === undefined ||
        form[key].length <= 0
      ) {
        isError = true;
        errors[key] = `Please Enter ${key}`;
      } else if (errors[key] && !inValid) {
        inValid = errors[key].value !== '' ? true : false;
      }
    });
    if (isError) {
      return errors;
    } else if (inValid) {
      return errors;
    } else {
      return true;
    }
  };

  const usePrevious = (value = {}) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current | {};
  };

  const prevlistOfImpacts = usePrevious(listOfImpacts);

  useEffect(() => {
    if (
      (listOfImpacts.length === 0 && listOfComplexity.length === 0) ||
      listOfImpacts.length !== prevlistOfImpacts.length
    ) {
      loadDetails();
    }
    return () => {
      setBanner(false, '', '');
    };
  }, []);

  const loadDetails = async () => {
    let url = `${BASE_URL}${GET_PROJECTOTHERDETAILS_URL}`;
    let response = await api.getCalls('loadDetails', url);
    console.log(response);
    let { data: { recordsets = [] } = {}, error } = response;
    let [listOfImpacts, listOfComplexity] = recordsets;
    const selectedData = listOfImpacts.filter((t) => t.Areas === form.Areas);
    setSelectedListOfImpact(selectedData);
    error
      ? setBanner(error)
      : dispatch({
          type: actions.SET_ProjectDetails,
          payload: { listOfImpacts, listOfComplexity },
        });
  };

  // Create new project
  const postProjectDetails = async () => {
    console.log('in post project');
    const {
      ComplexityID,
      Description,
      ImpactID,
      Name,
      Areas,
      ProjectStage,
      TeamSize,
      FunnelProjectID,
    } = form;
    const Email = form.Email.replace(/\s/g, '');
    const params = {
      ComplexityID,
      Description,
      Email,
      ImpactID,
      Name,
      Areas,
      ProjectStage,
      TeamSize,
      FunnelProjectID,
      userName: `${loginUser.FirstName} ${loginUser.LastName}`,
    };
    let url = `${BASE_URL}${POST_PROJECTDETAILS_URL}`;
    let response = await api.postCalls('CreateProjects', url, params);
    let { data: { recordset: recordSet = [] } = {}, error } = response;
    if (error) {
      setBanner(true, 'error', error);
      console.log(error);
    } else {
      setBanner(true, 'success', 'Project Created Successfully');
      loadProjects(true);
      if (Areas === 'Financial Operations') {
        console.log('FA sub');
        history.push('/riskAssessment');
        dispatch({
          type: actions.SET_selectedMenuIndex,
          payload: {
            index: 2,
          },
        });
      } else {
        history.push('/survey');
        console.log('normal sub');
        dispatch({
          type: actions.SET_selectedMenuIndex,
          payload: {
            index: 1,
          },
        });
      }
    }
  };

  const setBanner = (show, type = 'error', message) => {
    dispatch({
      type: actions.SET_Banner,
      payload: {
        show,
        type,
        message,
      },
    });
  };

  const handleChange = (e) => {
    let { name, value } = e.target;
    if (name === 'Areas') {
      const selectedData = listOfImpacts.filter((t) => t.Areas === value);
      if (value === 'Financial Operations') {
        setFOFlag(false);
      } else {
        setFOFlag(true);
      }
      setSelectedListOfImpact(selectedData);
    }
    name === 'Areas' &&
      setFormData({
        ...form,
        ['ImpactID']: null,
        ['ComplexityID']: null,
      });
    setFormData({
      ...form,
      [name]: value,
    });
  };
  return (
    <>
      {banner.show ? (
        <SnackBar
          banner={banner}
          onClose={() => setBanner(false, '', '')}
        />
      ) : null}
      <form>
        <Grid
          container
          spacing={2}
          style={{ padding: '20px' }}
        >
          <Grid
            item
            xs={8}
            className={classes.fields}
          >
            <TextField
              id="projectName"
              name="Name"
              label={
                <span>
                  Project Name
                  <span style={{ color: '#DD1D21' }}>*</span>
                </span>
              }
              placeholder="Use the same name as the salesforce entry"
              margin="normal"
              fullWidth
              //size="small"
              color="secondary"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              autoComplete="new-password" // disable autocomplete and autofill
              onChange={(e) => handleChange(e)}
              error={errors.Name ? true : false}
              helperText={errors.Name ? errors.Name : ''}
              onBlur={(e) => {
                if (e.target.value !== '') {
                  setErrors({ ...errors, Name: '' });
                }
              }}
            />
            {/* <InputLabel
              id="projectName"
              className={classes.label}
            >
              Project Name (Use the same name as the Salesforce entry)
              <span style={{ color: '#DD1D21' }}>*</span>
            </InputLabel>
            <TextField
              name="Name"
              variant="outlined"
              className={classes.inputField}
              size="small"
              required
              autoComplete="new-password" // disable autocomplete and autofill
              onChange={(e) => handleChange(e)}
              error={errors.Name ? true : false}
              helperText={errors.Name ? errors.Name : ''}
              onBlur={(e) => {
                if (e.target.value !== '') {
                  setErrors({ ...errors, Name: '' });
                }
              }}
            /> */}
          </Grid>
          <Grid
            item
            xs={4}
            className={classes.fields}
          >
            <TextField
              id="FunnelProjectID"
              name="FunnelProjectID"
              label="Salesforce ID"
              placeholder="ITeM or GTR"
              margin="normal"
              fullWidth
              color="secondary"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              autoComplete="new-password" // disable autocomplete and autofill
              onChange={(e) => handleChange(e)}
            />
            {/* <InputLabel
              id="FunnelProjectID"
              className={classes.label}
            >
              Salesforce Id (ITeM or GTR)
            </InputLabel>
            <TextField
              name="FunnelProjectID"
              variant="outlined"
              className={classes.inputField}
              size="small"
              autoComplete="new-password" // disable autocomplete and autofill
              onChange={(e) => handleChange(e)}
            /> */}
          </Grid>
          <Grid
            item
            xs={12}
            className={classes.fields}
          >
            <TextField
              id="desc"
              name="Description"
              label="Project Description"
              placeholder="Add a brief project description"
              margin="normal"
              fullWidth
              color="secondary"
              InputLabelProps={{
                shrink: true,
              }}
              multiline
              minRows={3}
              variant="outlined"
              autoComplete="new-password" // disable autocomplete and autofill
              onChange={(e) => handleChange(e)}
            />
            {/* <InputLabel
              id="desc"
              className={classes.label}
            >
              Brief Project Description
            </InputLabel>
            <TextField
              id="outlined-multiline-flexible"
              name="Description"
              multiline
              minRows={3}
              className={classes.inputField}
              variant="outlined"
              onChange={(e) => handleChange(e)}
            /> */}
          </Grid>

          <Grid
            item
            xs={6}
            className={classes.select}
          >
            <InputLabel
              id="projectStage"
              className={classes.selectLabel}
            >
              Project Stage
              <span style={{ color: '#DD1D21' }}>*</span>
            </InputLabel>
            <Select
              labelId="projectStage"
              style={{ textAlign: 'Left' }}
              onChange={(e) => handleChange(e)}
              defaultValue={form.ProjectStage}
              name="ProjectStage"
              variant="outlined"
              label="Project Stage"
              fullWidth
              color="secondary"
            >
              {listOfStages.map((item, i) => {
                return (
                  <MenuItem
                    key={i}
                    value={item.value}
                  >
                    {item.label}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>

          <Grid
            item
            xs={6}
            className={classes.select}
          >
            <InputLabel
              id="projectArea"
              className={classes.selectLabel}
            >
              Domain
              <span style={{ color: '#DD1D21' }}>*</span>
            </InputLabel>
            <Select
              labelId="projectArea"
              style={{ textAlign: 'Left' }}
              onChange={(e) => handleChange(e)}
              defaultValue={form.Areas}
              variant="outlined"
              fullWidth
              color="secondary"
              name="Areas"
            >
              {listOfAreas.map((item, i) => {
                return (
                  <MenuItem
                    key={i}
                    value={item.value}
                  >
                    {item.label}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
          {FOFlag && (
            <>
              {' '}
              <Grid
                item
                xs={6}
                className={classes.select}
              >
                <InputLabel
                  id="lblProjectComplexity"
                  className={classes.selectLabel}
                >
                  Code Complexity
                  <span style={{ color: '#DD1D21' }}>*</span>
                </InputLabel>
                <Select
                  name="ComplexityID"
                  labelId="lblIdProjectComplexity"
                  id="ddrProjectComplexity"
                  variant="outlined"
                  style={{ textAlign: 'Left' }}
                  label="Code Complexity"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  color="secondary"
                  defaultValue=""
                  error={errors.ComplexityID ? true : false}
                  onChange={(e) => handleChange(e)}
                  onBlur={(e) => {
                    if (e.target.value !== '') {
                      setErrors({ ...errors, ComplexityID: '' });
                    }
                  }}
                >
                  {listOfComplexity.map((item, i) => {
                    let { ComplexityLevel, ComplexityID, Desciption } = item;
                    return (
                      <MenuItem
                        key={i}
                        value={ComplexityID}
                      >
                        <b>{ComplexityLevel}</b>
                        {'\u00A0'} {`(${Desciption})`}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>
              <Grid
                item
                xs={6}
                className={classes.select}
              >
                <InputLabel
                  id="teamSize"
                  className={classes.selectLabel}
                >
                  Team Size
                  <span style={{ color: '#DD1D21' }}>*</span>
                </InputLabel>
                <Select
                  fullWidth
                  color="secondary"
                  variant="outlined"
                  style={{ textAlign: 'Left' }}
                  onChange={(e) => handleChange(e)}
                  defaultValue={form.TeamSize}
                  name="TeamSize"
                >
                  {listOfTeamSizes.map((item, i) => {
                    return (
                      <MenuItem
                        key={i}
                        value={item.value}
                      >
                        {item.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>
              {errors.ComplexityID && (
                <Typography
                  variant="subtitle2"
                  color="error"
                  gutterBottom={true}
                  className={classes.errorMessage}
                  style={{ width: '81%' }}
                >
                  Please Select Project Complexity
                </Typography>
              )}
              <Grid
                item
                xs={12}
                className={classes.select}
              >
                <InputLabel
                  id="lblProjectImpact"
                  className={classes.selectLabel}
                >
                  Project Impact
                  <span style={{ color: '#DD1D21' }}>*</span>
                </InputLabel>
                <Select
                  name="ImpactID"
                  variant="outlined"
                  fullWidth
                  color="secondary"
                  labelId="lblIdProjectImpact"
                  id="ddrProjectImpact"
                  style={{ textAlign: 'Left' }}
                  defaultValue=""
                  onChange={(e) => handleChange(e)}
                  error={errors.ImpactID ? true : false}
                  onBlur={(e) => {
                    if (e.target.value !== '') {
                      setErrors({ ...errors, ImpactID: '' });
                    }
                  }}
                >
                  {selectedListOfImpact.map((item, i) => {
                    let { ImpactLevel, ImpactID, Description } = item;
                    return (
                      <MenuItem
                        key={i}
                        value={ImpactID}
                      >
                        <b>{ImpactLevel}</b> {'\u00A0'}
                        {`(${Description})`}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>
              {errors.ImpactID && (
                <Typography
                  variant="subtitle2"
                  color="error"
                  gutterBottom={true}
                  className={classes.errorMessage}
                  style={{ width: '78%' }}
                >
                  Please Select Project Impact
                </Typography>
              )}
            </>
          )}
          <Grid
            item
            xs={12}
            className={classes.fields}
          >
            {/* <InputLabel
              id="email"
              className={classes.label}
            >
              Contact Details <span style={{ color: '#DD1D21' }}>*</span>
            </InputLabel> */}
            <TextField
              id="email"
              name="Email"
              variant="outlined"
              label={
                <span>
                  Contact Details <span style={{ color: '#DD1D21' }}>*</span>
                </span>
              }
              helperText="separate multiple emails with ,"
              fullWidth
              color="secondary"
              // type="email"
              // muliple
              // size="small"
              // className={classes.inputField}
              value={form.Email}
              onChange={(e) => handleChange(e)}
              style={{ marginTop: '20px' }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            className={classes.textButtonAlign}
          >
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              onClick={handleClick}
            >
              Create Project
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  fields: {
    display: 'flex',
  },
  label: {
    flex: '25%',
    textAlign: 'left',
  },
  select: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  selectLabel: {
    fontSize: '12px',
    marginBottom: '5px',
    marginTop: '15px',
  },
  textButtonAlign: {
    textAlign: 'center',
  },
  inputField: { width: '75%' },
  errorMessage: {
    fontSize: '0.75rem',
  },
}));
